import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  GetSupportedLoyaltyProgramsErrorResponse,
  GetSupportedLoyaltyProgramsSuccessResponse
} from './getSupportedLoyaltyPrograms.types'

export const getSupportedLoyaltyProgramsApi = async (): Promise<GetSupportedLoyaltyProgramsSuccessResponse> => {
  const response = await configureRequest({
    url: urls.supportedLoyaltyPrograms.getSupportedLoyaltyPrograms,
    method: 'GET'
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetSupportedLoyaltyProgramsSuccessResponse
  } else {
    throw response as GetSupportedLoyaltyProgramsErrorResponse
  }
}

export default getSupportedLoyaltyProgramsApi
