import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { GetProfileErrorResponse, GetProfileSuccessResponse } from './getProfile.types'

export const getProfileApi = async (): Promise<GetProfileSuccessResponse> => {
  const response = await configureRequest({
    url: urls.profile.getProfile,
    method: 'GET'
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetProfileSuccessResponse
  } else {
    throw response as GetProfileErrorResponse
  }
}

export default getProfileApi
