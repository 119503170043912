import { GetItemDetailsState } from './getItemDetails.types'

const initialState: GetItemDetailsState = {
  loading: false,
  error: null,
  success: null
  // Initialize any additional state properties here
}

export default initialState
