import React from 'react'

import StatusUpdatePage from '../../shared/components/StatusUpdatePage/StatusUpdatePage.component'

const SignUpSuccess: React.FC = () => {
  return (
    <StatusUpdatePage
      title="Sign Up Successful!"
      subtitle="A confirmation link has been sent to your email address. Please check your email and click the link to verify your account."
      status="success"
      action={{
        prompt: "Didn't receive the email?",
        link: '#',
        linkText: 'Resend'
      }}
    />
  )
}

export default SignUpSuccess
