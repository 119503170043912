import { SxProps } from '@mui/material'

import theme from '../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  } as SxProps
}

export default styles
