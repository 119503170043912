import React from 'react'

import { StepConnector } from '@mui/material'

import styles from './CustomConnector.styles'

const CustomConnector: React.FC = (props) => {
  return <StepConnector {...props} sx={styles.connector} />
}

export default CustomConnector
