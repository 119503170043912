import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    borderRadius: theme.shape.borderRadius / 4,
    padding: theme.spacing(1, 2, 2, 2),
    boxShadow: theme.shadows[3]
  },

  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '16px',
    textAlign: 'left'
  },

  subtitle: {
    fontSize: '16px',
    color: '#666',
    marginBottom: '24px'
  },

  sectionTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '24px',
    marginBottom: '16px'
  },

  warningBox: {
    backgroundColor: '#fff3e0',
    borderRadius: '4px',
    padding: '12px',
    textAlign: 'left'
  },

  warningText: {
    color: '#e65100',
    fontSize: '14px'
  },

  formContainer: {
    '& .MuiFormControl-root': {
      marginBottom: '16px'
    },

    '& .MuiInputBase-root': {
      borderRadius: '4px'
    },

    '& .MuiInputLabel-root': {
      fontSize: '14px'
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#e0e0e0'
      },

      '&:hover fieldset': {
        borderColor: '#bdbdbd'
      },

      '&.Mui-focused fieldset': {
        borderColor: '#1976d2'
      }
    }
  }
}

export default styles
