import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { getOfferRequestsApi } from './getOfferRequests.api'
import getOfferRequestsInitialState from './getOfferRequests.initialState'
import { GetOfferRequestsErrorResponse, GetOfferRequestsRequestPayload } from './getOfferRequests.types'

export const getOfferRequests = createAsyncThunk(
  urls.offers.viewOfferRequests,
  async (payload: GetOfferRequestsRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await getOfferRequestsApi(request)

      onSuccess && onSuccess(response.data)

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const getOfferRequestsSlice = createSlice({
  name: urls.offers.viewOfferRequests,
  initialState: getOfferRequestsInitialState,
  reducers: {
    // Add any additional reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOfferRequests.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getOfferRequests.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getOfferRequests.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetOfferRequestsErrorResponse
        state.success = null
      })
  }
})

export const { actions: getOfferRequestsActions, reducer: getOfferRequestsReducer } = getOfferRequestsSlice
export default getOfferRequestsSlice.reducer
