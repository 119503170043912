import { CSSProperties } from 'react'

import theme from '../../styles/themes/default.theme'
import { AccordionAlertType } from './Accordion.types'

const styles = {
  accordionContainer: (footerPresent: boolean, alertType?: AccordionAlertType) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    backgroundColor: footerPresent ? theme.palette.background.paper : 'transparent',
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[200]}`,
    borderWidth: alertType ? '2px' : '1px',
    borderColor:
      alertType === 'warning'
        ? theme.palette.warning.light
        : alertType === 'error'
          ? theme.palette.error.light
          : theme.palette.grey[200],
    boxShadow: theme.shadows[1],
    overflow: 'hidden'
  }),

  accordionContent: (expandedWithAlert: boolean) => ({
    borderRadius: expandedWithAlert ? theme.spacing(2) : theme.spacing(2, 2, 0, 0),
    overflow: 'hidden',
    width: '100%'
  }),

  accordionContainerSx: (expanded: boolean, expandedWithAlert: boolean) => ({
    width: '100%',
    '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
    '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
    boxShadow: 'none',
    '&.Mui-expanded': {
      marginBottom: expandedWithAlert ? 0 : theme.spacing(2)
    }
  }),

  accordionHeader: (expandIconPosition: 'left' | 'right', noExpandBackgroundColor: boolean) => ({
    display: 'flex',
    flexDirection: expandIconPosition === 'left' ? 'row-reverse' : 'row',
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '& .MuiAccordionSummary-expandIconWrapper': {
      marginRight: theme.spacing(2)
    },
    '&.Mui-expanded': {
      paddingTop: theme.spacing(1),
      backgroundColor: noExpandBackgroundColor ? 'transparent' : theme.palette.grey[200],
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    }
  }),

  accordionHeaderContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },

  accordionHeaderMainContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },

  accordionHeaderButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    paddingLeft: theme.spacing(3)
  },

  accordionDetails: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },

  accordionFooterContainer: (alertType?: AccordionAlertType) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor:
      alertType === 'warning'
        ? theme.palette.warning.light
        : alertType === 'error'
          ? theme.palette.error.light
          : 'transparent'
  }),

  accordionFooterContent: (isAlert: boolean) => ({
    padding: theme.spacing(isAlert ? 2 : 3),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%'
  }),

  accordionFooterAlertText: (alertType?: AccordionAlertType) =>
    ({
      fontSize: theme.typography.body1.fontSize,
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      color:
        alertType === 'warning'
          ? theme.palette.warning.contrastText
          : alertType === 'error'
            ? theme.palette.error.contrastText
            : theme.palette.text.primary
    }) as CSSProperties,

  accordionFooterAlertIcon: {
    fontSize: theme.typography.body1.fontSize
  },

  accordionFooterButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2)
  },

  accordionFooterButton: (alertType?: AccordionAlertType) => {
    let style = {} as CSSProperties

    if (alertType === 'error') {
      style = {
        color: theme.palette.error.contrastText,
        backgroundColor: 'transparent',
        border: `1.5px solid ${theme.palette.error.contrastText}`
      } as CSSProperties
    }

    return style
  },

  accordionFooterDivider: {
    width: '100%'
  }
}

export default styles
