import { JSX } from 'react'

import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import RedirectHandler from '../components/RedirectHandler.component'

import { Route as HourrierRoute } from '../router.types'
import routes from '../routes.dictionary'

const generateRoutes = (customRoutes: HourrierRoute[]): JSX.Element => {
  const routes = customRoutes.map((route) => (
    <Route key={route.path} path={route.path} element={<RedirectHandler route={route} />} />
  ))

  return <Routes>{routes}</Routes>
}

const useCustomNavigate = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const customNavigate = (
    path: string,
    options?: {
      replace?: boolean
      state?: { from?: string } & Record<string, unknown>
    },
    isRelativePath?: boolean
  ) => {
    const pathToNavigate = isRelativePath ? path : `/${path}`
    const currentPath = location.pathname

    // Add current path to state when navigating
    const navigationState = {
      ...options?.state,
      from: currentPath
    }

    if (path === '/') {
      navigate(routes.home.path, { ...options, state: navigationState })
    } else {
      navigate(pathToNavigate, { ...options, state: navigationState })
    }
  }

  return customNavigate
}

const useCurrentPage = (): HourrierRoute => {
  const location = useLocation()
  const currentRoute = Object.values(routes).find((route) => `/${route.path}` === location.pathname)

  // return (currentRoute ?? routes.home)
  // Todo: Fix typings for TripSummaryProps
  return (currentRoute ?? routes.home) as HourrierRoute
}

const usePreviousPage = () => {
  const location = useLocation()
  const navigate = useCustomNavigate()

  const goBack = () => {
    const previousPath = location.state?.from || routes.home.path

    navigate(previousPath, { replace: true })
  }

  return {
    goBack,
    previousPath: location.state?.from || routes.home.path
  }
}

const useSearchParamsHook = (searchQuery: string) => {
  const [params] = useSearchParams()
  const value = params.get(searchQuery)
  const sanitizedValue = value?.replace(/^\s+/, '')

  return sanitizedValue ?? ''
}

const useCustomLocation = () => {
  const location = useLocation()

  return location
}

const router = {
  navigate: useCustomNavigate,
  handleRedirects: null,
  generateRoutes,
  getCurrentPage: useCurrentPage,
  getPreviousPage: usePreviousPage,
  getUrlParam: useSearchParamsHook,
  getNavigationProps: useCustomLocation
}

export default router
