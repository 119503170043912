import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius / 4,
    backgroundColor: theme.palette.background.default
  },

  title: {
    marginBottom: theme.spacing(2)
  },

  image: {
    width: 100,
    height: 100,
    marginRight: theme.spacing(2),
    objectFit: 'contain' as const
  },

  details: {
    flex: 1,
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    textAlign: 'left'
  },

  priceValidLink: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    fontWeight: 600
  },

  priceQuantityContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  priceContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },

  priceValidText: {
    marginLeft: theme.spacing(1)
  },

  divider: {
    margin: theme.spacing(1, 2)
  },

  quantityControlContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'column'
  },

  quantityControl: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },

  quantityButton: {
    minWidth: '32px',
    width: '32px',
    height: '32px',
    padding: 0,
    borderRadius: '50%',
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.common.black,

    '&:hover': {
      backgroundColor: theme.palette.grey[100]
    }
  },

  lowStockText: {
    marginTop: theme.spacing(1)
  },

  quantityText: {
    margin: '0 8px'
  }
}

export default styles
