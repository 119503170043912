import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../../../../../../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  } as SxProps,

  legend: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
  } as SxProps,

  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  } as SxProps,

  legendSquare: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1),
    borderRadius: theme.shape.borderRadius / 12
  } as CSSProperties,

  legendSquareNotAvailable: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[200]}`
  } as CSSProperties,

  legendSquareAvailable: {
    backgroundColor: theme.palette.blue[50],
    border: `1px solid ${theme.palette.blue[800]}`
  } as CSSProperties,

  legendSquareSelected: {
    backgroundColor: theme.palette.blue[800],
    border: `1px solid ${theme.palette.blue[800]}`
  } as CSSProperties,

  seatMap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    //Todo: Center the seat map beter
    marginLeft: theme.spacing(4)
    // backgroundColor: 'red',
    // flexWrap: 'nowrap',
    // overflow: 'auto'
  } as SxProps,

  row: {
    // marginBottom: theme.spacing(1)
  } as SxProps,

  section: {
    display: 'flex',
    marginRight: theme.spacing(10)
  } as SxProps,

  seat: {
    width: 40,
    height: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(0.5),
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius / 8
  } as CSSProperties,

  seatNotAvailable: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[300]}`
  } as CSSProperties,

  seatUnavailable: {
    backgroundColor: theme.palette.common.white
  } as CSSProperties,

  seatAvailable: {
    backgroundColor: theme.palette.blue[50],
    border: `1px solid ${theme.palette.blue[800]}`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.blue[100]
    }
  } as CSSProperties,

  text: {
    color: theme.palette.common.black,
    fontSize: '12px',
    lineHeight: '16px'
  } as CSSProperties,

  textUnavailable: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '20px'
  } as CSSProperties,

  textSelected: {
    color: theme.palette.common.white
  } as CSSProperties,

  seatSelected: {
    backgroundColor: theme.palette.blue[800],
    border: `1px solid ${theme.palette.blue[800]}`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.blue[900]
    }
  } as CSSProperties,

  exitRow: {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.warning.light,
    margin: theme.spacing(0.5),
    visibility: 'hidden'
  } as SxProps,

  facility: {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.grey[400],
    margin: theme.spacing(0.5),
    visibility: 'hidden'
  } as SxProps,

  emptySeat: {
    width: 40,
    height: 40,
    margin: theme.spacing(0.5),
    visibility: 'hidden'
  } as SxProps
}

export default styles
