// TODO: Pull from api
export const marketplaceCountryUrls = {
  amazon: [
    { countryCode: 'US', url: 'amazon.com' },
    { countryCode: 'UK', url: 'amazon.co.uk' },
    { countryCode: 'DE', url: 'amazon.de' },
    { countryCode: 'FR', url: 'amazon.fr' },
    { countryCode: 'IT', url: 'amazon.it' },
    { countryCode: 'ES', url: 'amazon.es' },
    { countryCode: 'CA', url: 'amazon.ca' },
    { countryCode: 'JP', url: 'amazon.co.jp' },
    { countryCode: 'IN', url: 'amazon.in' },
    { countryCode: 'AU', url: 'amazon.com.au' },
    { countryCode: 'BR', url: 'amazon.com.br' },
    { countryCode: 'MX', url: 'amazon.com.mx' },
    { countryCode: 'NL', url: 'amazon.nl' },
    { countryCode: 'SG', url: 'amazon.sg' },
    { countryCode: 'TR', url: 'amazon.com.tr' },
    { countryCode: 'AE', url: 'amazon.ae' },
    { countryCode: 'SA', url: 'amazon.sa' },
    { countryCode: 'PL', url: 'amazon.pl' },
    { countryCode: 'ZA', url: 'amazon.co.za' }
  ]
}
