import React, { useEffect } from 'react'

import { Box, CircularProgress, Button as MuiButton, Tooltip } from '@mui/material'
import { ButtonProps as MuiButtonProps } from '@mui/material/Button'

import logger from '../../functions/Logger/logger.functions'
import styles from './Button.styles'
import { ButtonProps } from './Button.types'

const Button: React.FC<ButtonProps & MuiButtonProps> = ({
  buttonType,
  icon,
  iconPosition = 'start',
  isNav = false,
  isActiveNav = false,
  text,
  altText,
  outline,
  inverted,
  animateIconOnPress = false,
  disabled = false,
  loading = false,
  fullWidth = false,
  large = false,
  marginTop = '0px',
  onClick,
  tooltipText,
  style,
  containerStyle,
  ...props
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [animate, setAnimate] = React.useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const buttonName =
      altText?.toLowerCase().replace(/\s+/g, '_') || text?.toLowerCase().replace(/\s+/g, '_') || 'button'

    if (animateIconOnPress) {
      setAnimate((prev) => !prev)
    }

    logger.logEvent(`${buttonName}_button_clicked`)

    if (onClick) onClick(event)
  }

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  const getVariant = () => {
    if (buttonType === 'quaternary') return 'outlined'

    return outline ? 'outlined' : 'contained'
  }

  return (
    <Tooltip
      style={containerStyle}
      title={tooltipText || ''}
      disableHoverListener={!tooltipText}
      disableFocusListener={!tooltipText}
      arrow
    >
      <Box sx={styles.buttonContainer(fullWidth)}>
        <MuiButton
          variant={getVariant()}
          color={buttonType}
          startIcon={iconPosition === 'start' && icon && <Box sx={styles.iconWrapper(animate)}>{icon}</Box>}
          endIcon={iconPosition === 'end' && <Box sx={styles.iconWrapper(animate)}>{icon}</Box>}
          disabled={disabled || isLoading}
          onClick={handleClick}
          sx={styles.button(isNav, isActiveNav, large, marginTop, outline, inverted, buttonType)}
          fullWidth={fullWidth}
          style={style}
          role="button"
          {...props}
        >
          {icon && iconPosition === 'center' && <Box sx={styles.iconWrapper(animate)}>{icon}</Box>}
          {text && <>{text}</>}
          {isLoading && <CircularProgress size={24} sx={styles.loading} />}
        </MuiButton>
      </Box>
    </Tooltip>
  )
}

export default Button
