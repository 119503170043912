import { SxProps } from '@mui/material'

import theme from '../../../shared/styles/themes/default.theme'

const styles: { [key: string]: SxProps } = {
  chip: {
    borderRadius: '5px',
    padding: 0,

    '& .label-text': {
      marginRight: theme.spacing(1)
    },

    '& .error-text': {
      fontWeight: 'bold',
      lineHeight: '14px',
      fontSize: '14px'
    },

    fontSize: '14px',

    backgroundColor: '#f9fafb',

    borderColor: '#D1D5DB',
    borderStyle: 'solid',
    borderWidth: '1.5px',

    justifyContent: 'center',
    lineHeight: '14px',

    '& .MuiChip-label': {
      // padding: '0 !important'
    }
  },

  chipCloseIcon: {
    fill: 'black',

    width: '16px',
    height: '16px'
  }
}

styles.errorChip = {
  ...styles.chip,

  color: 'black',
  borderColor: 'red'
}

export default styles
