import React, { useCallback, useEffect, useState } from 'react'

import { Box, Button, MobileStepper } from '@mui/material'

import DotStepper from '../DotStepper/DotStepper.component'

import styles from './Carousel.styles'
import { AnimationType, AutoCycleDuration, CarouselProps } from './Carousel.types'

const Carousel: React.FC<CarouselProps> = ({
  items,
  showStepper = false,
  showDotStepper = false,
  autoCycle = false,
  autoCycleDuration = AutoCycleDuration.SHORT,
  animation = AnimationType.FADE,
  onStepChange
}) => {
  const [activeStep, setActiveStep] = useState(0)
  const [, setPrevStep] = useState(0)
  const maxSteps = items.length

  const handleStepChange = useCallback(
    (newStep: number) => {
      setPrevStep(activeStep)
      setActiveStep(newStep)

      if (onStepChange) {
        onStepChange(newStep)
      }
    },
    [activeStep, onStepChange]
  )

  const handleNext = useCallback(() => {
    const newStep = (activeStep + 1) % maxSteps

    handleStepChange(newStep)
  }, [activeStep, maxSteps, handleStepChange])

  const handleBack = useCallback(() => {
    const newStep = (activeStep - 1 + maxSteps) % maxSteps

    handleStepChange(newStep)
  }, [activeStep, maxSteps, handleStepChange])

  useEffect(() => {
    if (autoCycle) {
      const cycleInterval = setInterval(() => {
        handleNext()
      }, autoCycleDuration)

      return () => clearInterval(cycleInterval)
    }
  }, [autoCycle, autoCycleDuration, handleNext])

  const getAnimationStyle = () => {
    // TODO: Fix bug with animations
    switch (animation) {
      case AnimationType.FADE:
        return { transition: 'opacity 0.5s ease-in-out', opacity: 1 }

      case AnimationType.SLIDE:
        return { transition: 'transform 0.5s ease-in-out', transform: `translateX(0)` }
      // return { transition: 'transform 0.5s ease-in-out', transform: `translateX(-${activeStep * 100}%)` }

      default:
        return { transition: 'transform 0.5s ease-in-out', transform: `translateX(0)` }
    }
  }

  return (
    <Box sx={styles.carouselContainer}>
      <Box sx={{ ...styles.carouselItem, ...getAnimationStyle() }}>{items[activeStep]}</Box>
      {showStepper && (
        <>
          {showDotStepper ? (
            <DotStepper steps={maxSteps} activeStep={activeStep} onStepChange={handleStepChange} />
          ) : (
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                  Next
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                  Back
                </Button>
              }
            />
          )}
        </>
      )}
    </Box>
  )
}

export default Carousel
