import React, { useState } from 'react'

import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Container, IconButton } from '@mui/material'
import { FormikProps } from 'formik'
import * as Yup from 'yup'

import Form from '../../shared/components/Form/Form.component'

import { resetForgottenPassword } from '../../redux/states/auth/resetForgottenPassword/resetForgottenPassword.slice'
import { ResetForgottenPasswordRequest } from '../../redux/states/auth/resetForgottenPassword/resetForgottenPassword.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import { FormField } from '../../shared/components/Form/Form.types'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import styles from './ResetForgottenPassword.styles'
import { ResetForgottenPasswordFormValues } from './ResetForgottenPassword.types'

const ResetForgottenPassword: React.FC = () => {
  const { loading } = useAppSelector((state) => state.resetForgottenPassword)

  const [formikInstance, setFormikInstance] = useState<FormikProps<ResetForgottenPasswordFormValues>>(
    {} as FormikProps<ResetForgottenPasswordFormValues>
  )
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const dispatch = useAppDispatch()
  const navigate = router.navigate()
  const resetToken = router.getUrlParam('resetToken')

  const initialValues: ResetForgottenPasswordFormValues = {
    password: '',
    confirmPassword: ''
  }

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[A-Z]/, 'Password must contain one uppercase letter')
      .matches(/[a-z]/, 'Password must contain one lowercase letter')
      .matches(/[0-9]/, 'Password must contain one number')
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), ''], 'Passwords must match')
      .required('Required')
  })

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const handleBlur = () => {
    setShowPassword(false)
    setShowConfirmPassword(false)
  }

  const onSubmit = (values: ResetForgottenPasswordFormValues) => {
    const resetForgottenPasswordRequest: ResetForgottenPasswordRequest = {
      newPassword: values.password,
      resetToken: resetToken
    }

    const onSuccess = () => {
      navigate(routes.login.path, { replace: true })
    }

    dispatch(resetForgottenPassword({ resetForgottenPasswordRequest, onSuccess }))
  }

  const formFields: FormField[] = [
    {
      name: 'password',
      label: 'Password',
      type: showPassword ? 'text' : 'password',
      autoComplete: 'new-password',
      placeholder: 'Enter your new password',
      grid: { xs: 12 },
      required: true,
      fullWidth: true,
      onBlur: handleBlur,
      helperText:
        (formikInstance?.touched?.password && !!formikInstance?.errors?.password) ||
        (!!formikInstance?.values?.password && formikInstance?.errors?.password)
          ? 'Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, and one number.'
          : '',
      endAdornment: (
        <IconButton onMouseDown={(e) => e.preventDefault()} onClick={togglePasswordVisibility}>
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      )
    },
    {
      name: 'confirmPassword',
      label: 'Confirm Password',
      type: showConfirmPassword ? 'text' : 'password',
      autoComplete: 'new-password',
      placeholder: 'Confirm your new password',
      grid: { xs: 12 },
      required: true,
      fullWidth: true,
      onBlur: handleBlur,
      endAdornment: (
        <IconButton onMouseDown={(e) => e.preventDefault()} onClick={toggleConfirmPasswordVisibility}>
          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      )
    }
  ]

  return (
    <Container component="main" maxWidth="sm" sx={styles.container}>
      <Form
        title="New Password"
        subtitle="Enter your new password to continue."
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values: unknown) => onSubmit(values as ResetForgottenPasswordFormValues)}
        onFormChange={(formik: Partial<FormikProps<unknown>>) =>
          setFormikInstance(formik as FormikProps<ResetForgottenPasswordFormValues>)
        }
        formFields={formFields}
        loading={loading}
        buttonText="Submit"
      />
    </Container>
  )
}

export default ResetForgottenPassword
