import { SxProps } from '@mui/material'

import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%'
  },

  title: {
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.grey[800],
    fontSize: theme.typography.body1.fontSize,
    marginBottom: theme.spacing(1)
  },

  tripDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%'
  },

  legContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },

  legDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },

  route: {
    fontWeight: theme.typography.fontWeightMedium
  },

  arrival: {
    color: '#1a73e8'
  },

  verticalSeparator: {
    width: '1px',
    height: '40px',
    backgroundColor: theme.palette.divider,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },

  arrivalLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[500]
  } as SxProps,

  arrivalDate: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.secondary.dark
  }
}

export default styles
