import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import Snackbar from '../../../../shared/components/Snackbar/Snackbar.functions'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { resetForgottenPasswordApi } from './resetForgottenPassword.api'
import resetForgottenPasswordInitialState from './resetForgottenPassword.initialState'
import { ResetForgottenPasswordErrorResponse, ResetForgottenPasswordRequest } from './resetForgottenPassword.types'

export const resetForgottenPassword = createAsyncThunk(
  urls.auth.resetForgottenPassword,
  async (
    {
      resetForgottenPasswordRequest,
      onSuccess
    }: { resetForgottenPasswordRequest: ResetForgottenPasswordRequest; onSuccess: () => void },
    { rejectWithValue }
  ) => {
    try {
      const response = await resetForgottenPasswordApi(resetForgottenPasswordRequest)

      onSuccess()

      Snackbar.show({
        message: response.userFriendlyMessage,
        severity: 'success'
      })

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const resetForgottenPasswordSlice = createSlice({
  name: urls.auth.resetForgottenPassword,
  initialState: resetForgottenPasswordInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetForgottenPassword.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(resetForgottenPassword.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(resetForgottenPassword.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as ResetForgottenPasswordErrorResponse
        state.success = null
      })
  }
})

export const { actions: resetForgottenPasswordActions, reducer: resetForgottenPasswordReducer } =
  resetForgottenPasswordSlice
export default resetForgottenPasswordSlice.reducer
