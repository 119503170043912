import React, { useCallback, useEffect } from 'react'

import { ExpandMore } from '@mui/icons-material'
import { Box, Divider, Grid, Typography } from '@mui/material'

import Accordion from '../../../../shared/components/Accordion/Accordion.component'
import Avatar from '../../../../shared/components/Avatar/Avatar.component'
import Link from '../../../../shared/components/Link/Link.component'
import TrackingDetails from '../../../../shared/components/TrackingDetails/TrackingDetails.component'

import {
  ItemRequestDetails,
  Leg,
  OfferRequestDetails
} from '../../../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import { commitPurchasePrice } from '../../../../redux/states/items/commitPurchasePrice/commitPurchasePrice.slice'
import { CommitPurchasePriceRequestPayload } from '../../../../redux/states/items/commitPurchasePrice/commitPurchasePrice.types'
import { getItemChanges } from '../../../../redux/states/items/getItemChanges/getItemChanges.slice'
import {
  GetItemChangesData,
  GetItemChangesRequestPayload
} from '../../../../redux/states/items/getItemChanges/getItemChanges.types'
import { acceptItemRequest } from '../../../../redux/states/offers/acceptItemRequest/acceptItemRequest.slice'
import { AcceptItemRequestRequestPayload } from '../../../../redux/states/offers/acceptItemRequest/acceptItemRequest.types'
import { denyItemRequest } from '../../../../redux/states/offers/denyItemRequest/denyItemRequest.slice'
import {
  clearItemRequestTrackingDetailsSuccess,
  getItemRequestTrackingDetails
} from '../../../../redux/states/offers/getItemRequestTrackingDetails/getItemRequestTrackingDetails.slice'
import { GetItemRequestTrackingDetailsRequestPayload } from '../../../../redux/states/offers/getItemRequestTrackingDetails/getItemRequestTrackingDetails.types'
import { RootState } from '../../../../redux/store/store.types'
import { AccordionAlertProps, AccordionButtonProps } from '../../../../shared/components/Accordion/Accordion.types'
import { AvatarSize } from '../../../../shared/components/Avatar/Avatar.types'
import Modal from '../../../../shared/components/Modal/Modal.functions'
import status from '../../../../shared/dictionaries/status.dictionaries'
import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'
import { joinStringWithBullet } from '../../../../shared/functions/String/string.functions'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import { getUserRole } from '../../../../shared/functions/UserRole/userRoleManagment.functions'
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux.hooks'
import styles from './ItemRequestAccordion.styles'
import { ItemRequestAccordionProps } from './ItemRequestAccordion.types'

const ItemRequestAccordion: React.FC<ItemRequestAccordionProps> = (props) => {
  const { flightLeg, flightItineraryStatus, refreshFlightItineraries } = props

  const [expandedItemId, setExpandedItemId] = React.useState<string | null>(null)
  const [loadingAcceptItemIds, setLoadingAcceptItemIds] = React.useState<string[]>([])
  const [loadingDenyItemIds, setLoadingDenyItemIds] = React.useState<string[]>([])
  const [updatedFlightLeg, setUpdatedFlightLeg] = React.useState<Leg>(flightLeg)
  const [alerts, setAlerts] = React.useState<{ [key: string]: AccordionAlertProps | null }>({})

  const { loading: getAllFlightItinerariesLoading, error: getAllFlightItinerariesError } = useAppSelector(
    (state: RootState) => state.getAllFlightItineraries
  )

  const { loading: getItemChangesLoading, error: getItemChangesError } = useAppSelector(
    (state: RootState) => state.getItemChanges
  )

  const { loading: acceptItemRequestLoading, error: acceptItemRequestError } = useAppSelector(
    (state: RootState) => state.acceptItemRequest
  )

  const { loading: denyItemRequestLoading, error: denyItemRequestError } = useAppSelector(
    (state: RootState) => state.denyItemRequest
  )

  const dispatch = useAppDispatch()

  const [committedPrice, setCommittedPrice] = React.useState<number | null>(null)

  useEffect(() => {
    setUpdatedFlightLeg(flightLeg)
  }, [flightLeg])

  useEffect(() => {
    if (!acceptItemRequestLoading && acceptItemRequestError) {
      setLoadingAcceptItemIds([])
    }
  }, [acceptItemRequestLoading, acceptItemRequestError])

  useEffect(() => {
    if (!denyItemRequestLoading && denyItemRequestError) {
      setLoadingDenyItemIds([])
    }
  }, [denyItemRequestLoading, denyItemRequestError])

  useEffect(() => {
    if (!getItemChangesLoading && getItemChangesError) {
      setLoadingAcceptItemIds([])
    }
  }, [getItemChangesLoading, getItemChangesError])

  useEffect(() => {
    if (!getAllFlightItinerariesLoading && getAllFlightItinerariesError) {
      setLoadingAcceptItemIds([])
      setLoadingDenyItemIds([])
    }
  }, [getAllFlightItinerariesLoading, getAllFlightItinerariesError])

  useEffect(() => {
    if (updatedFlightLeg.offerRequestsDetails.length > 0) {
      updatedFlightLeg.offerRequestsDetails.forEach((offerRequest) => {
        offerRequest.itemRequestDetails.forEach((itemRequest) => {
          const latestPriceChangeDetail = itemRequest.priceChangeDetails?.[0]
          const latestAvailabilityChangeDetail = itemRequest.availabilityChangeDetails?.[0]

          // TODO: Refactor so that this will be shown on the new offer request accordion that wraps these item requests accordions
          // instead of being on each item request accordion
          if (offerRequest.paymentStatus?.name === status.paymentStatus.paymentFailed) {
            setAlerts((prev) => ({
              ...prev,
              [itemRequest.id]: {
                message:
                  "The shopper's payment failed. You can choose to wait for them to update their payment method or you can deny the request.",
                type: 'error',
                tag: 'payment-failed'
              }
            }))
          } else {
            if (latestPriceChangeDetail) {
              const itemPriceIncreasedAndNotAcceptedByShopper =
                latestPriceChangeDetail.changed &&
                latestPriceChangeDetail.acceptedByShopper === false &&
                latestPriceChangeDetail.newValue > latestPriceChangeDetail.oldValue &&
                latestPriceChangeDetail.newValue !== parseFloat(itemRequest.acceptedItemPrice)

              if (itemPriceIncreasedAndNotAcceptedByShopper) {
                setAlerts((prev) => ({
                  ...prev,
                  [itemRequest.id]: {
                    message: `Price changed on Amazon from $${latestPriceChangeDetail.oldValue.toFixed(2)} to $${latestPriceChangeDetail.newValue.toFixed(2)}. The shopper needs to agree to the new price and has been notified.`,
                    type: 'warning',
                    tag: 'price-increased'
                  }
                }))
              }
            }

            if (latestAvailabilityChangeDetail) {
              const itemOutOfStock =
                latestAvailabilityChangeDetail.changed &&
                latestAvailabilityChangeDetail.newValue === 'Out of Stock' &&
                latestAvailabilityChangeDetail.oldValue === 'In Stock'

              if (itemOutOfStock) {
                setAlerts((prev) => ({
                  ...prev,
                  [itemRequest.id]: {
                    message: 'The item is now out of stock.',
                    type: 'warning',
                    tag: 'out-of-stock'
                  }
                }))
              }
            }
          }
        })
      })
    }
  }, [updatedFlightLeg])

  const handleAcceptItemRequest = (itemRequest: ItemRequestDetails) => {
    const itemRequestId = itemRequest.id.toString()
    const itemId = itemRequest.item.id.toString()

    const acceptItemRequestRequestPayload: AcceptItemRequestRequestPayload = {
      request: {
        itemRequestId
      },

      onSuccess: () => {
        refreshFlightItineraries(() => {
          setLoadingAcceptItemIds((prev) => prev.filter((id) => id !== itemRequestId))
        })
      }
    }

    const getItemChangesRequestPayload: GetItemChangesRequestPayload = {
      request: {
        id: itemId
      },

      onSuccess: (itemChanges: GetItemChangesData) => {
        if (itemChanges.price.changed) {
          const itemPriceIncreased = itemChanges.price.newValue > itemChanges.price.oldValue

          if (itemPriceIncreased) {
            setAlerts((prev) => ({
              ...prev,
              [itemRequestId]: {
                message: `Price changed on Amazon from $${itemChanges.price.oldValue} to $${itemChanges.price.newValue}. The shopper needs to agree to the new price and has been notified.`,
                type: 'warning',
                tag: 'price-increased'
              }
            }))

            setLoadingAcceptItemIds((prev) => prev.filter((id) => id !== itemRequestId))
          }
        } else if (itemChanges.availability.changed) {
          const itemNowOutOfStock =
            itemChanges.availability.oldValue === 'In Stock' &&
            itemChanges.availability.newValue !== itemChanges.availability.oldValue

          if (itemNowOutOfStock) {
            setAlerts((prev) => ({
              ...prev,
              [itemRequestId]: {
                message: 'The item is currently out of stock on Amazon.',
                type: 'warning',
                tag: 'out-of-stock'
              }
            }))

            setLoadingAcceptItemIds((prev) => prev.filter((id) => id !== itemRequestId))
          }
        } else {
          dispatch(acceptItemRequest(acceptItemRequestRequestPayload))
        }
      }
    }

    setLoadingAcceptItemIds((prev) => [...prev, itemRequestId])
    dispatch(getItemChanges(getItemChangesRequestPayload))
  }

  const handleDenyItemRequest = (itemRequestId: string) => {
    const request = {
      itemRequestId
    }

    setLoadingDenyItemIds((prev) => [...prev, itemRequestId])

    const onSuccess = () => {
      refreshFlightItineraries(() => {
        setLoadingDenyItemIds((prev) => prev.filter((id) => id !== itemRequestId))
      })
    }

    dispatch(denyItemRequest({ request, onSuccess }))
  }

  const handleBuyOnAmazon = useCallback(
    (itemRequest: ItemRequestDetails) => {
      const priceCommitedTo = committedPrice ?? itemRequest.committedPurchasePrice

      Modal.show({
        title: 'Buy on Amazon',
        subtitle: `Please confirm you would like to buy this item on Amazon${
          priceCommitedTo
            ? `. The price you locked in is ${itemRequest.item.currency} $${priceCommitedTo}.`
            : ` for a price of ${itemRequest.item.currency} $${parseFloat(itemRequest.item.price).toFixed(2)}. Once you commit to the price, we will lock it in.`
        }`,
        primaryButton: {
          label: 'Buy',
          onClick: () => {
            const request: CommitPurchasePriceRequestPayload = {
              request: {
                itemRequestId: itemRequest.id.toString()
              },

              onSuccess: () => {
                setCommittedPrice(parseFloat(itemRequest.item.price))
                window.open(itemRequest.item.internalUrl, '_blank')
              }
            }

            if (!priceCommitedTo) {
              dispatch(commitPurchasePrice(request))
            } else {
              window.open(itemRequest.item.internalUrl, '_blank')
            }
          }
        },
        secondaryButton: {
          label: 'Cancel'
        }
      })
    },
    [dispatch, committedPrice]
  )

  const renderItemDetails = (itemRequest: ItemRequestDetails, offerRequest: OfferRequestDetails) => {
    const itemRequestAccordionHeaderButtons: AccordionButtonProps[] = []

    // TODO: Resolve issue with tracking details not being closed properly when another tracking details is opened
    const handleTrackButtonPress = (id: number) => {
      const itemId = id.toString()

      if (expandedItemId === itemId) {
        setExpandedItemId(null)
        dispatch(clearItemRequestTrackingDetailsSuccess())
      } else {
        setExpandedItemId(itemId)

        const payload: GetItemRequestTrackingDetailsRequestPayload = {
          request: {
            itemRequestId: itemId
          }
        }

        dispatch(getItemRequestTrackingDetails(payload))
      }
    }

    const handleAccordionExpansion = (isExpanded: boolean, accordionKey: string | number | undefined) => {
      if (isExpanded) {
        setExpandedItemId(accordionKey?.toString() ?? null)
        handleTrackButtonPress(itemRequest.id)
      } else {
        setExpandedItemId(null)
      }
    }

    const hasFullWeightDetails = itemRequest.item.weight && itemRequest.item.weightUnit
    const isBuyButtonVisible = itemRequest.status.name !== status.deliveryStatus.purchasedByTraveler
    const isFlightBooked = flightItineraryStatus === status.flightStatus.flightBooked
    const isTrackButtonVisible = isFlightBooked

    const isAcceptButtonDisabled = () => {
      const latestPriceChangeDetail = itemRequest.priceChangeDetails?.[0]
      const latestAvailabilityChangeDetail = itemRequest.availabilityChangeDetails?.[0]
      let disabled = false
      let itemPriceIncreasedAndNotAcceptedByShopper = false
      let itemOutOfStock = false

      if (latestPriceChangeDetail) {
        itemPriceIncreasedAndNotAcceptedByShopper =
          latestPriceChangeDetail.changed &&
          latestPriceChangeDetail.acceptedByShopper === false &&
          latestPriceChangeDetail.newValue > latestPriceChangeDetail.oldValue &&
          latestPriceChangeDetail.newValue !== parseFloat(itemRequest.acceptedItemPrice)
      }

      if (latestAvailabilityChangeDetail) {
        itemOutOfStock =
          latestAvailabilityChangeDetail.changed &&
          latestAvailabilityChangeDetail.newValue === 'Out of Stock' &&
          latestAvailabilityChangeDetail.oldValue === 'In Stock'
      }

      if (alerts[itemRequest.id]?.tag === 'price-increased') {
        disabled = true
      } else if (alerts[itemRequest.id]?.tag === 'out-of-stock') {
        disabled = true
      } else if (itemPriceIncreasedAndNotAcceptedByShopper) {
        disabled = true
      } else if (itemOutOfStock) {
        disabled = true
      }

      if (loadingDenyItemIds.includes(itemRequest.id.toString())) {
        disabled = true
      }

      return disabled
    }

    const denyButton: AccordionButtonProps = {
      text: 'Deny',
      onClick: () => handleDenyItemRequest(itemRequest.id.toString()),
      buttonType: 'tertiary',
      loading:
        (denyItemRequestLoading || getAllFlightItinerariesLoading) &&
        loadingDenyItemIds.includes(itemRequest.id.toString()),
      disabled: loadingAcceptItemIds.includes(itemRequest.id.toString())
    }

    const latestPriceChangeDetail = itemRequest.priceChangeDetails?.[0]
    let itemPriceIncreasedAndNotAcceptedByShopper = false

    if (latestPriceChangeDetail) {
      itemPriceIncreasedAndNotAcceptedByShopper =
        latestPriceChangeDetail.changed &&
        latestPriceChangeDetail.acceptedByShopper === false &&
        latestPriceChangeDetail.newValue > latestPriceChangeDetail.oldValue &&
        latestPriceChangeDetail.newValue !== parseFloat(itemRequest.acceptedItemPrice)
    }

    if (offerRequest.paymentStatus?.name === status.paymentStatus.paymentFailed) {
      itemRequestAccordionHeaderButtons.push(denyButton)
    } else {
      switch (itemRequest?.status.name) {
        case status.itemRequestStatus.acceptedByTraveler:
        case status.deliveryStatus.purchasedByTraveler:
        case status.deliveryStatus.inTransitToTraveler:
        case status.deliveryStatus.deliveredToTravelerAddress:
        case status.deliveryStatus.confirmedReceivedByTraveler:
        case status.deliveryStatus.confirmedInDestinationCountry:
        case status.deliveryStatus.confirmedDeliveredToShopperByTraveler:
          if (isTrackButtonVisible) {
            itemRequestAccordionHeaderButtons.push({
              text: 'Track',
              icon: (
                <ExpandMore
                  style={{
                    transform: expandedItemId === itemRequest.id.toString() ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s'
                  }}
                />
              ),
              iconPosition: 'end',
              onClick: () => handleTrackButtonPress(itemRequest.id),
              expandAccordion: true,
              buttonType: 'tertiary'
            })
          }

          if (isBuyButtonVisible) {
            itemRequestAccordionHeaderButtons.push({
              text: 'Buy on Amazon',
              onClick: () => handleBuyOnAmazon(itemRequest),
              target: '_blank',
              buttonType: 'primary',
              disabled:
                flightItineraryStatus !== status.flightStatus.flightBooked || itemPriceIncreasedAndNotAcceptedByShopper,
              tooltipText:
                flightItineraryStatus !== status.flightStatus.flightBooked
                  ? 'Must book flight before you can buy this item on Amazon.'
                  : 'As an Amazon Associate, we earn from qualifying purchases.'
            })
          }

          break

        case status.deliveryStatus.confirmedDeliveredToShopperByShopper:
          itemRequestAccordionHeaderButtons.push({
            text: getUserRole() === UserRole.shopper ? 'Received' : 'Delivered',
            buttonType: 'primary',
            disabled: true
          })

          break

        case status.itemRequestStatus.deniedByTraveler:
          itemRequestAccordionHeaderButtons.push({
            text: 'Denied',
            buttonType: 'primary',
            disabled: true
          })

          break

        case status.itemRequestStatus.cancelledByShopper:
          itemRequestAccordionHeaderButtons.push({
            text: 'Cancelled By Shopper',
            buttonType: 'primary',
            disabled: true,
            tooltipText: 'The shopper has cancelled this request.'
          })

          break

        default:
          itemRequestAccordionHeaderButtons.push(denyButton)

          itemRequestAccordionHeaderButtons.push({
            text: 'Accept',
            onClick: () => handleAcceptItemRequest(itemRequest),
            buttonType: 'primary',
            loading:
              (acceptItemRequestLoading || getAllFlightItinerariesLoading || getItemChangesLoading) &&
              loadingAcceptItemIds.includes(itemRequest.id.toString()),
            disabled: isAcceptButtonDisabled(),
            tooltipText: isAcceptButtonDisabled() ? 'The item cannot currently be accepted.' : undefined
          })
          break
      }
    }

    const itemRequestAccordionHeader = {
      mainContent: (
        <Grid item key={itemRequest.id} sx={styles.itemRequestContainer}>
          <Grid item md={5} sx={styles.itemDetailsContainer}>
            <Box sx={styles.itemImageContainer}>
              <img src={itemRequest.item.images[0]} alt={itemRequest.item.name} style={styles.itemImage} />
            </Box>

            <Box sx={styles.itemDetailsInfoContainer}>
              <Typography sx={styles.itemTitle}>{itemRequest.item.name}</Typography>

              <Typography sx={styles.itemDetailsText}>
                {joinStringWithBullet([
                  `Qty: ${itemRequest.quantity}`,
                  hasFullWeightDetails ? `Weighing ${itemRequest.item.weight} ${itemRequest.item.weightUnit}` : null
                ])}

                {!hasFullWeightDetails && <span>{`Weighing: N/A`}</span>}
              </Typography>

              <Typography sx={styles.itemDetailsText}>
                {joinStringWithBullet([
                  `$${parseFloat(itemRequest.item.price).toFixed(2)}`,
                  `Amazon price as of ${date(itemRequest.item.priceAt).format(
                    `${dateTimeFormats.date.medium} ${dateTimeFormats.time.short}`
                  )} EST`
                ])}
              </Typography>

              {!isFlightBooked && (
                <Typography sx={styles.itemDetailsText}>
                  <Link href={itemRequest.item.internalUrl} target="_blank" showColor>
                    View on Amazon
                  </Link>
                </Typography>
              )}
            </Box>
          </Grid>

          <Divider orientation="vertical" flexItem sx={styles.itemDetailsDivider} />

          <Grid item md={4} sx={styles.shopperInfoContainer}>
            <Avatar
              firstName={offerRequest.shopperDetails.firstName}
              lastName={offerRequest.shopperDetails.lastName}
              size={AvatarSize.small}
            />

            <Box>
              <Typography sx={styles.shopperNameText}>
                {`${offerRequest.shopperDetails.firstName} ${offerRequest.shopperDetails.lastName.charAt(0)}.`}
              </Typography>

              <Typography sx={styles.itemDetailsText}>
                {`${offerRequest.deliveryDetails.city.name}, ${offerRequest.deliveryDetails.country.name}`}
              </Typography>
            </Box>
          </Grid>

          <Divider orientation="vertical" flexItem sx={styles.itemDetailsDivider} />

          <Grid item md={3}>
            <Typography sx={styles.itemPriceText}>{`$${itemRequest.travelerBenefit.toFixed(2)}`}</Typography>
            <Typography sx={styles.itemDetailsText}>Flight Discount</Typography>
          </Grid>
        </Grid>
      ),
      buttons: itemRequestAccordionHeaderButtons
    }

    const getTrackingDetails = () => {
      return (
        <TrackingDetails
          itemRequestId={itemRequest.id}
          destinationCity={offerRequest.deliveryDetails.city.name}
          key={itemRequest.id}
          isOpen={expandedItemId === itemRequest.id.toString()}
          refreshMainList={refreshFlightItineraries}
        />
      )
    }

    // TODO: Add logic to check item tracking status
    const isTrackingDetailsAccessible =
      itemRequest.status.name === status.itemRequestStatus.acceptedByTraveler &&
      flightItineraryStatus === status.flightStatus.flightBooked

    return (
      <Accordion
        header={itemRequestAccordionHeader}
        body={getTrackingDetails()}
        noExpandIcon
        noExpandBackgroundColor
        preventExpandChange={!isTrackingDetailsAccessible}
        handleExpansion={handleAccordionExpansion}
        accordionKey={itemRequest.id}
        key={itemRequest.id}
        alert={alerts[itemRequest.id] || undefined}
      />
    )
  }

  const renderLegDetails = () => (
    <Box key={updatedFlightLeg.id}>
      {updatedFlightLeg.offerRequestsDetails.map((offerRequest) =>
        offerRequest.itemRequestDetails.map((itemRequest) => renderItemDetails(itemRequest, offerRequest))
      )}
    </Box>
  )

  return <Box sx={styles.itemRequestAccordionContainer}>{renderLegDetails()}</Box>
}

export default ItemRequestAccordion
