import { SxProps } from '@mui/material'

import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(3, 0, 0, 0)
  } as SxProps,

  select: {
    minWidth: 200
  } as SxProps,

  confirmButton: {
    alignSelf: 'flex-end'
  } as SxProps
}

export default styles
