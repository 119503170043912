import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  GetBaggageDetailsErrorResponse,
  GetBaggageDetailsRequest,
  GetBaggageDetailsSuccessResponse
} from './getBaggageDetails.types'

export const getBaggageDetailsApi = async (
  request: GetBaggageDetailsRequest
): Promise<GetBaggageDetailsSuccessResponse> => {
  const response = await configureRequest({
    url: urls.flights.baggageDetails,
    method: 'GET',
    data: request,
    params: {
      'external-system-flight-offer-id': request['external-system-flight-offer-id']
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetBaggageDetailsSuccessResponse
  } else {
    throw response as GetBaggageDetailsErrorResponse
  }
}

export default getBaggageDetailsApi
