import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  GetItemDetailsErrorResponse,
  GetItemDetailsRequest,
  GetItemDetailsSuccessResponse
} from './getItemDetails.types'

export const getItemDetailsApi = async (request: GetItemDetailsRequest): Promise<GetItemDetailsSuccessResponse> => {
  const response = await configureRequest({
    url: urls.items.getItemDetails,
    method: 'POST',
    data: request,
    headers: {
      'X-User-Role': UserRole.shopper
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetItemDetailsSuccessResponse
  } else {
    throw response as GetItemDetailsErrorResponse
  }
}

export default getItemDetailsApi
