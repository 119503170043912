import React from 'react'

import { Box, CircularProgress, Typography } from '@mui/material'

import { sentenceCase } from '../../functions/String/string.functions'
import styles from './Loader.styles'
import { LoaderProps, loaderSizeMap } from './Loader.types'

const Loader: React.FC<LoaderProps> = (props) => {
  const { loading, text, size, marginTop = 0 } = props

  if (!loading) return null

  return (
    <Box sx={styles.loaderContainer(marginTop)}>
      <CircularProgress size={loaderSizeMap[size as keyof typeof loaderSizeMap]} />

      {text && <Typography sx={styles.loaderText}>{sentenceCase(text)}</Typography>}
    </Box>
  )
}

export default Loader
