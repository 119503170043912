import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  ReleaseOfferRequestErrorResponse,
  ReleaseOfferRequestRequest,
  ReleaseOfferRequestSuccessResponse
} from './releaseOfferRequest.types'

export const releaseOfferRequestApi = async (
  request: ReleaseOfferRequestRequest
): Promise<ReleaseOfferRequestSuccessResponse> => {
  const response = await configureRequest({
    url: urls.offers.releaseOfferRequest,
    method: 'POST',
    data: request,
    params: {},
    headers: {
      'X-User-Role': UserRole.shopper
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as ReleaseOfferRequestSuccessResponse
  } else {
    throw response as ReleaseOfferRequestErrorResponse
  }
}

export default releaseOfferRequestApi
