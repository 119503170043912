import React from 'react'

import { LocationOn } from '@mui/icons-material'
import { Box, Divider, Typography } from '@mui/material'

import Avatar from '../../../../shared/components/Avatar/Avatar.component'

import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'
import styles from './HourrierCard.styles'
import { HourrierCardProps } from './HourrierCard.types'

const HourrierCard: React.FC<HourrierCardProps> = ({ flightItinerary }) => {
  const firstLeg = flightItinerary.itinerary.legs[0]

  return (
    <Box sx={styles.container}>
      <Typography variant="h6" sx={styles.title}>
        Your Hurrier
      </Typography>

      <Divider sx={styles.divider} />

      <Box sx={styles.content}>
        <Avatar firstName={flightItinerary.traveler.firstName} lastName={flightItinerary.traveler.lastName} />

        <Box sx={styles.details}>
          <Typography variant="h6" sx={styles.name}>
            {`${flightItinerary.traveler.firstName} ${flightItinerary.traveler.lastName}`}
          </Typography>

          <Box sx={styles.travelInfo}>
            <LocationOn sx={styles.locationIcon} />

            <Typography variant="body2" sx={styles.travelText}>
              Travelling to {firstLeg.destination.country.name} on{' '}
              {date(firstLeg.travelDate).format(dateTimeFormats.date.medium)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default HourrierCard
