import { setAuthenticationStatus } from '../../../networkRequests/apiClient/apiClient.functions'
import Snackbar from '../../components/Snackbar/Snackbar.functions'
import { ApiErrorResponse } from '../../types/api.types'

/**
 * Handles API errors and displays a snackbar if the error is not handled by the caller.
 * @param error - The error object.
 * @param onError - An optional callback function to be called after the error is handled.
 * @param hideSnackbar - An optional boolean to hide the snackbar.
 */
export const handleApiError = (
  error: ApiErrorResponse,
  onError?: (data?: ApiErrorResponse) => void,
  hideSnackbar?: boolean
): void => {
  const errorMessage = error?.userFriendlyMessage || 'An error occurred processing your request. Please try again.'

  if (
    error?.status === 401 &&
    (error?.message.includes('Token is required') || error?.message.includes('Invalid or expired token'))
  ) {
    setAuthenticationStatus(false)

    if (hideSnackbar !== true) {
      Snackbar.show({ message: 'Your session has expired. Please login again.', severity: 'warning' })
    }
  } else {
    if (hideSnackbar !== true) {
      Snackbar.show({ message: errorMessage, severity: 'error' })
    }
  }

  onError && onError(error)
}

/**
 * Checks if the error code matches any of the provided codes.
 * @param error - The error object.
 * @param codes - The list of error codes to check against.
 * @returns A boolean indicating whether the error code matches any of the provided codes.
 */
const isErrorCode = (error: unknown, ...codes: number[]): boolean => codes.includes((error as ApiErrorResponse).status)

/**
 * Handles default errors and displays a snackbar if the error is not handled by the caller.
 * @param error - The error object.
 */
export const handleDefaultError = (error: unknown) => {
  errorHandler.handleApiError(error as ApiErrorResponse)
}

const errorHandler = {
  handleDefaultError,
  handleApiError,
  isErrorCode
}

export default errorHandler
