import theme from '../../../../styles/themes/default.theme'

const styles = {
  connector: {
    '& .MuiStepConnector-line': {
      borderWidth: 3,
      borderColor: theme.palette.blue[800],
      borderRadius: '2px'
    }
  }
}

export default styles
