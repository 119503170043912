import React from 'react'

import { Box } from '@mui/material'

import ItemDetails from '../ItemDetails/ItemDetails.component'

import { CartItem } from '../../Cart.types'
import styles from './CartStep.styles'
import { CartStepProps } from './CartStep.types'

const CartStep: React.FC<CartStepProps> = (props) => {
  const { cartItems, updateItemQuantity, removeItemFromCart } = props

  return (
    <Box sx={styles.container}>
      {cartItems.map((item: CartItem) => (
        <ItemDetails
          key={item.id}
          item={item}
          updateQuantity={(newQuantity) => updateItemQuantity(item.id, newQuantity)}
          removeItemFromCart={() => removeItemFromCart(item)}
        />
      ))}
    </Box>
  )
}

export default CartStep
