import React, { useCallback, useMemo, useState } from 'react'

import { Box, Tab, Tabs, Typography } from '@mui/material'

import Modal from '../../../../../../../../shared/components/Modal/Modal.component'
import FlightsDropdown from '../../../FlightsDropdown/FlightsDropdown.component'
import SeatMap from './components/SeatMap.component'

import { GetSeatDetailsData } from '../../../../../../../../redux/states/flights/getSeatDetails/getSeatDetails.types'
import { useAppSelector } from '../../../../../../../../shared/hooks/redux.hooks'
import styles from './SeatDetailsModal.styles'
import { SeatDetailsModalProps, SeatSelections } from './SeatDetailsModal.types'

const SeatDetailsModal: React.FC<SeatDetailsModalProps> = ({
  open,
  onClose,
  fareOffer,
  onSubmitSelections,
  initialSelections
}) => {
  const { success: seatDetailsResponse } = useAppSelector((state) => state.getSeatDetails)
  const [selectedSegmentId, setSelectedSegmentId] = useState<string>(fareOffer.slices[0].segments[0].id)
  const [selectedPassenger, setSelectedPassenger] = useState(0)
  const [selectedSeats, setSelectedSeats] = useState<SeatSelections>(initialSelections)
  const [isLastFlight, setIsLastFlight] = useState(false)

  const selectedSegmentData = useMemo(() => {
    return seatDetailsResponse?.data.find((segment: GetSeatDetailsData) => segment.segment_id === selectedSegmentId)
  }, [seatDetailsResponse, selectedSegmentId])

  const updateIsLastFlight = useCallback(
    (segmentId: string) => {
      const flightOptions = fareOffer.slices.flatMap((slice) => slice.segments)
      setIsLastFlight(flightOptions[flightOptions.length - 1].id === segmentId)
    },
    [fareOffer]
  )

  const handleFlightChange = useCallback(
    (newSegmentId: string) => {
      setSelectedSegmentId(newSegmentId)
      updateIsLastFlight(newSegmentId)
    },
    [updateIsLastFlight]
  )

  const handlePassengerChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedPassenger(newValue)
  }

  const handleSeatSelect = (seatId: string, seatDesignator: string, serviceId: string, seatPrice: string) => {
    setSelectedSeats((prevSeats) => {
      const newSeats = { ...prevSeats }
      const currentPassengerId = fareOffer.passengers[selectedPassenger].id

      if (!newSeats[selectedSegmentId]) {
        newSeats[selectedSegmentId] = {}
      }

      if (newSeats[selectedSegmentId][currentPassengerId]?.id === seatId) {
        delete newSeats[selectedSegmentId][currentPassengerId]
      } else {
        // Select the seat
        newSeats[selectedSegmentId][currentPassengerId] = {
          id: seatId,
          serviceId,
          designator: seatDesignator,
          price: seatPrice
        }
      }

      return newSeats
    })
  }

  const getTotalPrice = () => {
    return Object.entries(selectedSeats)
      .reduce((total, [segmentId, passengerSeats]) => {
        const segmentData = seatDetailsResponse?.data.find(
          (segment: GetSeatDetailsData) => segment.segment_id === segmentId
        )

        return (
          total +
          Object.values(passengerSeats).reduce((segmentTotal, seat) => {
            const seatPrice = getSeatPrice(seat.id, segmentData)

            return segmentTotal + parseFloat(seatPrice)
          }, 0)
        )
      }, 0)
      .toFixed(2)
  }

  const getSeatPrice = (seatId: string, segmentData?: GetSeatDetailsData): string => {
    const seat = segmentData?.cabins[0].rows
      .flatMap((row) => row.sections)
      .flatMap((section) => section.elements)
      .find((element) => element.id === seatId)

    return seat?.available_services[0]?.total_amount || '0'
  }

  const handleNextFlight = useCallback(() => {
    const flightOptions = fareOffer.slices.flatMap((slice) => slice.segments)
    const currentIndex = flightOptions.findIndex((segment) => segment.id === selectedSegmentId)
    if (currentIndex < flightOptions.length - 1) {
      const nextSegmentId = flightOptions[currentIndex + 1].id
      setSelectedSegmentId(nextSegmentId)
      updateIsLastFlight(nextSegmentId)
    }
  }, [fareOffer, selectedSegmentId, updateIsLastFlight])

  const handleConfirm = useCallback(() => {
    onSubmitSelections(selectedSeats)
    onClose()
  }, [selectedSeats, onSubmitSelections, onClose])

  const modalBody = (
    <Box sx={styles.modalBody}>
      <FlightsDropdown fareOffer={fareOffer} value={selectedSegmentId} onChange={handleFlightChange} />
      <Tabs value={selectedPassenger} onChange={handlePassengerChange} aria-label="passenger tabs" sx={styles.tabs}>
        {fareOffer.passengers.map((passenger, index) => (
          <Tab key={passenger.id} label={`Passenger ${index + 1}`} />
        ))}
      </Tabs>
      {selectedSegmentData && (
        <SeatMap
          seatData={selectedSegmentData.cabins[0]}
          onSeatSelect={handleSeatSelect}
          selectedSeat={selectedSeats[selectedSegmentId]?.[fareOffer.passengers[selectedPassenger].id]}
          passengerId={fareOffer.passengers[selectedPassenger].id}
        />
      )}
    </Box>
  )

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Seat Selection"
      body={modalBody}
      maxWidth="md"
      headerRightSection={
        <Box sx={styles.headerRightSection}>
          <Typography sx={styles.totalCost} variant="h5">
            Total: ${getTotalPrice()}
          </Typography>
          <Typography sx={styles.seatsSelected} variant="body2">
            {Object.values(selectedSeats).reduce((count, segment) => count + Object.keys(segment).length, 0)} of{' '}
            {fareOffer.passengers.length * fareOffer.slices.length} seats selected
          </Typography>
        </Box>
      }
      primaryButton={{
        label: isLastFlight ? 'Done' : 'Next Flight',
        onClick: isLastFlight ? handleConfirm : handleNextFlight
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose
      }}
    />
  )
}

export default SeatDetailsModal
