import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { updateDeliveryAddressApi } from './updateDeliveryAddress.api'
import updateDeliveryAddressInitialState from './updateDeliveryAddress.initialState'
import { UpdateDeliveryAddressErrorResponse, UpdateDeliveryAddressRequest } from './updateDeliveryAddress.types'

export const updateDeliveryAddress = createAsyncThunk(
  urls.profile.updateDeliveryAddress,
  async (
    {
      updateDeliveryAddressRequest,
      onSuccess
    }: { updateDeliveryAddressRequest: UpdateDeliveryAddressRequest; onSuccess: () => void },
    { rejectWithValue }
  ) => {
    try {
      const response = await updateDeliveryAddressApi(updateDeliveryAddressRequest)

      onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const updateDeliveryAddressSlice = createSlice({
  name: urls.profile.updateDeliveryAddress,
  initialState: updateDeliveryAddressInitialState,
  reducers: {
    // Add any additional reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateDeliveryAddress.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(updateDeliveryAddress.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(updateDeliveryAddress.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as UpdateDeliveryAddressErrorResponse
        state.success = null
      })
  }
})

export const { actions: updateDeliveryAddressActions, reducer: updateDeliveryAddressReducer } =
  updateDeliveryAddressSlice
export default updateDeliveryAddressSlice.reducer
