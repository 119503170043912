import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { getUpdatedFlightOfferApi } from './getUpdatedFlightOffer.api'
import getUpdatedFlightOfferInitialState from './getUpdatedFlightOffer.initialState'
import { GetUpdatedFlightOfferErrorResponse, GetUpdatedFlightOfferRequestPayload } from './getUpdatedFlightOffer.types'

export const getUpdatedFlightOffer = createAsyncThunk(
  urls.flights.getUpdatedFlightOffer,
  async (payload: GetUpdatedFlightOfferRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess, onError } = payload

    try {
      const response = await getUpdatedFlightOfferApi(request)

      onSuccess && onSuccess(response)

      return response
    } catch (error) {
      if (onError) {
        onError(error as GetUpdatedFlightOfferErrorResponse)
      } else {
        errorHandler.handleApiError(error as ApiErrorResponse)
      }

      return rejectWithValue(error)
    }
  }
)

const getUpdatedFlightOfferSlice = createSlice({
  name: urls.flights.getUpdatedFlightOffer,
  initialState: getUpdatedFlightOfferInitialState,
  reducers: {
    // Add any additional reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUpdatedFlightOffer.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getUpdatedFlightOffer.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getUpdatedFlightOffer.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetUpdatedFlightOfferErrorResponse
        state.success = null
      })
  }
})

export const { actions: getUpdatedFlightOfferActions, reducer: getUpdatedFlightOfferReducer } =
  getUpdatedFlightOfferSlice
export default getUpdatedFlightOfferSlice.reducer
