import React from 'react'

import StatusUpdatePage from '../../shared/components/StatusUpdatePage/StatusUpdatePage.component'

import routes from '../../router/routes.dictionary'

const ForgetPasswordSuccess: React.FC = () => {
  return (
    <StatusUpdatePage
      title="We Sent a Link"
      subtitle="A confirmation link has been sent to your email address. Please check your email and click the link to create your new password."
      status="success"
      action={{
        prompt: "Didn't receive the email?",
        link: routes.forgetPassword.path,
        linkText: 'Resend'
      }}
    />
  )
}

export default ForgetPasswordSuccess
