import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { getUserRole } from '../../../../shared/functions/UserRole/userRoleManagment.functions'
import {
  GetItemRequestTrackingDetailsErrorResponse,
  GetItemRequestTrackingDetailsRequest,
  GetItemRequestTrackingDetailsSuccessResponse
} from './getItemRequestTrackingDetails.types'

export const getItemRequestTrackingDetailsApi = async (
  request: GetItemRequestTrackingDetailsRequest
): Promise<GetItemRequestTrackingDetailsSuccessResponse> => {
  const response = await configureRequest({
    url: urls.offers.getItemRequestTrackingDetails,
    method: 'POST',
    data: request,
    headers: {
      'X-User-Role': getUserRole() || ''
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetItemRequestTrackingDetailsSuccessResponse
  } else {
    throw response as GetItemRequestTrackingDetailsErrorResponse
  }
}

export default getItemRequestTrackingDetailsApi
