import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { denyItemRequestApi } from './denyItemRequest.api'
import denyItemRequestInitialState from './denyItemRequest.initialState'
import { DenyItemRequestErrorResponse, DenyItemRequestRequestPayload } from './denyItemRequest.types'

export const denyItemRequest = createAsyncThunk(
  urls.offers.denyItemRequest,
  async (payload: DenyItemRequestRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await denyItemRequestApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const denyItemRequestSlice = createSlice({
  name: urls.offers.denyItemRequest,
  initialState: denyItemRequestInitialState,
  reducers: {
    // Add any additional reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(denyItemRequest.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(denyItemRequest.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(denyItemRequest.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as DenyItemRequestErrorResponse
        state.success = null
      })
  }
})

export const { actions: denyItemRequestActions, reducer: denyItemRequestReducer } = denyItemRequestSlice
export default denyItemRequestSlice.reducer
