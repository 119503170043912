import { CommitPurchasePriceState } from './commitPurchasePrice.types'

const initialState: CommitPurchasePriceState = {
  loading: false,
  error: null,
  success: null
  // Initialize any additional state properties here
}

export default initialState
