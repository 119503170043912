import axios, { AxiosError } from 'axios'
import qs from 'qs'

import localStorage from '../../shared/functions/LocalStorage/localStorage'
import logger from '../../shared/functions/Logger/logger.functions'
import { ApiErrorResponse, ApiResponse } from '../../shared/types/api.types'
import { ApiClientRequestConfig } from './apiClient.types'
import env from './env.config'

/**
 * Configures and sends an API request.
 *
 * @param {ApiClientRequestConfig} config - The configuration object for the request.
 * @param {string} config.url - The URL to which the request is sent.
 * @param {'GET' | 'POST' | 'PUT' | 'DELETE'} config.method - The HTTP method to use for the request.
 * @param {Record<string, string>} [config.headers] - Optional headers to include in the request.
 * @param {object} [config.data] - Optional data to send with the request.
 * @param {number} [config.timeout=20000] - Optional timeout for the request in milliseconds.
 * @returns {Promise<ApiResponse<object>>} - A promise that resolves to the response data which will be of type ApiSuccessResponse or ApiErrorResponse.
 *
 * @throws {AxiosError | ApiErrorResponse} Will throw an error if the request fails.
 *
 * @example
 * const response = await configureRequest({
 *   url: urls.auth.signUp,
 *   method: 'POST',
 *   data: {
 *     username: 'exampleUser',
 *     email: 'user@example.com',
 *     password: 'examplePassword',
 *   },
 * })
 * console.log(response)
 */
export const configureRequest = async (config: ApiClientRequestConfig): Promise<ApiResponse<object>> => {
  const { url, method, headers = {}, data, timeout = 30000, params } = config

  const defaultHeaders = {
    'Content-Type': 'application/json',
    ...headers
  }

  const requestConfig: ApiClientRequestConfig = {
    url: `${env.API_BASE_URL}/${url}`,
    method,
    headers: defaultHeaders,
    data,
    timeout,
    withCredentials: true,
    params
  }

  return executeRequest(requestConfig)
    .then((response) => {
      return response
    })
    .catch((error) => {
      logger.logError(error, 'Error during request', 'configureRequest')
      throw error
    })
}

/**
 * Executes an API request using the provided configuration.
 *
 * @param {ApiClientRequestConfig} requestConfig - The configuration object for the request.
 * @returns {Promise<ApiResponse<object>>} - A promise that resolves to the response data which will be of type ApiSuccessResponse or ApiErrorResponse.
 *
 * @throws {AxiosError | ApiErrorResponse} Will throw an error if the request fails.
 *
 * @example
 * const response = await executeRequest({
 *   url: 'https://api.example.com/data',
 *   method: 'GET',
 *   headers: {
 *     'Authorization': 'Bearer token'
 *   }
 * })
 * console.log(response)
 */
export const executeRequest = async (requestConfig: ApiClientRequestConfig): Promise<ApiResponse<object>> => {
  try {
    const response = await axios({
      ...requestConfig,
      paramsSerializer: {
        serialize: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      }
    })

    return response.data
  } catch (error) {
    const axiosError = error as AxiosError
    const hourrierError = axiosError.response?.data as ApiErrorResponse
    const errorMessage = hourrierError?.message || 'Unknown error'

    logger.logError(hourrierError ?? axiosError, errorMessage, 'executeRequest')

    throw hourrierError ?? axiosError
  }
}

/**
 * Sets the authentication status in localStorage.
 *
 * @param {boolean} status - The authentication status to set.
 * @example
 * setAuthenticationStatus(true)
 */
export const setAuthenticationStatus = (status: boolean) => {
  localStorage.setItem('sessionAuthenticated', status.toString())
}

/**
 * Checks if the user is authenticated based on the stored authentication status.
 *
 * @returns {boolean} - True if the user is authenticated, otherwise false.
 * @example
 * const isAuth = isAuthenticated()
 * console.log(isAuth)
 */
export const isAuthenticated = () => {
  const authStatus = localStorage.getItem('sessionAuthenticated')

  return authStatus === 'true'
}
