import React, { useMemo } from 'react'

import { Box } from '@mui/material'
import { loadConnectAndInitialize } from '@stripe/connect-js'
import { ConnectAccountOnboarding, ConnectComponentsProvider } from '@stripe/react-connect-js'

import env from '../../../networkRequests/apiClient/env.config'
import theme from '../../styles/themes/default.theme'
import styles from './StripeConnectOnboardingForm.styles'
import { StripeConnectOnboardingFormProps } from './StripeConnectOnboardingForm.types'

const StripeConnectOnboardingForm: React.FC<StripeConnectOnboardingFormProps> = ({
  accountSessionClientSecret,
  onOnboardingComplete
}: StripeConnectOnboardingFormProps) => {
  const stripeConnectInstance = useMemo(() => {
    const stripeConnectInstance = loadConnectAndInitialize({
      publishableKey: env.STRIPE_PUBLISHABLE_KEY,
      fetchClientSecret: async () => accountSessionClientSecret,
      appearance: {
        overlays: 'dialog',
        variables: {
          colorPrimary: theme.palette.primary.main
        }
      }
    })

    return stripeConnectInstance
  }, [accountSessionClientSecret])

  return (
    <Box sx={styles.container}>
      <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
        <ConnectAccountOnboarding
          onExit={() => {
            onOnboardingComplete()
          }}
        />
      </ConnectComponentsProvider>
    </Box>
  )
}

export default StripeConnectOnboardingForm
