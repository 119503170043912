import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  AcceptItemPriceChangeErrorResponse,
  AcceptItemPriceChangeRequest,
  AcceptItemPriceChangeSuccessResponse
} from './acceptItemPriceChange.types'

export const acceptItemPriceChangeApi = async (
  request: AcceptItemPriceChangeRequest
): Promise<AcceptItemPriceChangeSuccessResponse> => {
  const response = await configureRequest({
    url: urls.items.acceptItemPriceChange,
    method: 'POST',
    data: request,
    headers: {
      'X-User-Role': UserRole.shopper
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as AcceptItemPriceChangeSuccessResponse
  } else {
    throw response as AcceptItemPriceChangeErrorResponse
  }
}

export default acceptItemPriceChangeApi
