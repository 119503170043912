import React from 'react'

import Close from '@mui/icons-material/Close'
import { Chip as MuiChip, Stack } from '@mui/material'

import { truncateString } from '../../functions/String/string.functions'
import styles from './Chip.styles'
import { ChipProps } from './Chip.types'

export const Chip: React.FC<ChipProps> = ({ error, showCloseIcon = true, ...props }) => {
  const { label, sx } = props

  const chipStyles = { ...(error ? styles.errorChip : styles.chip), ...sx }

  return (
    <MuiChip
      {...props}
      onDelete={props.onDelete}
      color={error ? 'error' : 'default'}
      sx={chipStyles}
      deleteIcon={showCloseIcon ? <Close sx={styles.chipCloseIcon} /> : <></>}
      label={
        <>
          <Stack direction={'row'}>
            {label && <span className="label-text">{truncateString(label as string)}</span>}
            {error && <span className="error-text">{truncateString(error.text, error?.maxLength ?? 30)}</span>}
          </Stack>
        </>
      }
    />
  )
}
