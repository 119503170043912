import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  DenyItemRequestErrorResponse,
  DenyItemRequestRequest,
  DenyItemRequestSuccessResponse
} from './denyItemRequest.types'

export const denyItemRequestApi = async (request: DenyItemRequestRequest): Promise<DenyItemRequestSuccessResponse> => {
  const response = await configureRequest({
    url: urls.offers.denyItemRequest,
    method: 'POST',
    headers: {
      'X-User-Role': UserRole.traveler
    },
    params: {
      itemRequestId: request.itemRequestId
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as DenyItemRequestSuccessResponse
  } else {
    throw response as DenyItemRequestErrorResponse
  }
}

export default denyItemRequestApi
