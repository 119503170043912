const styles = (active: boolean) => {
  return {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: active ? '#6b7280' : '#D9D9D9',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease'
  }
}

export const stepperContainerStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  paddingTop: '20px'
}

export default styles
