import React, { useEffect, useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Box, Divider, IconButton, MenuItem, Select, Typography } from '@mui/material'

import styles from './PassengerSelection.styles'
import { PassengerSelectionProps } from './PassengerSelection.types'

const PassengerSelection: React.FC<PassengerSelectionProps> = ({
  adults,
  children,
  childrenAges,
  setAdults,
  setChildren,
  setChildrenAges
}) => {
  const [ageErrors, setAgeErrors] = useState<string[]>([])

  useEffect(() => {
    const errors = childrenAges.map((age, index) => (age === 0 ? `Age for child ${index + 1} must be selected` : ''))
    setAgeErrors(errors)
  }, [childrenAges])

  const handleAddAdult = () => setAdults(adults + 1)
  const handleRemoveAdult = () => setAdults(Math.max(1, adults - 1))

  const handleAddChild = () => {
    setChildren(children + 1)
    setChildrenAges([...childrenAges, 0])
  }

  const handleRemoveChild = () => {
    if (children > 0) {
      setChildren(children - 1)
      setChildrenAges(childrenAges.slice(0, -1))
    }
  }

  const handleChildAgeChange = (index: number, age: number) => {
    const newAges = [...childrenAges]
    newAges[index] = age
    setChildrenAges(newAges)
  }

  return (
    <Box sx={styles.passengerSelectionContainer}>
      <Box sx={styles.passengerTypeContainer}>
        <Typography>Adults</Typography>

        <Box sx={styles.counterContainer}>
          <IconButton onClick={handleRemoveAdult} sx={styles.counterButton}>
            <RemoveIcon />
          </IconButton>

          <Typography>{adults}</Typography>

          <IconButton onClick={handleAddAdult} sx={styles.counterButton}>
            <AddIcon />
          </IconButton>
        </Box>
      </Box>

      <Divider sx={styles.divider} />

      <Box sx={styles.passengerTypeContainer}>
        <Typography>Children</Typography>

        <Box sx={styles.counterContainer}>
          <IconButton onClick={handleRemoveChild} sx={styles.counterButton}>
            <RemoveIcon />
          </IconButton>

          <Typography>{children}</Typography>

          <IconButton onClick={handleAddChild} sx={styles.counterButton}>
            <AddIcon />
          </IconButton>
        </Box>
      </Box>

      {children > 0 && (
        <Box sx={styles.childrenAgeContainer}>
          {childrenAges.map((age, index) => (
            <Box key={index} sx={styles.childAgeField}>
              <Typography sx={styles.ageLabel}>Age of child {index + 1} *</Typography>

              <Select
                value={age}
                onChange={(e) => handleChildAgeChange(index, e.target.value as number)}
                displayEmpty
                sx={styles.ageDropdown}
              >
                <MenuItem value={0}>Select age</MenuItem>
                {[...Array(18).keys()].map((age) => (
                  <MenuItem key={age} value={age}>
                    {age}
                  </MenuItem>
                ))}
              </Select>

              {ageErrors[index] && <Typography color="error">{ageErrors[index]}</Typography>}
            </Box>
          ))}

          <Typography sx={styles.ageInfoText}>
            A child's age must be valid for the full duration of the journey. For example, if a child has a birthday
            during a trip, please use their age on the date of the returning flight.
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default PassengerSelection
