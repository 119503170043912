import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  GetFlightItineraryShareLinkErrorResponse,
  GetFlightItineraryShareLinkRequest,
  GetFlightItineraryShareLinkSuccessResponse
} from './getFlightItineraryShareLink.types'

export const getFlightItineraryShareLinkApi = async (
  request: GetFlightItineraryShareLinkRequest
): Promise<GetFlightItineraryShareLinkSuccessResponse> => {
  const response = await configureRequest({
    url: urls.flights.getFlightItineraryShareLink,
    method: 'GET',
    params: {
      id: request.id
    },
    headers: {
      'X-User-Role': UserRole.traveler
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetFlightItineraryShareLinkSuccessResponse
  } else {
    throw response as GetFlightItineraryShareLinkErrorResponse
  }
}

export default getFlightItineraryShareLinkApi
