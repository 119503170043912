import { CSSProperties } from 'react'

import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  itemRequestContainer: {
    display: 'flex',
    alignItems: 'center'
  } as CSSProperties,

  itemDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  } as CSSProperties,

  itemDetailsInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  } as CSSProperties,

  itemImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(3),
    width: '32px',
    height: '32px'
  } as CSSProperties,

  itemImage: {
    objectFit: 'contain',
    width: '32px',
    height: '32px'
  } as CSSProperties,

  itemTitle: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical'
  } as CSSProperties,

  itemDetailsLinkText: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left',
    color: theme.palette.primary.main
  } as CSSProperties,

  itemDetailsDivider: {
    backgroundColor: theme.palette.grey[200],
    margin: theme.spacing(0, 4)
  } as CSSProperties,

  shopperInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  } as CSSProperties,

  rowTitleText: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left',
    color: theme.palette.grey[500]
  } as CSSProperties,

  amazonPriceText: {
    fontSize: theme.typography.caption.fontSize,
    fontStyle: 'italic',
    textAlign: 'left'
  } as CSSProperties,

  rowValueText: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left'
  } as CSSProperties
}

export default styles
