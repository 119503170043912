import React, { useCallback, useState } from 'react'

import { LocationOn } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import Avatar from '../../../../shared/components/Avatar/Avatar.component'
import Button from '../../../../shared/components/Button/Button.component'
import AcceptOffer from '../AcceptOffer/AcceptOffer.component'
import ItemDetails from '../ItemDetails/ItemDetails.component'

import { FlightItinerary } from '../../../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import { clearFlightSearchResults } from '../../../../redux/states/flights/searchFlights/searchFlights.slice'
import { acceptPublicOffer } from '../../../../redux/states/offerRequest/acceptPublicOffer/acceptPublicOffer.slice'
import { OfferRequest } from '../../../../redux/states/offerRequest/getPublicOffers/getPublicOffers.types'
import { AppDispatch, RootState } from '../../../../redux/store/store.types'
import router from '../../../../router/functions/router.functions'
import routes from '../../../../router/routes.dictionary'
import Modal from '../../../../shared/components/Modal/Modal.functions'
import Snackbar from '../../../../shared/components/Snackbar/Snackbar.functions'
import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux.hooks'
import { getFormattedTripDetails } from '../../../Requests/Requests.functions'
import { validateOfferAcceptance } from './OfferItem.functions'
import styles from './OfferItem.styles'
import { AccepOfferValidationResult, OfferItemProps } from './OfferItem.types'

const OfferItem: React.FC<OfferItemProps> = ({ offer, flightItineraries, onSelect, isSelected }) => {
  const dispatch = useAppDispatch()
  const navigate = router.navigate()
  const [offerAccepted, setOfferAccepted] = useState(false)
  const [loadingOfferId, setLoadingOfferId] = useState<string | null>(null)
  const { loading: acceptPublicOfferLoading } = useAppSelector((state: RootState) => state.acceptPublicOffer)

  const handleSelect = () => {
    if (onSelect) {
      onSelect(offer)
    }
  }

  const onConfirm = useCallback(
    (offer: OfferRequest, flightItinerary: FlightItinerary, dispatch: AppDispatch, onOfferAccepted: () => void) => {
      setLoadingOfferId(offer.offerRequestId.toString())
      dispatch(
        acceptPublicOffer({
          acceptPublicOfferRequest: {
            offerRequestId: offer.offerRequestId,
            confirmationFlightItineraryId: flightItinerary.id,
            isConfirming: true
          },
          onSuccess: () => {
            Snackbar.show({
              message: 'Offer successfully accepted! Go to the Requests page to see your accepted offers.',
              action: {
                label: 'Go to Requests',
                onClick: () => {
                  navigate(routes.requests.path)
                  window.scrollTo(0, 0)
                }
              },
              severity: 'success'
            })
            onOfferAccepted()
            setLoadingOfferId(null)
          }
        })
      )
    },
    [navigate]
  )

  const onAccepOfferValidation = useCallback((): AccepOfferValidationResult => {
    const items = offer.offerRequestDetails.map((item) => item.itemDetails)
    const deliveryLocation = offer.shopperDetails.deliveryTo
    const validation = validateOfferAcceptance(flightItineraries, items, deliveryLocation, offer)

    if (validation.noMatchingFlightItineraries) {
      const neededByDate = offer.neededByDate ? `${date(offer.neededByDate).format(dateTimeFormats.date.medium)}` : ''

      // Show "Book Flight" confirmation modal
      Modal.show({
        title: 'Book Flight',
        subtitle: (
          <span>
            You are required to have a flight leg from{' '}
            <span style={styles.linkText}>{validation.requiredCountries.itemLocation.name}</span> to{' '}
            <span style={styles.linkText}>
              {validation.requiredCountries.deliveryLocation.city}, {validation.requiredCountries.deliveryLocation.name}
            </span>{' '}
            {neededByDate && (
              <span>
                arriving by <span style={styles.linkText}>{neededByDate}</span>
              </span>
            )}{' '}
            to accept this offer.
          </span>
        ),
        primaryButton: {
          label: 'Book Flight',
          onClick: () => {
            dispatch(clearFlightSearchResults())
            navigate(routes.flightSearchResults.path, {
              state: {
                requiredCountries: validation.requiredCountries,
                selectedOffer: offer
              }
            })
          }
        },
        secondaryButton: {
          label: 'Cancel'
        }
      })
    } else if (!validation.noMatchingFlightItineraries && !validation.multipleOptions) {
      // Show "Select Offer" confirmation modal (if not multiple options)
      Modal.show({
        title: 'Select Offer',
        subtitle: `This offer will be assigned to the following itinerary:`,
        body: (
          <Box>
            <Typography variant="body1">
              {getFormattedTripDetails(validation.flightItineraries[0])}
              {`\u00A0 (${date(validation.flightItineraries[0].itinerary.legs[0].travelDate).format(
                dateTimeFormats.date.medium
              )})`}
            </Typography>
          </Box>
        ),
        primaryButton: {
          label: 'Confirm',
          onClick: () => {
            onConfirm(offer, validation.flightItineraries[0], dispatch, () => setOfferAccepted(true))
          },
          loading: acceptPublicOfferLoading && loadingOfferId === offer.offerRequestId.toString()
        },
        secondaryButton: {
          label: 'Close'
        }
      })
    } else if (validation.multipleOptions) {
      // Show "Choose Flight" confirmation modal (if multiple options)
      let selectedFlightItineraryId: number | null = null

      const handleConfirm = () => {
        if (selectedFlightItineraryId !== null) {
          const flightItinerary = validation.flightItineraries?.find(
            (flightItinerary) => flightItinerary.id === selectedFlightItineraryId
          )

          if (flightItinerary) {
            onConfirm(offer, flightItinerary, dispatch, () => setOfferAccepted(true))
          } else {
            Snackbar.show({
              message: 'Please select a valid flight booking to select the offer.',
              severity: 'error'
            })
          }
        }
      }

      Modal.show({
        title: 'Choose Flight',
        subtitle: `You have multiple flight bookings available. Please choose one to select the offer.`,
        body: (
          <AcceptOffer
            response={validation}
            onConfirm={(flightItineraryId) => {
              selectedFlightItineraryId = flightItineraryId
            }}
          />
        ),
        primaryButton: {
          label: 'Confirm',
          onClick: handleConfirm
        },
        secondaryButton: {
          label: 'Close'
        }
      })
    }

    return validation
  }, [dispatch, offer, navigate, flightItineraries, onConfirm, loadingOfferId, acceptPublicOfferLoading])

  return (
    <Box sx={styles.offerContainer}>
      <Box sx={styles.topInfoContainer}>
        <Avatar firstName={offer.shopperDetails.firstName} lastName={offer.shopperDetails.lastName} />

        <Box sx={styles.userContainer}>
          <Typography variant="h6" sx={styles.userName}>
            {`${offer.shopperDetails.firstName} ${offer.shopperDetails.lastName.charAt(0)}`}
          </Typography>

          <Typography variant="body2">{`${offer.shopperDetails.deliveryTo.city}, ${offer.shopperDetails.deliveryTo.country}`}</Typography>
        </Box>

        <Typography variant="h6" sx={styles.getAmount}>
          Get ${offer.travelerBenefit.toFixed(2)}
        </Typography>
      </Box>

      {offer.offerRequestDetails.map((detail, index) => (
        <ItemDetails key={index} item={detail.itemDetails} />
      ))}

      <Box sx={styles.bottomInfoContainer}>
        <Box sx={styles.locationInfoContainer}>
          <LocationOn sx={styles.locationIcon} />

          <Typography variant="body2">
            <span style={styles.boldText}>
              {[...new Set(offer.offerRequestDetails.map((detail) => detail.itemDetails.country.name))].join(', ')}
            </span>{' '}
            <span style={styles.lightText}>to</span>{' '}
            <span
              style={styles.boldText}
            >{`${offer.shopperDetails.deliveryTo.city}, ${offer.shopperDetails.deliveryTo.country}`}</span>
          </Typography>
        </Box>

        <Box sx={styles.neededByContainer}>
          <Typography variant="body2">
            <span style={styles.lightText}>Needed by:</span>{' '}
            <span style={styles.boldText}>
              {offer.neededByDate === null ? 'Anytime' : date(offer.neededByDate).format(dateTimeFormats.date.medium)}
            </span>
          </Typography>
        </Box>
      </Box>

      {onSelect ? (
        <Button
          text={isSelected ? 'Deselect' : 'Select Offer'}
          fullWidth
          variant={isSelected ? 'contained' : 'outlined'}
          buttonType={isSelected ? 'tertiary' : 'primary'}
          onClick={handleSelect}
        />
      ) : (
        <Button
          text={offerAccepted ? 'Offer Selected' : 'Select Offer'}
          fullWidth
          disabled={offerAccepted}
          buttonType="primary"
          onClick={onAccepOfferValidation}
          loading={acceptPublicOfferLoading && loadingOfferId === offer.offerRequestId.toString()}
        />
      )}
    </Box>
  )
}

export default OfferItem
