import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { forgetPasswordApi } from './forgetPassword.api'
import forgetPasswordInitialState from './forgetPassword.initialState'
import { ForgetPasswordErrorResponse, ForgetPasswordRequest } from './forgetPassword.types'

export const forgetPassword = createAsyncThunk(
  urls.auth.forgetPassword,
  async (
    { forgetPasswordRequest, onSuccess }: { forgetPasswordRequest: ForgetPasswordRequest; onSuccess: () => void },
    { rejectWithValue }
  ) => {
    try {
      const response = await forgetPasswordApi(forgetPasswordRequest)

      onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const forgetPasswordSlice = createSlice({
  name: urls.auth.forgetPassword,
  initialState: forgetPasswordInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(forgetPassword.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(forgetPassword.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(forgetPassword.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as ForgetPasswordErrorResponse
        state.success = null
      })
  }
})

export const { actions: forgetPasswordActions, reducer: forgetPasswordReducer } = forgetPasswordSlice
export default forgetPasswordSlice.reducer
