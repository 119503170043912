import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { LogoutErrorResponse, LogoutSuccessResponse } from './logout.types'

export const logoutApi = async (): Promise<LogoutSuccessResponse> => {
  const response = await configureRequest({
    url: urls.auth.logout,
    method: 'POST'
  })

  if (response.status >= 200 && response.status < 300) {
    return response as LogoutSuccessResponse
  } else {
    throw response as LogoutErrorResponse
  }
}

export default logoutApi
