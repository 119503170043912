import { SxProps } from '@mui/material'

const styles = {
  container: (marginTop: number = 0) =>
    ({
      width: '100%',
      marginTop
    }) as SxProps,

  textField: (isEditable: boolean = true) =>
    ({
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: isEditable ? 'rgba(0, 0, 0, 0.23)' : 'transparent'
        },

        '&:hover fieldset': {
          borderColor: isEditable ? 'rgba(0, 0, 0, 0.23)' : 'transparent'
        },

        '&.Mui-focused fieldset': {
          borderColor: isEditable ? 'primary.main' : 'transparent'
        }
      },
      '& .MuiInputBase-input': {
        color: isEditable ? 'text.primary' : 'text.secondary'
      },

      '& .MuiInputLabel-root': {
        color: isEditable ? 'text.primary' : 'text.secondary'
      }
    }) as SxProps
}

export default styles
