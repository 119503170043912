import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  UpdatePersonalInfoErrorResponse,
  UpdatePersonalInfoRequest,
  UpdatePersonalInfoSuccessResponse
} from './updatePersonalInfo.types'

export const updatePersonalInfoApi = async (
  request: UpdatePersonalInfoRequest
): Promise<UpdatePersonalInfoSuccessResponse> => {
  const response = await configureRequest({
    url: urls.profile.updatePersonalInfo,
    method: 'PUT',
    data: request
  })

  if (response.status >= 200 && response.status < 300) {
    return response as UpdatePersonalInfoSuccessResponse
  } else {
    throw response as UpdatePersonalInfoErrorResponse
  }
}

export default updatePersonalInfoApi
