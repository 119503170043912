import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { acceptPublicOfferApi } from './acceptPublicOffer.api'
import acceptPublicOfferInitialState from './acceptPublicOffer.initialState'
import {
  AcceptPublicOfferErrorResponse,
  AcceptPublicOfferRequest,
  AcceptPublicOfferSuccessResponse
} from './acceptPublicOffer.types'

export const acceptPublicOffer = createAsyncThunk(
  urls.publicOffers.acceptPublicOffer,
  async (
    {
      acceptPublicOfferRequest,
      onSuccess
    }: {
      acceptPublicOfferRequest: AcceptPublicOfferRequest
      onSuccess: (data: AcceptPublicOfferSuccessResponse) => void
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await acceptPublicOfferApi(acceptPublicOfferRequest)

      onSuccess(response)

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const acceptPublicOfferSlice = createSlice({
  name: urls.publicOffers.acceptPublicOffer,
  initialState: acceptPublicOfferInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(acceptPublicOffer.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(acceptPublicOffer.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(acceptPublicOffer.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as AcceptPublicOfferErrorResponse
        state.success = null
      })
  }
})

export const { actions: acceptPublicOfferActions, reducer: acceptPublicOfferReducer } = acceptPublicOfferSlice
export default acceptPublicOfferSlice.reducer
