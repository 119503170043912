import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import Snackbar from '../../../../shared/components/Snackbar/Snackbar.functions'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { changePasswordApi } from './changePassword.api'
import changePasswordInitialState from './changePassword.initialState'
import { ChangePasswordErrorResponse, ChangePasswordRequest } from './changePassword.types'

export const changePassword = createAsyncThunk(
  urls.auth.changePassword,
  async (
    { changePasswordRequest, onSuccess }: { changePasswordRequest: ChangePasswordRequest; onSuccess: () => void },
    { rejectWithValue }
  ) => {
    try {
      const response = await changePasswordApi(changePasswordRequest)

      onSuccess()

      Snackbar.show({
        message: response?.userFriendlyMessage,
        severity: 'success'
      })

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const changePasswordSlice = createSlice({
  name: urls.auth.changePassword,
  initialState: changePasswordInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(changePassword.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(changePassword.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as ChangePasswordErrorResponse
        state.success = null
      })
  }
})

export const { actions: changePasswordActions, reducer: changePasswordReducer } = changePasswordSlice
export default changePasswordSlice.reducer
