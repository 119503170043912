import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { signUpApi } from './signUp.api'
import signUpInitialState from './signUp.initialState'
import { SignUpErrorResponse, SignUpRequest } from './signUp.types'

export const signUp = createAsyncThunk(
  urls.auth.signUp,
  async (
    { signUpRequest, onSuccess }: { signUpRequest: SignUpRequest; onSuccess: () => void },
    { rejectWithValue }
  ) => {
    try {
      const response = await signUpApi(signUpRequest)

      onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const signUpSlice = createSlice({
  name: urls.auth.signUp,
  initialState: signUpInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signUp.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(signUp.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(signUp.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as SignUpErrorResponse
        state.success = null
      })
  }
})

export const { actions: signUpActions, reducer: signUpReducer } = signUpSlice
export default signUpSlice.reducer
