import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`
  },

  itemImageContainer: {
    width: '50px',
    height: '50px',
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },

  itemImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    alignSelf: 'center'
  } as CSSProperties,

  itemDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    flex: 1,
    marginLeft: theme.spacing(1)
  },

  itemName: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical'
  } as SxProps,

  itemInfo: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem'
  },

  itemPrice: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem'
  },

  viewOnAmazonButton: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: 'nowrap',
    textTransform: 'none',
    fontSize: '1rem',
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1, 1)
  } as SxProps
}

export default styles
