import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  UpdateContactInfoErrorResponse,
  UpdateContactInfoRequest,
  UpdateContactInfoSuccessResponse
} from './updateContactInfo.types'

export const updateContactInfoApi = async (
  request: UpdateContactInfoRequest
): Promise<UpdateContactInfoSuccessResponse> => {
  const response = await configureRequest({
    url: urls.profile.updateContactInfo,
    method: 'PUT',
    data: request
  })

  if (response.status >= 200 && response.status < 300) {
    return response as UpdateContactInfoSuccessResponse
  } else {
    throw response as UpdateContactInfoErrorResponse
  }
}

export default updateContactInfoApi
