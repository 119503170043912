import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  AddLoyaltyProgramErrorResponse,
  AddLoyaltyProgramRequest,
  AddLoyaltyProgramSuccessResponse
} from './addLoyaltyProgram.types'

export const addLoyaltyProgramApi = async (
  request: AddLoyaltyProgramRequest
): Promise<AddLoyaltyProgramSuccessResponse> => {
  const response = await configureRequest({
    url: urls.profile.addLoyaltyProgram,
    method: 'POST',
    data: request
  })

  if (response.status >= 200 && response.status < 300) {
    return response as AddLoyaltyProgramSuccessResponse
  } else {
    throw response as AddLoyaltyProgramErrorResponse
  }
}

export default addLoyaltyProgramApi
