import React, { useMemo } from 'react'

import { Box } from '@mui/material'

import { EmptyState } from '../../shared/components/EmptyState/EmptyState.component'
import Loader from '../../shared/components/Loader/Loader.component'

import { verifyEmail } from '../../redux/states/auth/verifyEmail/verifyEmail.slice'
import { VerifyEmailRequest } from '../../redux/states/auth/verifyEmail/verifyEmail.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import reactHooks from '../../shared/hooks/react.hooks'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import styles from './SignUpVerification.styles'

const { useOnceEffect } = reactHooks

const SignUpVerification: React.FC = () => {
  const { loading, error } = useAppSelector((state) => state.verifyEmail)

  const emailToken = router.getUrlParam('emailToken')
  const dispatch = useAppDispatch()
  const navigate = router.navigate()

  const verifyEmailPayload = useMemo(() => {
    if (!emailToken) {
      return null
    } else {
      const verifyEmailRequest: VerifyEmailRequest = { verificationToken: emailToken }

      const onSuccess = () => {
        navigate(routes.login.path, { replace: true })
      }

      return { verifyEmailRequest, onSuccess }
    }
  }, [emailToken, navigate])

  useOnceEffect(() => {
    if (verifyEmailPayload) {
      dispatch(verifyEmail(verifyEmailPayload))
    }
  })

  if (loading) {
    return <Loader loading={loading} size="large" text="Verifying your email..." marginTop={10} />
  } else if (error) {
    return (
      <Box sx={styles.container}>
        <EmptyState
          title="Failed to verify email"
          subtitle={error.userFriendlyMessage}
          button={{
            text: 'Try again',
            onClick: () => {
              if (verifyEmailPayload) {
                dispatch(verifyEmail(verifyEmailPayload))
              }
            }
          }}
        />
      </Box>
    )
  } else {
    return null
  }
}

export default SignUpVerification
