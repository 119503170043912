import React, { KeyboardEvent, forwardRef, useCallback, useImperativeHandle, useState } from 'react'

import ClearIcon from '@mui/icons-material/Clear'
import { Box, IconButton, InputAdornment, TextField } from '@mui/material'

import Button from '../Button/Button.component'

import styles from './SearchBar.styles'
import { SearchBarProps, SearchBarRef } from './SearchBar.types'

const SearchBar = forwardRef<SearchBarRef, SearchBarProps>((props, ref) => {
  const { placeholder = 'Type any destination or item name', onSearch } = props
  const [searchQuery, setSearchQuery] = useState('')

  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleSearch = useCallback(() => {
    onSearch(searchQuery)
  }, [onSearch, searchQuery])

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleSearch()
      }
    },
    [handleSearch]
  )

  const handleClear = useCallback(() => {
    setSearchQuery('')
    onSearch('')
  }, [onSearch])

  useImperativeHandle(ref, () => ({
    clear: handleClear
  }))

  return (
    <Box sx={styles.container}>
      <Box sx={styles.inputContainer}>
        <TextField
          fullWidth
          value={searchQuery}
          onChange={onTextChange}
          onKeyDown={handleKeyPress}
          placeholder={placeholder}
          variant="standard"
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                {searchQuery && (
                  <IconButton sx={styles.clearButton} aria-label="clear search" onClick={handleClear} edge="end">
                    <ClearIcon sx={styles.clearIcon} />
                  </IconButton>
                )}
              </InputAdornment>
            )
          }}
          sx={styles.input}
        />
      </Box>

      <Button
        text={'Search'}
        buttonType="primary"
        color="primary"
        disabled={!searchQuery}
        onClick={handleSearch}
        style={styles.searchButton}
      />
    </Box>
  )
})

export default SearchBar
