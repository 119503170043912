import React from 'react'

import theme from '../../styles/themes/default.theme'

const styles = {
  formContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius / 4,
    boxShadow: theme.shadows[1]
  } as React.CSSProperties,

  form: {
    width: '100%'
  } as React.CSSProperties,

  formTitle: {
    textAlign: 'left',
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(4)
  } as React.CSSProperties,

  paymentElement: {
    flex: 1,
    padding: '4rem'
  } as React.CSSProperties,

  line: {
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.divider,
    margin: theme.spacing(2, 0)
  } as React.CSSProperties
}

export default styles
