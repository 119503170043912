import React from 'react'

import { Navigate } from 'react-router-dom'

import { isAuthenticated } from '../../networkRequests/apiClient/apiClient.functions'
import Snackbar from '../../shared/components/Snackbar/Snackbar.functions'
import { Route as HourrierRoute } from '../router.types'
import routes from '../routes.dictionary'

interface RedirectHandlerProps {
  route: HourrierRoute
}

const RedirectHandler: React.FC<RedirectHandlerProps> = ({ route }) => {
  const isUserAuthenticated = isAuthenticated()

  if (route.isAuthenticationRequired && !isUserAuthenticated) {
    Snackbar.show({
      message: 'Please login to continue.',
      severity: 'error'
    })

    return <Navigate to={`/${routes.login.path}`} />
  }

  return route.component ? React.createElement(route.component) : null
}

export default RedirectHandler
