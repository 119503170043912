import React, { useCallback } from 'react'

import { Box } from '@mui/material'

import PaginatedPage from '../../shared/components/PaginatedPage/PaginatedPage.component'
import OfferRequestsAccordion from './components/OfferRequestsAccordion/OfferRequestsAccordion.component'

import { getOfferRequests } from '../../redux/states/offers/getOfferRequests/getOfferRequests.slice'
import {
  GetOfferRequestsRequest,
  OfferRequest
} from '../../redux/states/offers/getOfferRequests/getOfferRequests.types'
import { RootState } from '../../redux/store/store.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import { EmptyStateProps } from '../../shared/components/EmptyState/EmptyState.types'
import { FilterTab, PaginatedResponse, SearchBarProps } from '../../shared/components/PaginatedPage/PaginatedPage.types'
import {
  FilterSectionConfig,
  Filters
} from '../../shared/components/UniversalFilterSideBar/UniversalFilterSideBar.types'
import date, { dateTimeFormats } from '../../shared/functions/Date/date.functions'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import styles from './Orders.styles'

const Orders: React.FC = () => {
  const { loading } = useAppSelector((state: RootState) => state.getOfferRequests)
  const dispatch = useAppDispatch()
  const navigate = router.navigate()

  const fetchOffers = useCallback(
    async (page: number, onSuccess?: () => void): Promise<PaginatedResponse<OfferRequest>> => {
      return new Promise((resolve) => {
        const getOfferRequestsPayload: GetOfferRequestsRequest = {
          page,
          size: 20,
          searchQuery: ''
        }

        dispatch(
          getOfferRequests({
            request: getOfferRequestsPayload,
            onSuccess: (data) => {
              resolve({
                currentPage: data.currentPage,
                totalPages: data.totalPages,
                results: data.results
              })

              onSuccess?.()
            }
          })
        )
      })
    },
    [dispatch]
  )

  const filterTabs: FilterTab<unknown>[] = [
    {
      id: 'all',
      label: 'All',
      filterDef: (items: unknown[]) => items as OfferRequest[]
    }
  ]

  const searchBar: SearchBarProps = {
    placeholder: 'Type any item name',
    onSearchFilter: (items, query) => {
      const lowerQuery = query.toLowerCase()

      return (items as OfferRequest[]).filter((offerRequest) =>
        offerRequest.itemRequests.some(
          (detail) =>
            detail.itemDetails.title.toLowerCase().includes(lowerQuery) ||
            detail.itemDetails.description.toLowerCase().includes(lowerQuery) ||
            detail.itemDetails.productName.toLowerCase().includes(lowerQuery)
        )
      )
    }
  }

  const emptyState: EmptyStateProps = {
    title: 'You have no orders',
    subtitle: 'Search Amazon and find items you want to buy then submit an offer request.',
    button: {
      text: 'Shop Now',
      onClick: () => {
        navigate(routes.shop.path)
      }
    }
  }

  const filterSections: (items: unknown[], filters: Filters) => FilterSectionConfig[] = useCallback(
    (items: unknown[]) => {
      const orders = items as OfferRequest[]

      // Extract unique travelers
      const travelers = Array.from(
        new Set(
          orders.flatMap((order) => {
            if (order.tripDetails) {
              return `${order.tripDetails.traveler.firstName} ${order.tripDetails.traveler.lastName}`
            }

            return []
          })
        )
      )

      // Extract unique arrival dates
      const arrivalDates = Array.from(
        new Set(
          orders.flatMap((order) => {
            if (order.tripDetails) {
              return date(order.tripDetails.arrivalTime).format(dateTimeFormats.date.medium) || 'Invalid Date'
            }

            return []
          })
        )
      )

      const sections: FilterSectionConfig[] = [
        {
          id: 'traveler',
          title: 'Traveler',
          type: 'multi-select',
          options: [{ primaryValue: 'All' }, ...travelers.map((traveler) => ({ primaryValue: traveler }))],
          defaultValue: ['All'],
          filterDef: (item, filters) => {
            if (filters.traveler && Array.isArray(filters.traveler) && !filters.traveler.includes('All')) {
              if ((item as OfferRequest).tripDetails?.traveler) {
                return filters.traveler.includes(
                  `${(item as OfferRequest).tripDetails.traveler.firstName} ${
                    (item as OfferRequest).tripDetails.traveler.lastName
                  }`
                )
              }

              return false
            }

            return true
          }
        },
        {
          id: 'arrivalDate',
          title: 'Arrival Date',
          type: 'multi-select',
          options: [{ primaryValue: 'All' }, ...arrivalDates.map((date) => ({ primaryValue: date }))],
          defaultValue: ['All'],
          filterDef: (item, filters) => {
            if (filters.arrivalDate && Array.isArray(filters.arrivalDate) && !filters.arrivalDate.includes('All')) {
              if ((item as OfferRequest).tripDetails) {
                return (filters.arrivalDate as string[]).includes(
                  date((item as OfferRequest).tripDetails.arrivalTime).format(dateTimeFormats.date.medium) ||
                    'Invalid Date'
                )
              }

              return false
            }

            return true
          }
        }
      ]

      return sections
    },
    []
  )

  const hideAllFilters = (items: unknown[]) => {
    const orders = items as OfferRequest[]

    return orders.every((order) => order.tripDetails === null)
  }

  return (
    <Box sx={styles.container}>
      <PaginatedPage
        title={{ text: 'Orders', id: 'Orders' }}
        subtitle="Manage your orders in one place."
        initialLoading={loading}
        emptyState={emptyState}
        fetchCall={fetchOffers}
        hideFilters={hideAllFilters}
        renderListData={OfferRequestsAccordion}
        horizontalFilterTabs={filterTabs}
        verticalFilters={filterSections}
        searchBar={searchBar}
      />
    </Box>
  )
}

export default Orders
