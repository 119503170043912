import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  GetFlightFaresErrorResponse,
  GetFlightFaresRequest,
  GetFlightFaresSuccessResponse
} from './getFlightFares.types'

export const getFlightFaresApi = async (request: GetFlightFaresRequest): Promise<GetFlightFaresSuccessResponse> => {
  const response = await configureRequest({
    url: urls.flights.getFlightFares,
    method: 'GET',
    params: {
      outboundOfferId: request.outboundOfferId,
      selected_partial_offer: request.selected_partial_offers
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetFlightFaresSuccessResponse
  } else {
    throw response as GetFlightFaresErrorResponse
  }
}

export default getFlightFaresApi
