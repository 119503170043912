import theme from '../../styles/themes/default.theme'

const styles = {
  container: (isCompact: boolean) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: isCompact ? theme.spacing(1, 1.75) : theme.spacing(2),
    borderRadius: 1,
    border: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: theme.palette.background.paper,
    minHeight: isCompact ? '50px' : '70px',
    width: '100%',

    '&:focus-within': {
      borderColor: theme.palette.primary.main
    },

    '&:hover:not(:focus-within)': {
      borderColor: theme.palette.grey[300]
    }
  }),

  input: (isCompact: boolean) => ({
    flex: 1,
    '& .MuiInputBase-input': {
      padding: isCompact ? theme.spacing(0.25, 0) : theme.spacing(0, 2)
    }
  }),

  chip: {
    '& .error-text': {
      fontSize: '14px'
    },
    fontSize: '14px'
  }
}

export default styles
