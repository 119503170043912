import { SxProps } from '@mui/material'

import theme from '../../../styles/themes/default.theme'

const styles = {
  filterSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(3)
  } as SxProps,

  select: {
    textAlign: 'left'
  } as SxProps,

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  } as SxProps,

  sectionTitle: {
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: theme.typography.body2.lineHeight,
    textAlign: 'left'
  } as SxProps,

  dropdownTextContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'space-between'
  } as SxProps,

  dropdownRightText: {
    textAlign: 'right',
    paddingLeft: theme.spacing(1)
  } as SxProps,

  sliderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    lineHeight: '16px'
  } as SxProps,

  sliderText: {
    fontWeight: theme.typography.fontWeightRegular
  } as SxProps,

  resetButton: {
    fontSize: theme.typography.body2,
    cursor: 'pointer'
  }
}

export default styles
