import { SxProps } from '@mui/material'

import theme from '../../styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'space-between',
    width: '100%'
  } as SxProps,

  sliceContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  } as SxProps,

  airlineInfo: {
    display: 'flex',
    alignItems: 'center'
  } as SxProps,

  logo: {
    width: '40px',
    height: '40px',
    marginRight: theme.spacing(2)
  },

  departureContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  } as SxProps,

  arrivalContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  } as SxProps,

  airportName: {
    color: theme.palette.text.primary
  } as SxProps,

  time: {
    color: '#5A5A5A',
    fontSize: '0.875rem',
    fontWeight: theme.typography.fontWeightMedium
  } as SxProps,

  separator: {
    flex: 1,
    height: '1px',
    backgroundColor: theme.palette.divider,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3.5)
  } as SxProps
}

export default styles
