import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  GetUpdatedFlightOfferErrorResponse,
  GetUpdatedFlightOfferRequest,
  GetUpdatedFlightOfferSuccessResponse
} from './getUpdatedFlightOffer.types'

export const getUpdatedFlightOfferApi = async (
  request: GetUpdatedFlightOfferRequest
): Promise<GetUpdatedFlightOfferSuccessResponse> => {
  const response = await configureRequest({
    url: urls.flights.getUpdatedFlightOffer,
    method: 'POST',
    params: {
      id: request.id,
      manualOfferId: request.manualOfferId!
    },
    headers: {
      'X-User-Role': UserRole.traveler
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetUpdatedFlightOfferSuccessResponse
  } else {
    throw response as GetUpdatedFlightOfferErrorResponse
  }
}

export default getUpdatedFlightOfferApi
