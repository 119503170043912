import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { GetCountriesAndCitiesErrorResponse, GetCountriesAndCitiesSuccessResponse } from './getCountriesAndCities.types'

export const getCountriesAndCitiesApi = async (): Promise<GetCountriesAndCitiesSuccessResponse> => {
  const response = await configureRequest({
    url: urls.flights.getCountriesAndCities,
    method: 'GET'
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetCountriesAndCitiesSuccessResponse
  } else {
    throw response as GetCountriesAndCitiesErrorResponse
  }
}

export default getCountriesAndCitiesApi
