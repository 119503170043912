import { AvatarProps as MUIAvatarProps } from '@mui/material'

export interface AvatarProps extends MUIAvatarProps {
  firstName?: string
  lastName?: string
  size?: AvatarSize
  noRightMargin?: boolean
}

export enum AvatarSize {
  small = 32,
  medium = 50,
  large = 100,
  xLarge = 170
}
