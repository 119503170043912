import React from 'react'

import { Box, Grid, Typography } from '@mui/material'

import { GetSeatDetailsData } from '../../../../../../../../../redux/states/flights/getSeatDetails/getSeatDetails.types'
import styles from './SeatMap.styles'

interface SeatMapProps {
  seatData: GetSeatDetailsData['cabins'][0]
  onSeatSelect: (seatId: string, seatDesignator: string, serviceId: string, seatPrice: string) => void
  selectedSeat: { id: string; designator: string } | null
  passengerId: string
}

const SeatMap: React.FC<SeatMapProps> = (props) => {
  const { seatData, onSeatSelect, selectedSeat, passengerId } = props

  const renderElement = (element: GetSeatDetailsData['cabins'][0]['rows'][0]['sections'][0]['elements'][0]) => {
    if (element.type === 'seat') {
      const isAvailable = element.available_services.some((service) => service.passenger_id === passengerId)
      const isSelected = selectedSeat?.id === element.id
      const isUnavailable = !isAvailable && element.designator !== null
      const seatPrice = element.available_services.find((service) => service.passenger_id === passengerId)?.total_amount

      const handleSeatSelect = () => {
        if (isAvailable && seatPrice) {
          onSeatSelect(element.id, element.designator || '', element.available_services[0].id, seatPrice)
        }
      }

      let seatStyle = isUnavailable
        ? styles.seatNotAvailable
        : isAvailable
          ? isSelected
            ? styles.seatSelected
            : styles.seatAvailable
          : styles.seatUnavailable
      seatStyle = {
        ...styles.seat,
        ...seatStyle
      }

      const textStyle = isUnavailable
        ? { ...styles.text, ...styles.textUnavailable }
        : isSelected
          ? { ...styles.text, ...styles.textSelected }
          : styles.text

      return (
        <Box key={element.id} sx={seatStyle} onClick={handleSeatSelect}>
          <Typography sx={textStyle}>{isUnavailable ? 'X' : element.designator}</Typography>

          {isAvailable && (
            <Typography sx={textStyle} variant="caption">
              ${seatPrice}
            </Typography>
          )}
        </Box>
      )
    } else if (element.type === 'exit_row') {
      return <Box key={element.id} sx={styles.exitRow} />
    } else if (element.type === 'lavatory' || element.type === 'galley') {
      return <Box key={element.id} sx={styles.facility} />
    }

    return <Box key={element.id} sx={styles.emptySeat} />
  }

  const renderSection = (section: GetSeatDetailsData['cabins'][0]['rows'][0]['sections'][0]) => {
    const elements = section.elements.map(renderElement)
    const totalSeats = 3 // Assuming each section should have 3 seats
    // const availableSeats = section.elements.filter((e) => e.type === 'seat').length

    // Create an array of 3 elements, filling missing seats with null
    const arrangedElements = new Array(totalSeats).fill(null).map((_, index) => elements[index] || null)

    return (
      <Grid item key={section.id}>
        <Box
          sx={{
            ...styles.section,
            justifyContent: 'center',
            width: `${totalSeats * 48}px` // 40px seat width + 8px margin
          }}
        >
          {arrangedElements.map((element, index) => element || <Box key={`empty-${index}`} sx={styles.emptySeat} />)}
        </Box>
      </Grid>
    )
  }

  const renderRow = (row: GetSeatDetailsData['cabins'][0]['rows'][0]) => (
    <Grid wrap={'nowrap'} container key={row.id} spacing={1} sx={styles.row}>
      {row.sections.map(renderSection)}
    </Grid>
  )

  return (
    <Box sx={styles.container}>
      <Box sx={styles.legend}>
        <Box sx={styles.legendItem}>
          <Box sx={{ ...styles.legendSquare, ...styles.legendSquareNotAvailable }} />
          <Typography>Not Available</Typography>
        </Box>

        <Box sx={styles.legendItem}>
          <Box sx={{ ...styles.legendSquare, ...styles.legendSquareAvailable }} />
          <Typography>Available</Typography>
        </Box>

        <Box sx={styles.legendItem}>
          <Box style={{ ...styles.legendSquare, ...styles.legendSquareSelected }} />
          <Typography>Selected</Typography>
        </Box>
      </Box>

      <Box sx={styles.seatMap}>{seatData.rows.map(renderRow)}</Box>
    </Box>
  )
}

export default SeatMap
