import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  ResetOfferRequestFlightItineraryErrorResponse,
  ResetOfferRequestFlightItineraryRequest,
  ResetOfferRequestFlightItinerarySuccessResponse
} from './resetOfferRequestFlightItinerary.types'

export const resetOfferRequestFlightItineraryApi = async (
  request: ResetOfferRequestFlightItineraryRequest
): Promise<ResetOfferRequestFlightItinerarySuccessResponse> => {
  const response = await configureRequest({
    url: urls.offers.resetOfferRequestFlightItinerary,
    method: 'POST',
    params: {
      offerRequestId: request.offerRequestId
    },
    headers: {
      'X-User-Role': UserRole.shopper
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as ResetOfferRequestFlightItinerarySuccessResponse
  } else {
    throw response as ResetOfferRequestFlightItineraryErrorResponse
  }
}

export default resetOfferRequestFlightItineraryApi
