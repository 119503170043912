import React, { useMemo } from 'react'

import { Box, IconButton, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  XIcon
} from 'react-share'

import Button from '../Button/Button.component'
import Modal from '../Modal/Modal.component'

import images from '../../../assets/images'
import { Country, CountryMarketplaceMap } from '../../../redux/states/firebaseRemoteConfig/firebaseRemoteConfig.types'
import { RootState } from '../../../redux/store/store.types'
import { getCountryByCode } from '../../functions/Places/places.functions'
import { useAppSelector } from '../../hooks/redux.hooks'
import styles from './ShareFlightItineraryLinkModal.styles'
import { ShareFlightItineraryLinkModalProps } from './ShareFlightItineraryLinkModal.types'

const ShareFlightItineraryLinkModal: React.FC<ShareFlightItineraryLinkModalProps> = (props) => {
  const { shareLink, travelerFirstName, destinationCountries, originCountries, tripType, open, onClose } = props

  const { values } = useAppSelector((state: RootState) => state.firebaseRemoteConfig)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const countryMarketplaceMap = useMemo(() => {
    return (values?.countryMarketplaceMap as CountryMarketplaceMap) ?? {}
  }, [values])

  const uniqueDestinationCountries = useMemo(() => {
    const uniqueCountries: Country[] = []
    const seenCodes = new Set()

    destinationCountries.forEach((country) => {
      const countryObject = getCountryByCode(country.iataCode)

      if (countryObject && !seenCodes.has(country.iataCode)) {
        seenCodes.add(country.iataCode)
        uniqueCountries.push(countryObject)
      }
    })

    return uniqueCountries
  }, [destinationCountries])

  const uniqueCountriesWithMarketplace = useMemo(() => {
    const uniqueCountries: Country[] = []
    const seenCodes = new Set()
    const countries = [...destinationCountries, ...originCountries]
    const countriesToCheck = tripType === 'oneWay' ? originCountries : countries

    countriesToCheck.forEach((country) => {
      if (countryMarketplaceMap[country.iataCode] && !seenCodes.has(country.iataCode)) {
        const countryObject = getCountryByCode(country.iataCode)

        if (countryObject) {
          seenCodes.add(country.iataCode)
          uniqueCountries.push(countryObject)
        }
      }
    })

    return uniqueCountries
  }, [destinationCountries, originCountries, countryMarketplaceMap, tripType])

  const travelCountriesNames =
    tripType === 'oneWay'
      ? `${destinationCountries[0].name} from ${originCountries[0].name}`
      : uniqueDestinationCountries.map((country) => country.name).join(' and ')

  const countriesWithMarketplaceNames = uniqueCountriesWithMarketplace.map((country) => country.name).join(' or ')

  const shareLinkMessage = `${travelerFirstName} is taking a trip to ${travelCountriesNames}. Send your Amazon ${countriesWithMarketplaceNames} item requests and get items delivered for less.`

  const modalBody = (
    <Box sx={styles.modalBody}>
      <Box sx={styles.shareLinkContainer}>
        <Box sx={styles.shareLinkTop}>
          <img src={images.shareLinkWithIconsIcon} alt="Share Link Modal Image" style={styles.shareLinkModalImage} />

          <Typography variant="h5" sx={styles.shareLinkModalPrimaryText}>
            Fly Smart & Save Big!
          </Typography>

          <Typography variant="h6" sx={styles.shareLinkModalSecondaryText}>
            Share Hurrier link to get paid to carry items.
          </Typography>

          {/* <Typography variant="body1" sx={styles.shareLinkModalTertiaryText}>
            Send link to <span style={styles.linkText}>3 friends or family</span> to get item requests and fly for less.
            Finish booking flight within <span style={styles.linkText}>3 days</span>.
          </Typography> */}
        </Box>

        <Box sx={styles.shareViaSocialMediaContainer}>
          <Typography variant="body1" sx={styles.shareViaSocialMedia}>
            Share via social media by clicking on the icon below.
          </Typography>

          <Box sx={styles.socialIconsContainer}>
            <FacebookShareButton url={shareLink} style={styles.socialIconButton}>
              <FacebookIcon size={48} round />
            </FacebookShareButton>

            {isMobile && (
              <IconButton
                sx={styles.socialIconButton}
                href={`instagram://share?text=${encodeURIComponent(shareLink)}`}
                target="_blank"
              >
                <img src={images.instagramGraphic} style={styles.socialMediaIcon} alt="Instagram" />
              </IconButton>
            )}

            <TwitterShareButton title={shareLinkMessage} url={shareLink} style={styles.socialIconButton}>
              <XIcon size={48} round />
            </TwitterShareButton>

            <IconButton
              sx={styles.socialIconButton}
              href={`https://wa.me/?text=${encodeURIComponent(shareLinkMessage + '\n\n' + shareLink)}`}
              target="_blank"
            >
              <WhatsappIcon size={48} round />
            </IconButton>

            <RedditShareButton title={shareLinkMessage} url={shareLink} style={styles.socialIconButton}>
              <RedditIcon size={48} round />
            </RedditShareButton>

            <TelegramShareButton title={shareLinkMessage} url={shareLink} style={styles.socialIconButton}>
              <TelegramIcon size={48} round />
            </TelegramShareButton>
          </Box>
        </Box>
      </Box>

      <Box sx={styles.copyLinkContainer}>
        <Typography sx={styles.copyLinkInstructionsText}>Copy the link to share on your preferred platform.</Typography>

        <Box sx={styles.copyLinkContentContainer}>
          <TextField sx={styles.copyLinkText} value={shareLink} InputProps={{ readOnly: true }} />
          <Button onClick={() => navigator.clipboard.writeText(shareLink)} buttonType="primary" text="Copy Link" />
        </Box>
      </Box>
    </Box>
  )

  return (
    <Modal
      open={open}
      onClose={onClose}
      body={modalBody}
      primaryButton={{
        label: 'Close',
        onClick: onClose
      }}
    />
  )
}

export default ShareFlightItineraryLinkModal
