import { SxProps } from '@mui/material'

import theme from '../../../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  } as SxProps,

  filterSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(3)
  } as SxProps,

  select: {
    textAlign: 'left'
  } as SxProps,

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  } as SxProps,

  sectionTitle: {
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '16px'
  } as SxProps,

  dropdownTextContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'space-between'
  } as SxProps,

  dropdownRightText: {
    textAlign: 'right',
    paddingLeft: theme.spacing(1)
  } as SxProps,

  divider: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0)
  } as SxProps,

  resetButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    outline: 'none'
  }
}

export default styles
