import { useEffect } from 'react'

import router from '../../../router/functions/router.functions'

const ScrollToTop = () => {
  const pathname = router.getCurrentPage().path

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default ScrollToTop
