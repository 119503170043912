import React, { useCallback, useState } from 'react'

import { Box } from '@mui/material'

import Button from '../../../../shared/components/Button/Button.component'
import { Chip } from '../../../../shared/components/Chip/Chip.component'
import UrlWidget from '../../../../shared/components/UrlWidget/UrlWidget.component'
import MiniTripDetails from '../../../SharedTrip/components/MiniTripDetails/MiniTripDetails.component'

import { getItemDetails } from '../../../../redux/states/items/getItemDetails/getItemDetails.slice'
import router from '../../../../router/functions/router.functions'
import routes from '../../../../router/routes.dictionary'
import Snackbar from '../../../../shared/components/Snackbar/Snackbar.functions'
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux.hooks'
import { isValidMarketplaceUrl } from './MarketplaceLinksInput.functions'
import styles from './MarketplaceLinksInput.styles'
import { MarketplaceLinksInputProps, MarketplaceUrlError } from './MarketplaceLinksInput.types'

const extractAmazonDomain = (url: string): string | null => {
  try {
    const urlObj = new URL(url)
    if (urlObj.hostname.includes('amazon.')) {
      return urlObj.hostname
    }

    return null
  } catch {
    return null
  }
}

export const MarketplaceLinksInput: React.FC<MarketplaceLinksInputProps> = (props) => {
  const { flightItinerary, isCompact = false } = props

  const [errors, setErrors] = useState<MarketplaceUrlError[]>([])
  const [urls, setUrls] = useState<string[]>([])
  const [inputValue, setInputValue] = useState('')

  const { loading } = useAppSelector((state) => state.getItemDetails)

  const dispatch = useAppDispatch()
  const navigate = router.navigate()

  const handleErrorUpdate = useCallback(
    (newError: MarketplaceUrlError) => {
      // Only add domain errors once
      if (newError.error.type === 'domain') {
        const domainErrorExists = errors.some((error) => error.error.type === 'domain')
        if (!domainErrorExists) {
          setErrors((prevErrors) => [...prevErrors, newError])
        }
      } else {
        // Handle other errors as before
        const errorExists = errors.some((error) => error.url === newError.url)
        if (!errorExists) {
          setErrors((prevErrors) => [...prevErrors, newError])
        }
      }
    },
    [errors]
  )

  const validateDomainConsistency = useCallback((urlList: string[]): boolean => {
    if (urlList.length === 0) return true

    const domains = urlList.map(extractAmazonDomain).filter((domain): domain is string => domain !== null)

    if (domains.length !== urlList.length) return false

    const firstDomain = domains[0]

    return domains.every((domain) => domain === firstDomain)
  }, [])

  const handleUrlValidation = useCallback(
    (url: string) => {
      const basicValidation = isValidMarketplaceUrl(url, handleErrorUpdate, flightItinerary)

      // Only check domain consistency if the URL passes basic validation
      if (basicValidation) {
        const allUrls = [...urls, url]
        const domainsMatch = validateDomainConsistency(allUrls)

        if (!domainsMatch) {
          handleErrorUpdate({
            url,
            error: {
              message: 'All Amazon links must be from the same country.',
              type: 'domain'
            }
          })

          return false
        }
      }

      return basicValidation
    },
    [handleErrorUpdate, flightItinerary, urls, validateDomainConsistency]
  )

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleInputKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      addUrl()
    }
  }

  const addUrl = () => {
    if (inputValue.trim()) {
      setUrls((prevUrls) => [...prevUrls, inputValue.trim()])
      setInputValue('')
    }
  }

  const removeUrl = (urlToRemove: string) => {
    setUrls((prevUrls) => prevUrls.filter((url) => url !== urlToRemove))

    // Clear all domain errors when removing a URL
    setErrors((prevErrors) => {
      const remainingUrls = urls.filter((url) => url !== urlToRemove)
      const domainValid = validateDomainConsistency(remainingUrls)

      if (domainValid) {
        // Remove domain errors
        return prevErrors.filter((error) => error.error.type !== 'domain')
      }

      return prevErrors
    })

    // Clear specific URL errors
    setErrors((prevErrors) => prevErrors.filter((error) => error.url !== urlToRemove))
  }

  const onSubmit = () => {
    setErrors([])

    const validUrls = urls.filter((url) => handleUrlValidation(url))

    dispatch(
      getItemDetails({
        request: {
          urls: validUrls
        },

        onSuccess: (results) => {
          const { processedItems, unprocessableItems } = results
          const onlyUnprocessableItems = processedItems.length === 0 && unprocessableItems.length >= 0

          if (onlyUnprocessableItems) {
            Snackbar.show({
              message: 'There was an issue processing your request. Please try again shortly.',
              severity: 'error'
            })
          } else {
            navigate(routes.cart.path, { state: { flightItineraryId: flightItinerary?.id } })
            setUrls([])
          }
        }
      })
    )
  }

  // TODO: Enhance the validation logic to account for the case where
  // a URL that doesn't match the location of the flight is provided in the compact widget
  const isGetOrdersEnabled =
    urls.length > 0 && urls.every((url) => handleUrlValidation(url)) && validateDomainConsistency(urls)

  const getMiniTripDetails = () => {
    if (flightItinerary) {
      return <MiniTripDetails flightItinerary={flightItinerary} />
    }
  }

  const getMarketplaceLinksInput = () => {
    return (
      <UrlWidget
        urls={urls}
        handleInputChange={handleInputChange}
        handleInputKeyPress={handleInputKeyPress}
        removeUrl={removeUrl}
        inputValue={inputValue}
        isValidMarketplaceUrl={handleUrlValidation}
        isCompact={isCompact}
      />
    )
  }

  const getErrorList = () => {
    if (errors.length > 0) {
      return (
        <Box sx={styles.errorListContainer}>
          {errors.map((error, index) => {
            return <Chip key={index} error={{ text: error.error.message, maxLength: 60 }} showCloseIcon={false} />
          })}
        </Box>
      )
    }
  }

  const getButton = () => {
    const buttonText = isCompact ? `Add Item${urls.length > 1 ? 's' : ''}` : 'Proceed'

    return (
      <Button
        text={buttonText}
        buttonType="primary"
        color="primary"
        disabled={!isGetOrdersEnabled}
        onClick={onSubmit}
        loading={loading}
        large={!isCompact}
        style={isCompact ? styles.compactButton : styles.largeButton}
      />
    )
  }

  const content = () => {
    if (isCompact) {
      return (
        <Box sx={styles.compactContainer}>
          <Box sx={styles.searchAreaContainer}>
            {getMarketplaceLinksInput()}
            {getButton()}
          </Box>

          {/* {getErrorList()} */}
        </Box>
      )
    } else {
      return (
        <Box sx={styles.largeWidgetContainer}>
          {getMiniTripDetails()}
          {getMarketplaceLinksInput()}

          <Box sx={styles.buttonContainer(errors.length > 0)}>
            {getErrorList()}
            {getButton()}
          </Box>
        </Box>
      )
    }
  }

  return content()
}
