import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import { getSeatDetailsApi } from './getSeatDetails.api'
import getSeatDetailsInitialState from './getSeatDetails.initialState'
import { GetSeatDetailsErrorResponse, GetSeatDetailsRequestPayload } from './getSeatDetails.types'

export const getSeatDetails = createAsyncThunk(
  urls.flights.seatDetails,
  async (payload: GetSeatDetailsRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await getSeatDetailsApi(request)

      onSuccess && onSuccess(response)

      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const getSeatDetailsSlice = createSlice({
  name: urls.flights.seatDetails,
  initialState: getSeatDetailsInitialState,
  reducers: {
    resetGetSeatDetails: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSeatDetails.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getSeatDetails.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getSeatDetails.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetSeatDetailsErrorResponse
        state.success = null
      })
  }
})

export const { actions: getSeatDetailsActions, reducer: getSeatDetailsReducer } = getSeatDetailsSlice
export const { resetGetSeatDetails } = getSeatDetailsActions
export default getSeatDetailsSlice.reducer
