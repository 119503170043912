import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  largeWidgetContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    width: '100%',
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(5),
    borderRadius: theme.shape.borderRadius / 4,
    boxShadow: theme.shadows[10]
  },

  compactContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    gap: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  },

  searchAreaContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    gap: theme.spacing(2)
  },

  buttonContainer: (hasErrors: boolean) => ({
    display: 'flex',
    justifyContent: hasErrors ? 'space-between' : 'flex-end',
    alignItems: 'flex-start',
    width: '100%'
  }),

  largeButton: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(1)
  },

  compactButton: {
    display: 'flex',
    alignSelf: 'flex-start',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    height: '50px'
  },

  errorListContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    flexWrap: 'wrap'
  },

  errorList: {},

  errorListItem: {
    color: theme.palette.error.main,

    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',

    alignItems: 'center',
    display: 'block',

    width: '100%'
  }
}

export default styles
