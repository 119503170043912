import { SxProps } from '@mui/material'

import theme from '../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  } as SxProps,

  listContainerGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(5),
    [theme.breakpoints.down(1315)]: {
      gridTemplateColumns: 'repeat(1, 1fr)'
    }
  } as SxProps,

  offerItemContainer: {
    flex: '1 1 calc(33.333% - 16px)'
  } as SxProps
}

export default styles
