import { FunctionComponent, SVGProps } from 'react'

import CartIcon from './images/cart.svg?react'
import CashIcon from './images/cash.svg?react'
import ChecklistIcon from './images/checklist.svg?react'
import CursorIcon from './images/cursor.svg?react'
import facebookGraphic from './images/facebook-graphic.svg'
import facebookLogo from './images/facebook-logo.svg'
import hourrierLogoWithText from './images/hurrier-logo-with-text-without-padding.svg'
import hourrierLogo from './images/hurrier-logo-without-padding.svg'
import instagramGraphic from './images/instagram-graphic.svg'
import instagramLogo from './images/instagram-logo.svg'
import OrderSubmittedCheckmarkIcon from './images/order-submitted-checkmark.svg?react'
import PlaneIcon from './images/plane.svg?react'
import profileAvatar from './images/profile-avatar-1.svg'
import shareLinkModalImage from './images/share-link-modal-image.svg'
import shareLinkWithIconsIcon from './images/share-link-with-icons.svg'
import shareLinkIcon from './images/share-link.svg'
import StepperCheckmarkIcon from './images/stepper-checkmark.svg?react'
import tiktokGraphic from './images/tiktok-graphic.svg'
import tiktokLogo from './images/tiktok-logo.svg'
import TruckIcon from './images/truck.svg?react'
import warning from './images/warning.svg'
import whatsappGraphic from './images/whatsapp-graphic.svg'
import xGraphic from './images/x-graphic.svg'
import xLogo from './images/x-logo.svg'

type SvgImageComponent = FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>

const images: {
  hourrierLogo: string
  hourrierLogoWithText: string
  profileAvatar: string
  facebookLogo: string
  tiktokLogo: string
  instagramLogo: string
  xLogo: string
  CashIcon: SvgImageComponent
  PlaneIcon: SvgImageComponent
  TruckIcon: SvgImageComponent
  CursorIcon: SvgImageComponent
  ChecklistIcon: SvgImageComponent
  CartIcon: SvgImageComponent
  OrderSubmittedCheckmarkIcon: SvgImageComponent
  StepperCheckmarkIcon: SvgImageComponent
  shareLinkIcon: string
  shareLinkWithIconsIcon: string
  shareLinkModalImage: string
  facebookGraphic: string
  instagramGraphic: string
  tiktokGraphic: string
  xGraphic: string
  whatsappGraphic: string
  warning: string
} = {
  hourrierLogo,
  hourrierLogoWithText,
  profileAvatar,
  facebookLogo,
  tiktokLogo,
  instagramLogo,
  xLogo,
  CashIcon,
  PlaneIcon,
  TruckIcon,
  CursorIcon,
  ChecklistIcon,
  CartIcon,
  StepperCheckmarkIcon,
  OrderSubmittedCheckmarkIcon,
  shareLinkIcon,
  shareLinkWithIconsIcon,
  shareLinkModalImage,
  facebookGraphic,
  instagramGraphic,
  tiktokGraphic,
  xGraphic,
  whatsappGraphic,
  warning
}

export default images
