import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { addItemTrackingNumberApi } from './addItemTrackingNumber.api'
import addItemTrackingNumberInitialState from './addItemTrackingNumber.initialState'
import { AddItemTrackingNumberErrorResponse, AddItemTrackingNumberRequestPayload } from './addItemTrackingNumber.types'

export const addItemTrackingNumber = createAsyncThunk(
  urls.offers.addItemTrackingNumber,
  async (payload: AddItemTrackingNumberRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await addItemTrackingNumberApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const addItemTrackingNumberSlice = createSlice({
  name: urls.offers.addItemTrackingNumber,
  initialState: addItemTrackingNumberInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addItemTrackingNumber.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(addItemTrackingNumber.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(addItemTrackingNumber.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as AddItemTrackingNumberErrorResponse
        state.success = null
      })
  }
})

export const { actions: addItemTrackingNumberActions, reducer: addItemTrackingNumberReducer } =
  addItemTrackingNumberSlice
export default addItemTrackingNumberSlice.reducer
