import { SxProps } from '@mui/material'

import theme from '../../../../styles/themes/default.theme'

const styles = {
  menuContainer: {
    flexGrow: 1,
    display: { xs: 'flex', md: 'none' }
  } as SxProps,

  menuContentContainer: {
    boxShadow: theme.shadows[1],
    display: { xs: 'block', md: 'none' }
  } as SxProps
}

export default styles
