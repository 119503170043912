import { SxProps } from '@mui/material'

import theme from '../../../../../../../../shared/styles/themes/default.theme'

const styles = {
  modalBody: {
    paddingTop: theme.spacing(2),
    maxHeight: '60vh',
    overflowY: 'auto'
  } as SxProps,

  headerRightSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    alignSelf: 'flex-end'
  } as SxProps,

  includedBaggageInfo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  } as SxProps,

  carryOnContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  } as SxProps,

  checkIcon: {
    color: theme.palette.success.main
  } as SxProps,

  price: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary
  } as SxProps,

  totalCost: {
    color: theme.palette.blue[800],
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '24px',
    width: '100%'
  } as SxProps,

  bagsSelected: {
    marginTop: theme.spacing(0),
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '16px',
    color: theme.palette.grey[500]
  } as SxProps,

  noBaggageAvailable: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    marginTop: theme.spacing(1)
  } as SxProps,

  tabs: {
    marginBottom: theme.spacing(2),
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.blue[800]
    },
    '& .MuiTab-root': {
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.text.secondary,
      '&.Mui-selected': {
        color: theme.palette.blue[800]
      }
    }
  } as SxProps,

  segmentContainer: {
    marginBottom: theme.spacing(2)
  } as SxProps,

  segmentTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1)
  } as SxProps,

  passengerContainer: {
    marginBottom: theme.spacing(2)
  } as SxProps,

  passengerTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(1)
  } as SxProps
}

export default styles
