import React, { useMemo } from 'react'

import { Box, Checkbox, FormControl, ListItemText, MenuItem, Select, Slider, Typography } from '@mui/material'

import Link from '../../Link/Link.component'

import styles from './FilterSection.styles'
import { FilterSectionProps } from './FilterSection.types'

const FilterSection: React.FC<FilterSectionProps> = ({ title, type, options, unit, value, onChange, onReset }) => {
  const content = useMemo(() => {
    switch (type) {
      case 'dropdown':
        return (
          <Select sx={styles.select} size="small" value={value || ''} onChange={(e) => onChange(e.target.value)}>
            {options.map((option) => (
              <MenuItem key={option.primaryValue} value={option.primaryValue}>
                {option.primaryValue}
                {option.secondaryValue && ` (${option.secondaryValue})`}
              </MenuItem>
            ))}
          </Select>
        )

      case 'multi-select':
        if (options.length === 1 && options[0].primaryValue === 'All') {
          return null
        }

        return (
          <Select
            sx={styles.select}
            size="small"
            multiple
            value={value || []}
            onChange={(e) => {
              const newValue = e.target.value as string[]
              if (newValue.length > 0 && newValue[newValue.length - 1] === 'All') {
                onChange(['All'])
              } else if (newValue.includes('All') && newValue.length > 1) {
                onChange(newValue.filter((item) => item !== 'All'))
              } else if (newValue.length === 0) {
                onChange(['All'])
              } else {
                onChange(newValue)
              }
            }}
            renderValue={(selected) => (selected as string[]).join(', ')}
          >
            {options.map((option) => (
              <MenuItem key={option.primaryValue} value={option.primaryValue}>
                <Checkbox checked={((value as string[]) || []).indexOf(option.primaryValue as string) > -1} />

                <Box sx={styles.dropdownTextContainer}>
                  <ListItemText primary={option.primaryValue} />
                  {option.secondaryValue && (
                    <ListItemText secondary={option.secondaryValue} sx={styles.dropdownRightText} />
                  )}
                </Box>
              </MenuItem>
            ))}
          </Select>
        )

      case 'slider':
        if (options[0].primaryValue === Infinity || options?.[0]?.primaryValue === options?.[1]?.primaryValue) {
          return null
        }

        if (options[0].primaryValue >= options?.[1]?.primaryValue) {
          return null
        }

        return (
          <Box sx={styles.sliderContainer}>
            <Typography sx={styles.sliderText} variant="body2">
              {`${unit?.symbol || ''}${(value as number[])?.[0]?.toFixed(2) || ''} – ${unit?.symbol || ''}${(value as number[])?.[1]?.toFixed(2) || ''} ${
                unit?.label || ''
              }`}
            </Typography>

            <Slider
              value={
                (value as number[]) || [
                  options[0].primaryValue as number,
                  options[options.length - 1].primaryValue as number
                ]
              }
              step={0.2}
              onChange={(_, newValue) => onChange(newValue as number[])}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => `${unit?.symbol || ''}${value.toFixed(2)} ${unit?.label || ''}`}
              min={options[0].primaryValue as number}
              max={options[options.length - 1].primaryValue as number}
            />
          </Box>
        )
      default:
        return null
    }
  }, [type, options, value, onChange, unit])

  if (!content) {
    return null
  }

  return (
    <Box sx={styles.filterSection}>
      <Box sx={styles.header}>
        <Typography variant="h6" sx={styles.sectionTitle}>
          {title}
        </Typography>

        <Link onClick={onReset} showColor sx={styles.resetButton} underline="none">
          Reset
        </Link>
      </Box>

      <FormControl fullWidth>{content}</FormControl>
    </Box>
  )
}

export default FilterSection
