import theme from '../../styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius / 4,
    boxShadow: theme.shadows[3]
  },

  shareIconsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    maxWidth: '170px',
    marginBottom: theme.spacing(2)
  },

  shareIcon: {
    width: '100%',
    height: 'auto'
  },

  icon: {
    width: '40px',
    height: '40px'
  },

  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },

  description: {
    textAlign: 'center',
    color: theme.palette.grey[500]
  },

  shareButton: {
    marginTop: theme.spacing(3)
  }
}

export default styles
