import { SxProps } from '@mui/material'

import theme from '../../../../../../../../shared/styles/themes/default.theme'

const styles = {
  modalBody: {
    // padding: theme.spacing(2),
    maxHeight: '60vh',
    overflowY: 'auto'
  } as SxProps,

  headerRightSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    alignSelf: 'flex-end'
  } as SxProps,

  totalCost: {
    color: theme.palette.blue[800],
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '24px'
  } as SxProps,

  seatsSelected: {
    marginTop: theme.spacing(0),
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '16px',
    color: theme.palette.grey[500]
  } as SxProps,

  tabs: {
    marginBottom: theme.spacing(2),
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.blue[800]
    },
    '& .MuiTab-root': {
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.text.secondary,
      '&.Mui-selected': {
        color: theme.palette.blue[800]
      }
    }
  } as SxProps,

  legendContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    gap: theme.spacing(3)
  } as SxProps,

  legendItem: {
    display: 'flex',
    alignItems: 'center'
  } as SxProps,

  legendBox: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1),
    borderRadius: theme.shape.borderRadius / 12
  } as SxProps,

  legendSquareNotAvailable: {
    border: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: theme.palette.grey[100]
  } as SxProps,

  legendSquareAvailable: {
    backgroundColor: theme.palette.blue[50],
    border: `1px solid ${theme.palette.blue[800]}`
  } as SxProps,

  legendSquareSelected: {
    backgroundColor: theme.palette.blue[800],
    border: `1px solid ${theme.palette.blue[800]}`
  } as SxProps
}

export default styles
