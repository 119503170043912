import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  GetFlightItineraryErrorResponse,
  GetFlightItineraryRequest,
  GetFlightItinerarySuccessResponse
} from './getFlightItinerary.types'

export const getFlightItineraryApi = async (
  request: GetFlightItineraryRequest
): Promise<GetFlightItinerarySuccessResponse> => {
  const { id } = request

  const response = await configureRequest({
    url: urls.flights.getFlightItinerary,
    method: 'GET',
    params: { id }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetFlightItinerarySuccessResponse
  } else {
    throw response as GetFlightItineraryErrorResponse
  }
}

export default getFlightItineraryApi
