import theme from '../../styles/themes/default.theme'

const styles = {
  bannerContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius / 4,
    boxShadow: theme.shadows[10],
    marginBottom: theme.spacing(5)
  },

  textContainer: {
    flex: 1,
    padding: theme.spacing(5)
  },

  title: {
    fontWeight: 'bold',
    color: theme.palette.grey[800],
    marginBottom: theme.spacing(5),
    textAlign: 'left'
  },

  subtitle: {
    color: theme.palette.grey[600],
    marginBottom: theme.spacing(5),
    textAlign: 'left'
  },

  carouselContainer: {
    flex: 1,
    height: '470px'
  },

  imageContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    borderRadius: theme.shape.borderRadius / 4,
    overflow: 'hidden'
  },

  image: {
    width: '100%',
    height: '100%'
  },

  link: {
    textAlign: 'left'
  }
}

export default styles
