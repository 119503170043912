import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { cancelItemRequestApi } from './cancelItemRequest.api'
import cancelItemRequestInitialState from './cancelItemRequest.initialState'
import { CancelItemRequestErrorResponse, CancelItemRequestRequestPayload } from './cancelItemRequest.types'

export const cancelItemRequest = createAsyncThunk(
  urls.offers.cancelItemRequest,
  async (payload: CancelItemRequestRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await cancelItemRequestApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const cancelItemRequestSlice = createSlice({
  name: urls.offers.cancelItemRequest,
  initialState: cancelItemRequestInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(cancelItemRequest.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(cancelItemRequest.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(cancelItemRequest.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as CancelItemRequestErrorResponse
        state.success = null
      })
  }
})

export const { actions: cancelItemRequestActions, reducer: cancelItemRequestReducer } = cancelItemRequestSlice
export default cancelItemRequestSlice.reducer
