import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../../../../../shared/styles/themes/default.theme'

const styles = {
  seatCard: {
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
    width: '100%',
    borderRadius: theme.shape.borderRadius / 4,
    padding: theme.spacing(1, 2, 2, 2),
    boxShadow: theme.shadows[3]
  } as SxProps,

  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  } as SxProps,

  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '20px'
  } as SxProps,

  divider: {
    margin: theme.spacing(2, 0)
  } as SxProps,

  seatItem: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2)
  } as SxProps,

  seatDetails: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  } as SxProps,

  seatInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  } as SxProps,

  checkIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1)
  } as CSSProperties,

  cancelIcon: {
    color: theme.palette.warning.main,
    marginRight: theme.spacing(1)
  } as CSSProperties,

  seatPrice: {
    fontWeight: theme.typography.fontWeightBold
  } as SxProps,

  showMoreButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2)
  } as CSSProperties,

  showMoreButton: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main
  } as CSSProperties,

  segmentContainer: (isLast: boolean) =>
    ({
      marginBottom: isLast ? 0 : theme.spacing(5)
    }) as SxProps,

  segmentTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1)
  } as SxProps,

  passengerTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(1)
  } as SxProps,

  seatNotice: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  } as SxProps,

  addedText: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary
  } as SxProps
}

export default styles
