import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { createOfferRequestPaymentIntentApi } from './createOfferRequestPaymentIntent.api'
import createOfferRequestPaymentIntentInitialState from './createOfferRequestPaymentIntent.initialState'
import {
  CreateOfferRequestPaymentIntentErrorResponse,
  CreateOfferRequestPaymentIntentRequestPayload
} from './createOfferRequestPaymentIntent.types'

export const createOfferRequestPaymentIntent = createAsyncThunk(
  urls.payments.createOfferRequestPaymentIntent,
  async (payload: CreateOfferRequestPaymentIntentRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await createOfferRequestPaymentIntentApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const createOfferRequestPaymentIntentSlice = createSlice({
  name: urls.payments.createOfferRequestPaymentIntent,
  initialState: createOfferRequestPaymentIntentInitialState,
  reducers: {
    resetCreateOfferRequestPaymentIntent: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOfferRequestPaymentIntent.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(createOfferRequestPaymentIntent.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(createOfferRequestPaymentIntent.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as CreateOfferRequestPaymentIntentErrorResponse
        state.success = null
      })
  }
})

export const { actions: createOfferRequestPaymentIntentActions, reducer: createOfferRequestPaymentIntentReducer } =
  createOfferRequestPaymentIntentSlice

export const { resetCreateOfferRequestPaymentIntent } = createOfferRequestPaymentIntentActions

export default createOfferRequestPaymentIntentSlice.reducer
