import { SxProps } from '@mui/system'

import theme from '../../shared/styles/themes/default.theme'

const styles = {
  app: {
    textAlign: 'center'
  },

  header: ({ isGreyPage }: { isGreyPage: boolean }): SxProps => {
    return {
      backgroundColor: isGreyPage ? theme.palette.grey[50] : theme.palette.background.default,
      minHeight: '100vh',
      fontSize: 'calc(10px + 2vmin)',
      color: theme.palette.background.default,
      borderBottom: isGreyPage ? `1px solid ${theme.palette.grey[200]}` : 'none'
    }
  }
}

export default styles
