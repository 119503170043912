import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { createAccountSessionApi } from './createAccountSession.api'
import createAccountSessionInitialState from './createAccountSession.initialState'
import { CreateAccountSessionErrorResponse, CreateAccountSessionRequestPayload } from './createAccountSession.types'

export const createAccountSession = createAsyncThunk(
  urls.payments.createAccountSession,
  async (payload: CreateAccountSessionRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess, onError } = payload

    try {
      const response = await createAccountSessionApi(request)

      onSuccess && onSuccess(response)

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse, onError)

      return rejectWithValue(error)
    }
  }
)

const createAccountSessionSlice = createSlice({
  name: urls.payments.createAccountSession,
  initialState: createAccountSessionInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createAccountSession.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(createAccountSession.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(createAccountSession.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as CreateAccountSessionErrorResponse
        state.success = null
      })
  }
})

export const { actions: createAccountSessionActions, reducer: createAccountSessionReducer } = createAccountSessionSlice
export default createAccountSessionSlice.reducer
