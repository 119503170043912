import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  CreateFlightBookingPaymentIntentErrorResponse,
  CreateFlightBookingPaymentIntentRequest,
  CreateFlightBookingPaymentIntentSuccessResponse
} from './createFlightBookingPaymentIntent.types'

export const createFlightBookingPaymentIntentApi = async (
  request: CreateFlightBookingPaymentIntentRequest
): Promise<CreateFlightBookingPaymentIntentSuccessResponse> => {
  const response = await configureRequest({
    url: urls.payments.createFlightBookingPaymentIntent,
    method: 'POST',
    data: request,
    headers: {
      'X-User-Role': UserRole.traveler
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as CreateFlightBookingPaymentIntentSuccessResponse
  } else {
    throw response as CreateFlightBookingPaymentIntentErrorResponse
  }
}

export default createFlightBookingPaymentIntentApi
