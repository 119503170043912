import React from 'react'

import { Box } from '@mui/material'
import { StepIconProps } from '@mui/material/StepIcon'

import styles from './StepLabel.styles'

const StepLabel: React.FC<StepIconProps> = (props) => {
  const { active, completed } = props

  return <Box sx={styles.stepLabel({ active, completed })} />
}

export default StepLabel
