/* eslint-disable no-console */
import firebase from '../Firebase/firebase.functions'
import sentry from '../Sentry/sentry.functions'
import { logEventNameVerificationRegex } from './logger.regex'

/**
 * Logs an event in Firebase.
 * @param {string} eventName Name of event to log | Max length = 40 characters,
 * @param {string} parameters Array of objects to log to Firebase | Max parameter value length = 100 characters
 * @example logEvent('Login_Button_Pressed')
 * @example logEvent('User_Created', {date: '12/12/2023'})
 */
export const logEvent = (eventName: string, parameters?: Record<string, string | number | boolean>) => {
  try {
    const sanitizedEventName = sanitizeEventName(eventName)

    firebase.analytics.logEvent(sanitizedEventName, parameters)
    sentry.logEvent(eventName, parameters)
  } catch (error) {
    sentry.logError(error, eventName, 'logEvent', parameters)
  }
}

/**
 * Logs an error in Sentry.
 * @param {string} errorMessage - The error message to log.
 * @param {Error} error - The Error object to log.
 * @example logError('Failed to fetch data', new Error('Network error'))
 */
export const logError = (
  error: Error | unknown,
  errorMessage: string,
  functionName: string,
  parameters?: Record<string, string | number | boolean>
) => {
  sentry.logError(error || new Error(errorMessage), errorMessage, functionName, parameters)
  console.error(error, errorMessage, functionName, parameters)
}

/**
 * Sanitizes the event name to ensure it meets the required criteria.
 *
 * This function checks if the event name contains invalid characters and replaces them with underscores.
 * It also truncates the event name if it exceeds the maximum length of 40 characters.
 *
 * @param {string} eventName - The original event name to sanitize.
 * @returns {string} - The sanitized event name.
 */
const sanitizeEventName = (eventName: string): string => {
  let sanitizedEventName = eventName
  const invalidEventName = logEventNameVerificationRegex.regex.test(sanitizedEventName)

  if (invalidEventName) {
    sanitizedEventName = eventName.replace(logEventNameVerificationRegex.regex, '_')
  }

  if (sanitizedEventName.length > 40) {
    sanitizedEventName = sanitizedEventName.slice(0, 40)
  }

  return sanitizedEventName
}

const logger = { logEvent, logError }

export default logger
