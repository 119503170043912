import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { requestOfferRequestRefundApi } from './requestOfferRequestRefund.api'
import requestOfferRequestRefundInitialState from './requestOfferRequestRefund.initialState'
import {
  RequestOfferRequestRefundErrorResponse,
  RequestOfferRequestRefundRequestPayload
} from './requestOfferRequestRefund.types'

export const requestOfferRequestRefund = createAsyncThunk(
  urls.payments.requestOfferRequestRefund,
  async (payload: RequestOfferRequestRefundRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess, onError } = payload

    try {
      const response = await requestOfferRequestRefundApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      if (onError) {
        onError()
      }

      return rejectWithValue(error)
    }
  }
)

const requestOfferRequestRefundSlice = createSlice({
  name: urls.payments.requestOfferRequestRefund,
  initialState: requestOfferRequestRefundInitialState,
  reducers: {
    resetRequestOfferRequestRefund: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestOfferRequestRefund.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(requestOfferRequestRefund.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(requestOfferRequestRefund.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as RequestOfferRequestRefundErrorResponse
        state.success = null
      })
  }
})

export const { actions: requestOfferRequestRefundActions, reducer: requestOfferRequestRefundReducer } =
  requestOfferRequestRefundSlice
export const { resetRequestOfferRequestRefund } = requestOfferRequestRefundActions
export default requestOfferRequestRefundSlice.reducer
