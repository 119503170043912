import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  CancelFlightItineraryErrorResponse,
  CancelFlightItineraryRequest,
  CancelFlightItinerarySuccessResponse
} from './cancelFlightItinerary.types'

export const cancelFlightItineraryApi = async (
  request: CancelFlightItineraryRequest
): Promise<CancelFlightItinerarySuccessResponse> => {
  const response = await configureRequest({
    url: urls.flights.cancelFlightItinerary,
    method: 'POST',
    data: request,
    params: {},
    headers: {
      'X-User-Role': UserRole.traveler
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as CancelFlightItinerarySuccessResponse
  } else {
    throw response as CancelFlightItineraryErrorResponse
  }
}

export default cancelFlightItineraryApi
