import { SxProps, circularProgressClasses } from '@mui/material'

import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    overflow: 'auto',
    // height: '100%',
    // overflow: 'visible',
    paddingRight: theme.spacing(3)
  } as SxProps,

  breadcrumbs: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    color: theme.palette.grey[400],
    fontWeight: 'bold',
    fontSize: '12px'
  } as SxProps,

  breadcrumbsSeparator: {
    color: theme.palette.grey[400]
  } as SxProps,

  activeBreadcrumb: {
    cursor: 'pointer',
    color: theme.palette.grey[500]
  } as SxProps,

  inactiveBreadcrumb: {} as SxProps,

  title: {
    fontWeight: 'bold',
    fontSize: '24px',
    textAlign: 'center',
    marginBottom: theme.spacing(0.5)
  } as SxProps,

  subtitle: {
    marginBottom: theme.spacing(4),
    color: theme.palette.grey[500],
    fontSize: '16px'
  } as SxProps,

  loadingProgress: {
    position: 'absolute',
    left: 0,
    'svg circle': { stroke: 'url(#my_gradient)' },
    [`& .${circularProgressClasses.circle}`]: {
      strokeLinecap: 'round'
    }
  } as SxProps,

  loadingFlightContainer: {
    position: 'absolute',
    right: 0,
    top: 70,
    width: '100%',
    height: '100%'
  } as SxProps,

  loadingFlightIcon: {
    color: theme.palette.primary.main,
    fontSize: '64px'
  } as SxProps,

  loadingFlightText: {
    fontWeight: 'bold',
    fontSize: '24px',
    color: theme.palette.primary.main
  } as SxProps,

  loadingDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(3)
  } as SxProps,

  loadingDetailsContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(3)
  } as SxProps,

  loadingTitle: {
    fontWeight: 'bold',
    fontSize: '32px',
    marginBottom: theme.spacing(0.5)
  } as SxProps,

  loadingSubtitle: {
    fontSize: '24px'
  } as SxProps,

  tripSelectorContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  } as SxProps,

  offersContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: '100%',
    overflow: 'visible'
  } as SxProps,

  showMoreButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius / 6,
    padding: theme.spacing(1.5, 3),
    textTransform: 'none',
    fontWeight: 'bold',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  } as SxProps
}

export default styles
