import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { SearchFlightsErrorResponse, SearchFlightsSuccessResponse } from './searchFlights.types'
import { SearchFlightsRequest } from './types/SearchFlightRequest.types'

export const searchFlightsApi = async (request: SearchFlightsRequest): Promise<SearchFlightsSuccessResponse> => {
  const response = await configureRequest({
    url: urls.flights.search,
    method: 'POST',
    data: request
  })

  if (response.status >= 200 && response.status < 300) {
    return response as SearchFlightsSuccessResponse
  } else {
    throw response as SearchFlightsErrorResponse
  }
}

export default searchFlightsApi
