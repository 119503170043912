import { SxProps } from '@mui/material'

import theme from '../../styles/themes/default.theme'

const styles = {
  loaderContainer: (marginTop: number) =>
    ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      marginTop: marginTop
    }) as SxProps,

  loaderText: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2)
  } as SxProps
}

export default styles
