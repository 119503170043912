import { FirebaseRemoteConfigState } from './firebaseRemoteConfig.types'

const initialState: FirebaseRemoteConfigState = {
  values: {
    travelBanner: [
      {
        src: 'https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?auto=format&fit=crop&w=400&h=250&q=60',
        alt: 'Waterfall, Iceland'
      }
    ],
    testimonials: []
  }
}

export default initialState
