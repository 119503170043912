import { SxProps } from '@mui/material'

import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    borderRadius: theme.shape.borderRadius / 4,
    marginBottom: theme.spacing(2),
    width: '100%'
  } as SxProps,

  warningBox: {
    backgroundColor: '#fff3e0',
    borderRadius: '4px',
    padding: '12px',
    marginBottom: theme.spacing(0),
    textAlign: 'left'
  } as SxProps,

  warningText: {
    color: '#e65100',
    fontSize: '14px'
  } as SxProps,

  passengerCard: (passengersLength: number) =>
    ({
      backgroundColor: theme.palette.background.default,
      width: '100%',
      borderRadius: theme.shape.borderRadius / 4,
      padding: theme.spacing(1, 2, 2, 2),
      boxShadow: theme.shadows[3],
      marginBottom: passengersLength > 1 ? theme.spacing(4) : 0
    }) as SxProps,

  passengerTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2)
  } as SxProps,

  formContainer: {
    '& .MuiFormControl-root': {
      marginBottom: '16px'
    },

    '& .MuiInputBase-root': {
      borderRadius: '4px'
    },

    '& .MuiInputLabel-root': {
      fontSize: '14px'
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#e0e0e0'
      },

      '&:hover fieldset': {
        borderColor: '#bdbdbd'
      },

      '&.Mui-focused fieldset': {
        borderColor: '#1976d2'
      }
    }
  } as SxProps
}

export default styles
