import { SxProps } from '@mui/material'

import theme from '../../../../shared/styles/themes/default.theme'

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius / 4,
    backgroundColor: theme.palette.background.default
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    flexWrap: 'wrap'
  },

  amountToPayContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap'
  } as SxProps,

  divider: {
    margin: theme.spacing(2, 0)
  },

  title: {
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'left'
  } as SxProps,

  subtotalTitleText: {
    textAlign: 'left',
    paddingRight: theme.spacing(2)
  } as SxProps,

  fieldText: {
    color: theme.palette.grey[500],
    fontWeight: 500
  },

  amountToPayText: {
    textAlign: 'left',
    fontWeight: theme.typography.fontWeightBold,
    paddingRight: theme.spacing(2)
  } as SxProps
}
