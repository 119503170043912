import logger from '../../functions/Logger/logger.functions'
import { ModalContextProps, ShowModalProps } from './context/Modal.context.types'

let modalContext: ModalContextProps | null = null

export const setModalContext = (context: ModalContextProps) => {
  modalContext = context
}

const showModal = (props: ShowModalProps) => {
  if (modalContext) {
    modalContext.show({
      ...props
    })
  } else {
    logger.logError('Modal context is not set', 'showModal', 'Modal')
  }
}

const isOpen = () => {
  return modalContext?.isOpen
}

const Modal = {
  show: showModal,
  isOpen: isOpen
}

export default Modal
