import { createReducer } from '@reduxjs/toolkit'

import {
  FlightSearchInitialValues,
  InitialFlight
} from '../../../../pages/Travel/components/FlightSearchWidget/FlightSearchWidget.types'
import { setFlightSearchValues } from './flightSearchWidget.actions'

const initialState: FlightSearchInitialValues = null as unknown as FlightSearchInitialValues

// TODO: Refactor this reducer
const flightSearchWidgetReducer = createReducer(initialState, (builder) => {
  builder.addCase(setFlightSearchValues, (_state, action) => {
    const newState = { ...action.payload }

    newState.flights = newState?.flights.map(
      (flight) =>
        ({
          ...flight,
          departure: flight.departure?.toString(),
          return: flight.return?.toString()
        }) as InitialFlight
    )

    return newState
  })
})

export default flightSearchWidgetReducer
