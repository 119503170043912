import React from 'react'

import { Box, Typography } from '@mui/material'

import Button from '../../../../shared/components/Button/Button.component'
import Link from '../../../../shared/components/Link/Link.component'

import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'
import { joinStringWithBullet } from '../../../../shared/functions/String/string.functions'
import styles from './ItemDetails.styles'
import { ItemDetailsProps } from './ItemDetails.types'

const ItemDetails: React.FC<ItemDetailsProps> = (props) => {
  const { item } = props
  const hasFullWeightDetails = item.weight && item.weightUnit

  return (
    <Box sx={styles.itemContainer}>
      <Box sx={styles.itemImageContainer}>
        <img src={item.images[0]} alt={item.title} style={styles.itemImage} />
      </Box>

      <Box sx={styles.itemDetails}>
        <Typography variant="h6" sx={styles.itemName}>
          {item.title}
        </Typography>

        <Typography variant="body2" sx={styles.itemInfo}>
          {joinStringWithBullet([
            `Qty: ${item.quantity}`,
            hasFullWeightDetails ? `Weighing ${item.weight} ${item.weightUnit}` : null
          ])}

          {!hasFullWeightDetails && (
            <span>
              {`Weighing: N/A (`}
              <Link href={item.internal_url} target="_blank" showColor>
                View on Amazon
              </Link>
              {`)`}
            </span>
          )}
        </Typography>

        <Typography variant="body2" sx={styles.itemPrice}>
          {joinStringWithBullet([
            `$${parseFloat(item.price).toFixed(2)}`,
            `Amazon price as of ${date(item.priceAt).format(
              `${dateTimeFormats.date.medium} ${dateTimeFormats.time.short}`
            )} EST`
          ])}
        </Typography>
      </Box>

      <Button
        text="View on Amazon"
        buttonType="primary"
        size="small"
        variant="text"
        href={item.internal_url}
        target="_blank"
        sx={styles.viewOnAmazonButton}
      >
        View on Amazon
      </Button>
    </Box>
  )
}

export default ItemDetails
