import { useEffect, useRef } from 'react'

const useOnceEffect = (effect: () => void | (() => void)) => {
  const hasRun = useRef(false)

  useEffect(() => {
    if (hasRun.current) return

    const cleanup = effect()
    hasRun.current = true

    return cleanup
  }, [effect])
}

const reactHooks = {
  useOnceEffect
}

export default reactHooks
