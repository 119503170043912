import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  AcceptPublicOfferErrorResponse,
  AcceptPublicOfferRequest,
  AcceptPublicOfferSuccessResponse
} from './acceptPublicOffer.types'

export const acceptPublicOfferApi = async (
  request: AcceptPublicOfferRequest
): Promise<AcceptPublicOfferSuccessResponse> => {
  const response = await configureRequest({
    url: `${urls.publicOffers.acceptPublicOffer}`,
    method: 'POST',
    headers: {
      'X-User-Role': UserRole.traveler
    },
    data: request
  })

  if (response.status >= 200 && response.status < 300) {
    return response as AcceptPublicOfferSuccessResponse
  } else {
    throw response as AcceptPublicOfferErrorResponse
  }
}

export default acceptPublicOfferApi
