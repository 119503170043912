import { SxProps } from '@mui/material'

import theme from '../../shared/styles/themes/default.theme'

const styles = {
  container: {
    paddingTop: theme.spacing(10)
  } as SxProps
}

export default styles
