const styles = {
  carouselContainer: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  carouselAndStepperContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  carouselItem: {
    width: '100%',
    height: '100%'
  },

  carouselWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },

  carouselLeft: {
    flex: 1,
    marginRight: 2
  },

  carouselRight: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}

export default styles
