import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { getUserRole } from '../../../../shared/functions/UserRole/userRoleManagment.functions'
import {
  UpdateItemRequestTrackingDetailsErrorResponse,
  UpdateItemRequestTrackingDetailsRequest,
  UpdateItemRequestTrackingDetailsSuccessResponse
} from './updateItemRequestTrackingDetails.types'

export const updateItemRequestTrackingDetailsApi = async (
  request: UpdateItemRequestTrackingDetailsRequest
): Promise<UpdateItemRequestTrackingDetailsSuccessResponse> => {
  const response = await configureRequest({
    url: urls.offers.updateItemRequestTrackingDetails,
    method: 'POST',
    data: request,
    headers: {
      'X-User-Role': getUserRole() || ''
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as UpdateItemRequestTrackingDetailsSuccessResponse
  } else {
    throw response as UpdateItemRequestTrackingDetailsErrorResponse
  }
}

export default updateItemRequestTrackingDetailsApi
