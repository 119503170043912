import { CSSProperties } from 'react'

import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  legHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[200]}`
  } as CSSProperties,

  emptyStateContainer: {
    padding: theme.spacing(1, 0)
  } as CSSProperties,

  accordionHeaderMainContent: {
    textAlign: 'left'
  } as CSSProperties,

  legHeaderMainContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  } as CSSProperties,

  legHeaderTitle: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left'
  } as CSSProperties,

  legDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  } as CSSProperties,

  divider: {
    margin: theme.spacing(1, 0, 1, 0),
    width: '40%'
  } as CSSProperties
}

export default styles
