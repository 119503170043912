import React from 'react'

import { Autocomplete, TextField } from '@mui/material'
import { Box } from '@mui/material'

import { FormFieldOption } from '../Form/Form.types'
import styles from './Dropdown.styles'
import { DropdownProps } from './Dropdown.types'

const Dropdown: React.FC<DropdownProps> = (props) => {
  const { options, setValue, name, label, isEditable, value, disabled, readOnly, marginTop } = props

  return (
    <Box sx={styles.container(marginTop)}>
      <Autocomplete
        options={options || []}
        getOptionLabel={(option: FormFieldOption) => option.label}
        renderInput={(params: object) => (
          <TextField {...params} name={name} label={label} fullWidth sx={styles.textField(isEditable)} />
        )}
        readOnly={readOnly}
        value={options?.find((option: FormFieldOption) => option.value === value) || null}
        onChange={(_event: unknown, newValue: FormFieldOption | null) => {
          setValue(newValue ? newValue.value.toString() : '')
        }}
        disabled={disabled}
      />
    </Box>
  )
}

export default Dropdown
