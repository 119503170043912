import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  CancelItemRequestErrorResponse,
  CancelItemRequestRequest,
  CancelItemRequestSuccessResponse
} from './cancelItemRequest.types'

export const cancelItemRequestApi = async (
  request: CancelItemRequestRequest
): Promise<CancelItemRequestSuccessResponse> => {
  const response = await configureRequest({
    url: urls.offers.cancelItemRequest,
    method: 'POST',
    params: {
      itemRequestId: request.itemRequestId
    },
    headers: {
      'X-User-Role': UserRole.shopper
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as CancelItemRequestSuccessResponse
  } else {
    throw response as CancelItemRequestErrorResponse
  }
}

export default cancelItemRequestApi
