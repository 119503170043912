import React from 'react'

import { SxProps } from '@mui/material'

import theme from '../../styles/themes/default.theme'

const styles: { [key: string]: SxProps } = {
  container: {
    marginTop: theme.spacing(10),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  } as SxProps,

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius / 4,
    boxShadow: theme.shadows[15],
    width: '50%',
    minWidth: '280px'
  },

  iconContainer: {
    marginBottom: theme.spacing(0)
  },

  icon: {
    fontSize: '4rem',
    color: theme.palette.success.main
  },

  pendingIcon: {
    fontSize: '4rem',
    color: theme.palette.warning.main
  },

  failedIcon: {
    fontSize: '4rem',
    color: theme.palette.error.main
  },

  title: {
    color: theme.palette.grey[800],
    textAlign: 'center',
    fontWeight: '500',
    marginBottom: theme.spacing(4)
  } as React.CSSProperties,

  subtitle: {
    color: theme.palette.grey[500],
    textAlign: 'justify',
    fontWeight: '500',
    marginBottom: theme.spacing(2)
  } as React.CSSProperties,

  actionText: {
    color: theme.palette.grey[500],
    fontWeight: '700'
  }
}

export default styles
