import React from 'react'

import { Box, styled } from '@mui/material'

import styles, { stepperContainerStyles } from './DotStepper.styles'
import { DotStepperProps } from './DotStepper.types'

const StepperContainer = styled(Box)(stepperContainerStyles)

const Dot = styled(Box)<{ active: boolean }>(({ active }) => styles(active))

const DotStepper: React.FC<DotStepperProps> = ({ steps, activeStep, onStepChange }) => {
  return (
    <StepperContainer>
      {Array.from({ length: steps }, (_, index) => (
        <Dot key={index} active={index === activeStep} onClick={() => onStepChange(index)} />
      ))}
    </StepperContainer>
  )
}

export default DotStepper
