import {
  Analytics as FireBaseAnalytics,
  getAnalytics,
  logEvent as logFirebaseEvent,
  setUserId
} from 'firebase/analytics'
import { FirebaseApp, initializeApp } from 'firebase/app'
import {
  RemoteConfig,
  fetchAndActivate,
  getBoolean,
  getNumber,
  getRemoteConfig,
  getString
} from 'firebase/remote-config'

import logger from '../Logger/logger.functions'
import firebaseConfig from './firebase.config'
import firebaseDictionary from './firebase.dictionaries'
import { ConfigDescription, ConfigKey, FirebaseConfig } from './firebase.types'

/**
 * Wrapper class for Firebase Analytics.
 */
class Analytics {
  private firebaseAnalytics: FireBaseAnalytics

  public constructor(firebaseAnalytics: FireBaseAnalytics) {
    this.firebaseAnalytics = firebaseAnalytics
  }

  public setUserId(userId: string): void {
    setUserId(this.firebaseAnalytics, userId)
  }

  /**
   * Logs an event in Firebase, Apptentive, and Instana.
   * @param {string} eventName Name of event to log | Max length = 40 characters,
   * @param {string} parameters Array of objects to log to Firebase | Max parameter value length = 100 characters
   * @example logEvent('Login_Button_Pressed')
   * @example logEvent('User_Created', {date: '12/12/2023'})
   */
  public logEvent = (eventName: string, parameters?: Record<string, string | number | boolean>) => {
    try {
      logFirebaseEvent(this.firebaseAnalytics, eventName, parameters)
    } catch (error) {
      logger.logError(error, 'Error logging event in logger', 'logEvent', parameters)
    }
  }
}

/**
 * Wrapper class for Firebase Remote Config.
 */
class FirebaseRemoteConfig {
  private remoteConfig: RemoteConfig

  private configKeys: Record<ConfigKey, ConfigDescription> = firebaseDictionary.remoteConfigVariables

  public constructor(firebaseRemoteConfig: RemoteConfig) {
    this.remoteConfig = firebaseRemoteConfig
    this.remoteConfig.settings = {
      fetchTimeoutMillis: 60000, // 1 minute
      minimumFetchIntervalMillis: 3600000 // 1 hour
    }
    this.refreshConfig()
  }

  /**
   * Refreshes the Firebase Remote Config.
   * @returns A promise that resolves when the config is refreshed.
   */
  public async refreshConfig(): Promise<void> {
    await fetchAndActivate(this.remoteConfig)
  }

  /**
   * Retrieves the value of a config key.
   * @param key The config key.
   * @returns The value of the config key.
   * @throws {Error} An error if the data type of the config key is unsupported.
   */
  public getConfigValue<T>(key: ConfigKey): T {
    const configDescription = this.configKeys[key]
    let jsonString = ''

    switch (configDescription.dataType) {
      case 'string':
        return getString(this.remoteConfig, key) as T

      case 'number':
        return getNumber(this.remoteConfig, key) as T

      case 'boolean':
        return getBoolean(this.remoteConfig, key) as T

      case 'array':
        // Assuming the array is stored as a JSON string
        jsonString = getString(this.remoteConfig, key)

        if (jsonString) {
          try {
            return JSON.parse(jsonString) as T
          } catch (error) {
            logger.logError(error, `Error parsing JSON for config key: ${key}`, 'getConfigValue')
          }
        } else {
          logger.logError('Invalid JSON string', `Empty JSON string for config key: ${key}`, 'getConfigValue')
        }
        break

      default:
        logger.logError('Unsupported data type', `Unsupported data type for config key: ${key}`, 'getConfigValue')
    }

    return undefined as unknown as T
  }
}

/**
 * Firebase service.
 */
export class FirebaseService {
  private static instance: FirebaseService
  public analytics: Analytics
  public remoteConfig: FirebaseRemoteConfig | null = null
  public app: FirebaseApp

  private constructor(firebaseConfig: FirebaseConfig) {
    this.app = initializeApp(firebaseConfig)
    this.analytics = new Analytics(getAnalytics())

    if (this.isIndexedDBAvailable()) {
      const remoteConfigInstance = getRemoteConfig()

      remoteConfigInstance.settings = {
        fetchTimeoutMillis: 60000, // 1 minute
        minimumFetchIntervalMillis: 3600000 // 1 hour
      }

      this.remoteConfig = new FirebaseRemoteConfig(remoteConfigInstance)
    } else {
      logger.logError(
        new Error('IndexedDB is not supported by the current browser. Remote Config will not be initialized.'),
        'FirebaseService',
        'constructor'
      )
    }
  }

  isIndexedDBAvailable(): boolean {
    try {
      return !!window.indexedDB
    } catch (e) {
      return false
    }
  }

  public static configure(): void {
    if (!this.instance) {
      this.instance = new FirebaseService(firebaseConfig)
      this.instance.remoteConfig?.refreshConfig()
    }
  }

  public static getInstance(): FirebaseService {
    if (!this.instance) {
      this.configure()
    }

    if (!this.instance) {
      throw new Error('FirebaseService not initialized')
    }

    return this.instance
  }
}

const firebase = FirebaseService.getInstance()

export default firebase
