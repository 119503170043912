import { Country } from '../../../redux/states/firebaseRemoteConfig/firebaseRemoteConfig.types'
import logger from '../Logger/logger.functions'
import { countryCodes } from './places.dictionary'

/**
 * Get the list of countries with their names and codes using the Intl.DisplayNames API
 * @returns {Array} - The list of countries
 */
export function getCountries(): Country[] {
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' })

  const countries = countryCodes.map((code) => {
    try {
      return { name: regionNames.of(code) ?? 'N/A', code }
    } catch (error) {
      logger.logError(error, `Error getting country name for code: ${code}`, 'getCountries')

      return { name: 'N/A', code }
    }
  })

  return countries
}

/**
 * Get a country by its country code
 * @param countryCode - The ISO country code to look up
 * @returns {Country | undefined} - The country object if found, undefined if not found
 */
export function getCountryByCode(countryCode?: string): Country | undefined {
  if (!countryCode) return undefined

  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' })

  try {
    const name = regionNames.of(countryCode)

    return name ? { name, code: countryCode } : undefined
  } catch (error) {
    logger.logError(error, `Error getting country name for code: ${countryCode}`, 'getCountryByCode')

    return undefined
  }
}
