import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import resetOfferRequestFlightItineraryApi from './resetOfferRequestFlightItinerary.api'
import resetOfferRequestFlightItineraryInitialState from './resetOfferRequestFlightItinerary.initialState'
import {
  ResetOfferRequestFlightItineraryErrorResponse,
  ResetOfferRequestFlightItineraryRequestPayload
} from './resetOfferRequestFlightItinerary.types'

export const resetOfferRequestFlightItinerary = createAsyncThunk(
  urls.offers.resetOfferRequestFlightItinerary,
  async (payload: ResetOfferRequestFlightItineraryRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await resetOfferRequestFlightItineraryApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const resetOfferRequestFlightItinerarySlice = createSlice({
  name: urls.offers.resetOfferRequestFlightItinerary,
  initialState: resetOfferRequestFlightItineraryInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetOfferRequestFlightItinerary.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(resetOfferRequestFlightItinerary.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(resetOfferRequestFlightItinerary.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as ResetOfferRequestFlightItineraryErrorResponse
        state.success = null
      })
  }
})

export const { actions: resetOfferRequestFlightItineraryActions, reducer: resetOfferRequestFlightItineraryReducer } =
  resetOfferRequestFlightItinerarySlice
export default resetOfferRequestFlightItinerarySlice.reducer
