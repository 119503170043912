import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import { getFlightItineraryApi } from './getFlightItinerary.api'
import getFlightItineraryInitialState from './getFlightItinerary.initialState'
import { GetFlightItineraryErrorResponse, GetFlightItineraryRequestPayload } from './getFlightItinerary.types'

export const getFlightItinerary = createAsyncThunk(
  urls.flights.getFlightItinerary,
  async (payload: GetFlightItineraryRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await getFlightItineraryApi(request)

      onSuccess && onSuccess(response)

      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const getFlightItinerarySlice = createSlice({
  name: urls.flights.getFlightItinerary,
  initialState: getFlightItineraryInitialState,
  reducers: {
    clearFlightItinerarySuccess: (state) => {
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFlightItinerary.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getFlightItinerary.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getFlightItinerary.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetFlightItineraryErrorResponse
        state.success = null
      })
  }
})

export const { actions: getFlightItineraryActions, reducer: getFlightItineraryReducer } = getFlightItinerarySlice
export const { clearFlightItinerarySuccess } = getFlightItineraryActions
export default getFlightItinerarySlice.reducer
