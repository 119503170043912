import { useEffect, useState } from 'react'

import { Box, SelectChangeEvent } from '@mui/material'

import FilterDropdown from '../../../../../shared/components/FilterDropdown/FilterDropdown.component'

import styles from './FilterResults.styles'
import { FilterResultsProps } from './FilterResults.types'

const sortOptions = ['Cheapest', 'Fastest', 'Best']
const stopOptions = ['Direct only', '1 stop at most', '2 stops at most', 'Any number of stops']

const FilterResults = ({ setFilters, availableAirlines }: FilterResultsProps) => {
  const [selectedAirlines, setSelectedAirlines] = useState<string[]>(['All'])
  const [selectedSort, setSelectedSort] = useState<string>(sortOptions[0])
  const [selectedStop, setSelectedStop] = useState<string>(stopOptions[3])

  useEffect(() => {
    setFilters({
      airlines: selectedAirlines.includes('All') ? [] : selectedAirlines,
      sort: selectedSort,
      stops: selectedStop
    })
  }, [selectedAirlines, selectedSort, selectedStop, setFilters])

  const handleAirlineChange = (event: SelectChangeEvent<string | number | (string | number)[]>) => {
    const value = event.target.value as string[]
    if (value.includes('All') && value.length > 1) {
      setSelectedAirlines(value.filter((airline) => airline !== 'All'))
    } else if (value.length === 0) {
      setSelectedAirlines(['All'])
    } else {
      setSelectedAirlines(value)
    }
  }

  const handleSortChange = (event: SelectChangeEvent<string | number | (string | number)[]>) => {
    setSelectedSort(event.target.value as string)
  }

  const handleStopChange = (event: SelectChangeEvent<string | number | (string | number)[]>) => {
    setSelectedStop(event.target.value as string)
  }

  const handleResetSort = () => {
    setSelectedSort(sortOptions[0])
  }

  const handleResetAirlines = () => {
    setSelectedAirlines(['All'])
  }

  const handleResetStop = () => {
    setSelectedStop(stopOptions[3])
  }

  return (
    <Box sx={styles.container}>
      {/* {currentSearchValues && (
        <EditSearch searchInfo={currentSearchValues} onEditSearch={() => setEditSearchModalOpen(true)} />
      )}

      <EditSearchModal open={editSearchModalOpen} onClose={() => setEditSearchModalOpen(false)} /> */}

      <FilterDropdown
        label="Sort"
        options={sortOptions}
        selectedValue={selectedSort}
        onChange={handleSortChange}
        resetHandler={handleResetSort}
      />

      <FilterDropdown
        label="Airline"
        options={availableAirlines.map((airline) => airline.name)}
        selectedValue={selectedAirlines}
        onChange={handleAirlineChange}
        multiple
        renderValue={(selected) => (selected as string[]).join(', ')}
        resetHandler={handleResetAirlines}
      />

      <FilterDropdown
        label="Stops"
        options={stopOptions}
        selectedValue={selectedStop}
        onChange={handleStopChange}
        resetHandler={handleResetStop}
      />
    </Box>
  )
}

export default FilterResults
