import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { cancelFlightItineraryApi } from './cancelFlightItinerary.api'
import cancelFlightItineraryInitialState from './cancelFlightItinerary.initialState'
import { CancelFlightItineraryErrorResponse, CancelFlightItineraryRequestPayload } from './cancelFlightItinerary.types'

export const cancelFlightItinerary = createAsyncThunk(
  urls.flights.cancelFlightItinerary,
  async (payload: CancelFlightItineraryRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await cancelFlightItineraryApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const cancelFlightItinerarySlice = createSlice({
  name: urls.flights.cancelFlightItinerary,
  initialState: cancelFlightItineraryInitialState,
  reducers: {
    resetCancelFlightItinerary: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(cancelFlightItinerary.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(cancelFlightItinerary.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(cancelFlightItinerary.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as CancelFlightItineraryErrorResponse
        state.success = null
      })
  }
})

export const { actions: cancelFlightItineraryActions, reducer: cancelFlightItineraryReducer } =
  cancelFlightItinerarySlice
export const { resetCancelFlightItinerary } = cancelFlightItineraryActions
export default cancelFlightItinerarySlice.reducer
