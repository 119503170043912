import theme from '../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: theme.spacing(10),
    height: '100vh'
  }
}

export default styles
