import React from 'react'

import { SxProps } from '@mui/material'

import theme from '../../shared/styles/themes/default.theme'

const styles = {
  container: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(15)
  } as SxProps,

  title: {
    color: theme.palette.grey[800],
    textAlign: 'left',
    fontWeight: '500'
  } as React.CSSProperties,

  subtitle: {
    color: theme.palette.grey[500],
    textAlign: 'left',
    fontWeight: '500'
  } as React.CSSProperties,

  form: {
    width: '100%',
    marginTop: theme.spacing(3)
  } as SxProps,

  submit: {
    margin: theme.spacing(3, 0, 2)
  } as SxProps,

  link: {
    color: theme.palette.text.secondary
  } as SxProps,

  alternativeActionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1.5)
  },

  loginTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  loginText: {
    color: theme.palette.grey[700],
    fontSize: '16px'
  },

  loginLink: {
    color: theme.palette.primary.main,
    fontSize: '16px',
    marginLeft: theme.spacing(0.5)
  },

  termsContainer: {
    marginTop: theme.spacing(10)
  },

  termsTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  termsText: {
    color: theme.palette.grey[700],
    fontSize: '14px'
  },

  termsLink: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5)
  },

  divider: {
    marginBottom: theme.spacing(2)
  }
}

export default styles
