import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  CreateFlightItineraryErrorResponse,
  CreateFlightItineraryRequest,
  CreateFlightItinerarySuccessResponse
} from './createFlightItinerary.types'

export const createFlightItineraryApi = async (
  request: CreateFlightItineraryRequest
): Promise<CreateFlightItinerarySuccessResponse> => {
  const response = await configureRequest({
    url: urls.flights.createFlightItinerary,
    method: 'POST',
    data: request
  })

  if (response.status >= 200 && response.status < 300) {
    return response as CreateFlightItinerarySuccessResponse
  } else {
    throw response as CreateFlightItineraryErrorResponse
  }
}

export default createFlightItineraryApi
