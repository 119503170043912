import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Alert, Box, Card, CardContent, Typography } from '@mui/material'
import _ from 'lodash'
import * as Yup from 'yup'

import Form from '../../../../shared/components/Form/Form.component'
import BaggageDetails from './components/BaggageDetails/BaggageDetails.component'
import SeatDetails from './components/SeatDetails/SeatDetails.component'

import { getBaggageDetails } from '../../../../redux/states/flights/getBaggageDetails/getBaggageDetails.slice'
import { GetBaggageDetailsRequestPayload } from '../../../../redux/states/flights/getBaggageDetails/getBaggageDetails.types'
import { getSeatDetails } from '../../../../redux/states/flights/getSeatDetails/getSeatDetails.slice'
import { GetSeatDetailsRequestPayload } from '../../../../redux/states/flights/getSeatDetails/getSeatDetails.types'
import { FormSection } from '../../../../shared/components/Form/Form.types'
import date from '../../../../shared/functions/Date/date.functions'
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux.hooks'
import styles from './PassengerDetails.styles'
import { PassengerDetailsProps, PassengerDetailsValues } from './PassengerDetails.types'

const PassengerDetails: React.FC<PassengerDetailsProps> = (props) => {
  const {
    fareOffer,
    onFormChange,
    onBaggageSelectionsChange,
    onSeatSelectionsChange,
    countriesAndCities,
    userProfile
  } = props

  const { success: baggageDetailsResponse } = useAppSelector((state) => state.getBaggageDetails)
  const { success: seatDetailsResponse } = useAppSelector((state) => state.getSeatDetails)

  const dispatch = useAppDispatch()

  const onLoadBaggageDetails = useCallback(() => {
    if (baggageDetailsResponse) {
      return
    }

    const payload: GetBaggageDetailsRequestPayload = {
      request: {
        'external-system-flight-offer-id': fareOffer.id
      },
      onSuccess: () => {
        //
      }
    }

    dispatch(getBaggageDetails(payload))
  }, [dispatch, fareOffer.id, baggageDetailsResponse])

  const onLoadSeatDetails = useCallback(() => {
    if (seatDetailsResponse) {
      return
    }

    const payload: GetSeatDetailsRequestPayload = {
      request: {
        'external-system-flight-offer-id': fareOffer.id
      },
      onSuccess: () => {
        //
      }
    }

    dispatch(getSeatDetails(payload))
  }, [dispatch, fareOffer.id, seatDetailsResponse])

  useEffect(() => {
    onLoadBaggageDetails()
    onLoadSeatDetails()
  }, [onLoadBaggageDetails, onLoadSeatDetails])

  const [passengers, setPassengers] = useState<PassengerDetailsValues[]>(
    fareOffer?.passengers.map((passenger, index) => {
      let dateOfBirth = ''

      if (index === 0 && userProfile?.dateOfBirth) {
        dateOfBirth = date(userProfile.dateOfBirth).format('yyyy-MM-dd') ?? ''
      }

      return {
        id: passenger.id,
        title: '',
        firstName: index === 0 ? userProfile?.firstName ?? '' : '',
        lastName: index === 0 ? userProfile?.lastName ?? '' : '',
        dateOfBirth,
        gender: '',
        phoneNumber: index === 0 ? userProfile?.mobileNumber ?? '' : '',
        passportNumber: '',
        issuingCountry: '',
        expirationDate: ''
      }
    })
  )

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string().required('Title is required'),
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        dateOfBirth: Yup.date().required('Date of birth is required'),
        gender: Yup.string().required('Gender is required'),
        phoneNumber: Yup.string().required('Phone number is required'),
        passportNumber: Yup.string().required('Passport number is required'),
        issuingCountry: Yup.string().required('Issuing country is required'),
        expirationDate: Yup.date().required('Expiration date is required') // TODO: Add validation for expiration date (must be after the last flight date)
      }),
    []
  )

  const handleFormChange = useCallback(
    (values: PassengerDetailsValues, passengerId: string) => {
      setPassengers((prevPassengers) => {
        const updatedPassengers = prevPassengers.map((passenger) =>
          passenger.id === passengerId ? { ...passenger, ...values } : passenger
        )

        const hasChanged = !_.isEqual(prevPassengers, updatedPassengers)

        if (!hasChanged) {
          return prevPassengers
        }

        const allValid = updatedPassengers.every(
          (p, index) =>
            p.title &&
            p.firstName &&
            p.lastName &&
            p.dateOfBirth &&
            p.gender &&
            (index === 0 ? p.phoneNumber : true) &&
            p.passportNumber &&
            p.issuingCountry &&
            p.expirationDate
        )

        onFormChange(updatedPassengers, allValid)

        return updatedPassengers
      })
    },
    [onFormChange]
  )

  const getFormSections = (passengerIndex: number): FormSection[] => [
    {
      title: 'Personal Information',
      fields: [
        {
          name: 'title',
          label: 'Title',
          type: 'select',
          options: [
            { value: 'mr', label: 'Mr.' },
            { value: 'mrs', label: 'Mrs.' },
            { value: 'ms', label: 'Ms.' },
            { value: 'dr', label: 'Dr.' }
          ],
          grid: { sm: 12, md: 2 },
          required: true,
          fullWidth: true
        },
        {
          name: 'firstName',
          label: 'First Name',
          type: 'text',
          grid: { sm: 12, md: 5 },
          required: true,
          fullWidth: true,
          autoFocus: true
        },
        {
          name: 'lastName',
          label: 'Last Name',
          type: 'text',
          grid: { sm: 12, md: 5 },
          required: true,
          fullWidth: true
        },
        {
          name: 'dateOfBirth',
          label: 'Date of Birth',
          type: 'date',
          grid: { sm: 12, md: 6 },
          required: true,
          fullWidth: true
        },
        {
          name: 'gender',
          label: 'Gender',
          type: 'select',
          options: [
            { value: 'm', label: 'Male' },
            { value: 'f', label: 'Female' }
          ],
          grid: { sm: 12, md: 6 },
          required: true,
          fullWidth: true
        },
        // Only include phone number field for first passenger
        ...(passengerIndex === 0
          ? [
              {
                name: 'phoneNumber',
                label: 'Phone Number',
                type: 'tel',
                grid: { sm: 12, md: 12 },
                required: true,
                fullWidth: true
              }
            ]
          : [])
      ]
    },
    {
      title: 'Passport Information',
      fields: [
        {
          name: 'passportNumber',
          label: 'Passport Number',
          type: 'text',
          grid: { sm: 12, md: 6 },
          required: true,
          fullWidth: true,
          preventAutoFocus: true
        },
        {
          name: 'issuingCountry',
          label: 'Issuing Country',
          type: 'select',
          options: countriesAndCities?.map((country) => ({
            value: country.iataCountryCode,
            label: country.countryName
          })),
          grid: { sm: 12, md: 6 },
          required: true,
          fullWidth: true
        },
        {
          name: 'expirationDate',
          label: 'Expiration Date',
          type: 'date',
          grid: { sm: 12, md: 12 },
          required: true,
          fullWidth: true
        }
      ]
    }
  ]

  return (
    <Box sx={styles.container}>
      {passengers.map((passenger, index) => (
        <Card key={passenger.id} sx={styles.passengerCard(passengers.length)}>
          <CardContent>
            <Typography variant="h6" sx={styles.passengerTitle}>
              Passenger {index + 1} Details
            </Typography>

            <Alert severity="warning" sx={styles.warningBox}>
              <Typography sx={styles.warningText}>
                Please ensure the personal details and passport details are for the passenger.
              </Typography>
            </Alert>

            <Form
              hideButtons={true}
              buttonText=""
              initialValues={passenger}
              validationSchema={validationSchema}
              onSubmit={() => {
                // Handle form submission if necessary
              }}
              formSections={getFormSections(index)}
              onFormChange={(values) => {
                handleFormChange(values.values as PassengerDetailsValues, passenger.id)
              }}
            />
          </CardContent>
        </Card>
      ))}

      <BaggageDetails onBaggageSelectionsChange={onBaggageSelectionsChange} fareOffer={fareOffer} />
      <SeatDetails fareOffer={fareOffer} onSeatSelectionsChange={onSeatSelectionsChange} />
    </Box>
  )
}

export default PassengerDetails
