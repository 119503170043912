import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import Snackbar from '../../../../shared/components/Snackbar/Snackbar.functions'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { updatePersonalInfoApi } from './updatePersonalInfo.api'
import updatePersonalInfoInitialState from './updatePersonalInfo.initialState'
import { UpdatePersonalInfoErrorResponse, UpdatePersonalInfoRequest } from './updatePersonalInfo.types'

export const updatePersonalInfo = createAsyncThunk(
  urls.profile.updatePersonalInfo,
  async (
    {
      updatePersonalInfoRequest,
      onSuccess
    }: { updatePersonalInfoRequest: UpdatePersonalInfoRequest; onSuccess: () => void },
    { rejectWithValue }
  ) => {
    try {
      const response = await updatePersonalInfoApi(updatePersonalInfoRequest)

      onSuccess()

      Snackbar.show({
        message: response.userFriendlyMessage,
        severity: 'success'
      })

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const updatePersonalInfoSlice = createSlice({
  name: urls.profile.updatePersonalInfo,
  initialState: updatePersonalInfoInitialState,
  reducers: {
    // Add any additional reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePersonalInfo.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(updatePersonalInfo.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(updatePersonalInfo.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as UpdatePersonalInfoErrorResponse
        state.success = null
      })
  }
})

export const { actions: updatePersonalInfoActions, reducer: updatePersonalInfoReducer } = updatePersonalInfoSlice
export default updatePersonalInfoSlice.reducer
