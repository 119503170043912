import theme from '../../../../../shared/styles/themes/default.theme'

const styles = {
  autocompletePaper: {
    boxShadow: theme.shadows[5],
    maxWidth: 300,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    marginTop: theme.spacing(1)
  }
}

export default styles
