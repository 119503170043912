import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { createFlightItineraryApi } from './createFlightItinerary.api'
import createFlightItineraryInitialState from './createFlightItinerary.initialState'
import { CreateFlightItineraryErrorResponse, CreateFlightItineraryRequestPayload } from './createFlightItinerary.types'

export const createFlightItinerary = createAsyncThunk(
  urls.flights.createFlightItinerary,
  async ({ request, onSuccess }: CreateFlightItineraryRequestPayload, { rejectWithValue }) => {
    try {
      const response = await createFlightItineraryApi(request)

      onSuccess?.()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const createFlightItinerarySlice = createSlice({
  name: urls.flights.createFlightItinerary,
  initialState: createFlightItineraryInitialState,
  reducers: {
    clearSuccess(state) {
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFlightItinerary.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(createFlightItinerary.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(createFlightItinerary.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as CreateFlightItineraryErrorResponse
        state.success = null
      })
  }
})

export const { actions: createFlightItineraryActions, reducer: createFlightItineraryReducer } =
  createFlightItinerarySlice
export const { clearSuccess: clearCreateFlightItinerarySuccess } = createFlightItineraryActions
export default createFlightItinerarySlice.reducer
