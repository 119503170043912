import {
  TrackingDetails,
  TrackingDetailsKeys
} from '../../../redux/states/offers/getItemRequestTrackingDetails/getItemRequestTrackingDetails.types'
import { UserRole } from '../../functions/UserRole/userRoleManagement.types'
import { getUserRole } from '../../functions/UserRole/userRoleManagment.functions'
import { TrackingDetailsStatus } from './TrackingDetails.types'

export const getStatuses = (trackingDetails: TrackingDetails, destinationCity: string) => {
  const userRole = getUserRole()

  const statuses: TrackingDetailsStatus[] = [
    {
      id: TrackingDetailsKeys.AcceptedByTraveler,
      name: 'Accepted by traveler',
      description: 'Item has been accepted by traveler.',
      timestamp: trackingDetails?.acceptedByTraveler.timestamp,
      status: trackingDetails?.acceptedByTraveler.status,
      visible: false
    },
    {
      id: TrackingDetailsKeys.PurchasedByTraveler,
      name: 'Item Purchased',
      description: 'Item has been purchased.',
      timestamp: trackingDetails?.purchasedByTraveler.timestamp,
      status: trackingDetails?.purchasedByTraveler.status,
      visible: true
    },
    {
      id: TrackingDetailsKeys.InTransitToTraveler,
      name: 'Item in transit',
      description: 'Item is in transit to traveler.',
      timestamp: trackingDetails?.inTransitToTraveler.timestamp,
      status: trackingDetails?.inTransitToTraveler.status,
      visible: false
    },
    {
      id: TrackingDetailsKeys.DeliveredToTravelerAddress,
      name: 'Item Delivered to Traveler',
      description: 'Item has been delivered to traveler.',
      timestamp: trackingDetails?.deliveredToTravelerAddress.timestamp,
      status: trackingDetails?.deliveredToTravelerAddress.status,
      visible: false
    },
    {
      id: TrackingDetailsKeys.ConfirmedReceivedByTraveler,
      name: userRole === UserRole.traveler ? 'Item Received' : 'Traveler Has Item',
      description: 'Traveler has confirmed receipt of item.',
      timestamp: trackingDetails?.confirmedReceivedByTraveler.timestamp,
      status: trackingDetails?.confirmedReceivedByTraveler.status,
      visible: true
    },
    {
      id: TrackingDetailsKeys.ConfirmedInDestinationCountry,
      name: `Arrived in ${destinationCity}`,
      description: 'Traveler has arrived in destination country.',
      timestamp: trackingDetails?.confirmedInDestinationCountry.timestamp,
      status: trackingDetails?.confirmedInDestinationCountry.status,
      visible: true
    },
    {
      id: TrackingDetailsKeys.ConfirmedDeliveredToShopperByTraveler,
      name: 'Item Delivered',
      description: 'Traveler has confirmed delivery of item to the shopper.',
      timestamp: trackingDetails?.confirmedDeliveredToShopperByTraveler.timestamp,
      status: trackingDetails?.confirmedDeliveredToShopperByTraveler.status,
      visible: userRole === UserRole.traveler
    },
    {
      id: TrackingDetailsKeys.ConfirmedDeliveredToShopperByShopper,
      name: 'Item Delivered',
      description: 'Shopper has confirmed delivery of item by the traveler.',
      timestamp: trackingDetails?.confirmedDeliveredToShopperByShopper.timestamp,
      status: trackingDetails?.confirmedDeliveredToShopperByShopper.status,
      visible: userRole === UserRole.shopper
    },
    {
      id: TrackingDetailsKeys.Lost,
      name: 'Lost',
      description: 'Item has been lost.',
      timestamp: trackingDetails?.lost.timestamp,
      status: trackingDetails?.lost.status,
      visible: false
    },
    {
      id: TrackingDetailsKeys.OutOfStock,
      name: 'Out of stock',
      description: 'Item is out of stock.',
      timestamp: trackingDetails?.outOfStock.timestamp,
      status: trackingDetails?.outOfStock.status,
      visible: false
    }
  ]

  return statuses
}

export const getCurrentTrackingDetailsStatus = (trackingStatuses: TrackingDetailsStatus[]) => {
  for (let i = 0; i < trackingStatuses.length; i++) {
    if (!trackingStatuses[i].status) {
      return trackingStatuses[i]?.id || trackingStatuses[0].id
    }
  }

  return trackingStatuses[trackingStatuses.length - 1].id
}

export const getNextTrackingDetailsStatus = (trackingStatuses: TrackingDetailsStatus[]) => {
  const currentStatusKey = getCurrentTrackingDetailsStatus(trackingStatuses)
  const currentStatusIndex = trackingStatuses.findIndex((status) => status.id === currentStatusKey)
  const nextStatusIndex = currentStatusIndex + 1
  const nextStatusKey = trackingStatuses[nextStatusIndex].id

  return nextStatusKey
}

export const isStatusTrue = (trackingDetails: TrackingDetails, status: TrackingDetailsKeys) => {
  return trackingDetails[status].status
}
