import theme from '../../../../../shared/styles/themes/default.theme'

const styles = {
  passengerSelectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2
  },

  passengerTypeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  counterContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 1
  },

  divider: {
    margin: theme.spacing(1, 0)
  },

  counterButton: {
    border: '1px solid #ccc',
    borderRadius: '50%',
    padding: 0.5
  },

  childrenAgeContainer: {
    marginTop: 2
  },

  childAgeField: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(4)
  },

  ageLabel: {
    marginBottom: 1
  },

  ageDropdown: {
    minWidth: 120
  },

  ageInfoText: {
    fontSize: '0.875rem'
  }
}

export default styles
