import { SxProps } from '@mui/material'

import theme from '../../shared/styles/themes/default.theme'

const styles = {
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
    width: '100%',
    minHeight: 'calc(100vh - 64px - 56px)'
  },

  container: {
    paddingRight: theme.spacing(4),
    textAlign: 'left'
  } as SxProps,

  loaderContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },

  summaryTitle: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800],
    lineHeight: '24px',
    marginBottom: theme.spacing(1)
  } as SxProps,

  summarySubtitle: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[500],
    lineHeight: '24px',
    marginBottom: theme.spacing(3)
  } as SxProps,

  tripDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(4)
  } as SxProps,

  tripDetail: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1]
  } as SxProps,

  tripDetailsTitle: {
    marginBottom: theme.spacing(1)
  } as SxProps,

  promoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.warning.light,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(4)
  } as SxProps,

  shareLinkContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    marginBottom: theme.spacing(4)
  } as SxProps,

  overviewContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1]
  } as SxProps,

  tripOverviewContainer: {
    marginTop: theme.spacing(2)
  } as SxProps,

  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  } as SxProps,

  tripUpdatedAlert: {} as SxProps,

  tripUpdatedAlertText: {
    fontSize: theme.typography.fontSize
  } as SxProps
}

export default styles
