import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { createCustomerSessionApi } from './createCustomerSession.api'
import createCustomerSessionInitialState from './createCustomerSession.initialState'
import { CreateCustomerSessionErrorResponse, CreateCustomerSessionRequestPayload } from './createCustomerSession.types'

export const createCustomerSession = createAsyncThunk(
  urls.payments.createCustomerSession,
  async (payload: CreateCustomerSessionRequestPayload, { rejectWithValue }) => {
    const { onSuccess } = payload

    try {
      const response = await createCustomerSessionApi()

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const createCustomerSessionSlice = createSlice({
  name: urls.payments.createCustomerSession,
  initialState: createCustomerSessionInitialState,
  reducers: {
    resetCreateCustomerSessionState: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCustomerSession.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(createCustomerSession.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(createCustomerSession.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as CreateCustomerSessionErrorResponse
        state.success = null
      })
  }
})

export const { actions: createCustomerSessionActions, reducer: createCustomerSessionReducer } =
  createCustomerSessionSlice
export const { resetCreateCustomerSessionState } = createCustomerSessionActions
export default createCustomerSessionSlice.reducer
