import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react'

import Modal from '../Modal.component'

import { setModalContext } from '../Modal.functions'
import { ModalButton } from '../Modal.types'
import { ModalContextProps, ModalState, ShowModalProps } from './Modal.context.types'

const ModalContext = createContext<ModalContextProps | undefined>(undefined)

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [modalState, setModalState] = useState<ModalState>({
    open: false,
    title: undefined,
    subtitle: undefined,
    icon: undefined,
    body: undefined,
    primaryButton: undefined,
    secondaryButton: undefined
  })

  const [primaryButton, setPrimaryButton] = useState<ModalButton | undefined>(undefined)
  const [secondaryButton, setSecondaryButton] = useState<ModalButton | undefined>(undefined)

  const showModal = (props: ShowModalProps) => {
    setModalState({
      open: true,
      title: props.title,
      subtitle: props.subtitle,
      icon: props.icon,
      body: props.body ?? undefined,
      primaryButton: props.primaryButton,
      secondaryButton: props.secondaryButton
    })
  }

  const handleClose = () => {
    setModalState((prev) => ({ ...prev, open: false }))
  }

  useEffect(() => {
    setModalContext({ show: showModal, isOpen: false })
  }, [])

  useEffect(() => {
    setModalContext({ show: showModal, isOpen: modalState.open })
  }, [modalState.open])

  useEffect(() => {
    if (modalState.primaryButton) {
      const { onClick, ...rest } = modalState.primaryButton

      const wrappedOnClick = () => {
        if (onClick) {
          onClick()
        }

        handleClose()
      }

      setPrimaryButton({ ...rest, onClick: wrappedOnClick })
    } else {
      setPrimaryButton(undefined)
    }
  }, [modalState.primaryButton])

  useEffect(() => {
    if (modalState.secondaryButton) {
      const { onClick, ...rest } = modalState.secondaryButton

      const wrappedOnClick = () => {
        if (onClick) {
          onClick()
        }

        handleClose()
      }

      setSecondaryButton({ ...rest, onClick: wrappedOnClick })
    } else {
      setSecondaryButton(undefined)
    }
  }, [modalState.secondaryButton])

  return (
    <ModalContext.Provider value={{ show: showModal, isOpen: modalState.open }}>
      {children}

      <Modal
        title={modalState.title}
        subtitle={modalState.subtitle}
        icon={modalState.icon}
        body={modalState.body}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        open={modalState.open}
        onClose={handleClose}
      />
    </ModalContext.Provider>
  )
}

export const useModal = (): ModalContextProps => {
  const context = useContext(ModalContext)

  if (!context) {
    throw new Error('useModal must be used within a ModalProvider')
  }

  return context
}
