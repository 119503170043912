import FlightTakeoff from '@mui/icons-material/FlightTakeoff'
import LinkIcon from '@mui/icons-material/Link'
import SearchIcon from '@mui/icons-material/Search'

import images from '../../assets/images'
import { styles } from '../../shared/components/StepByStepGuide/StepByStepGuide.styles'

const TruckIcon = images.TruckIcon

const dictionary = {
  pageBanner: {
    title: 'Save 50% Instantly on Flights. Carry Items for Friends and Family.',
    subtitle: 'Book your flight for less by accepting to courrier items for family and friends of friends.',
    link: {
      text: 'How it works?',
      url: '#'
    }
  },

  howToTravelAndEarn: {
    title: 'How to travel and earn',
    subtitle: 'In 4 simple steps, you can fly for less.',
    steps: [
      {
        icon: <SearchIcon />,
        title: 'Flight Search',
        description: 'Search for flight.'
      },

      {
        icon: <LinkIcon sx={styles.rotatedIcon} />,
        title: 'Share Link',
        description: 'Friends of friends and family (FFFs) use share link to request items.'
      },

      {
        icon: <FlightTakeoff />,
        title: 'Book Flight',
        description: 'Carry items for a fee. Instantly receive fees & item amount to apply to your flight.'
      },

      {
        icon: <TruckIcon />,
        title: 'Buy Item and Deliver',
        description: 'Buy amazon item with your money. Deliver to your FFF in person.'
      }
    ]
  }
}

export default dictionary
