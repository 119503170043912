import React from 'react'

import { Logout } from '@mui/icons-material'
import { Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material'

import Avatar from '../../../Avatar/Avatar.component'

import { logout } from '../../../../../redux/states/auth/logout/logout.slice'
import router from '../../../../../router/functions/router.functions'
import routes from '../../../../../router/routes.dictionary'
import logger from '../../../../functions/Logger/logger.functions'
import { useAppDispatch } from '../../../../hooks/redux.hooks'
import { AvatarSize } from '../../../Avatar/Avatar.types'
import styles from './ProfileMenu.styles'
import { ProfileMenuProps } from './ProfileMenu.types'

const ProfileMenu: React.FC<ProfileMenuProps> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isProfileMenuOpen = Boolean(anchorEl)

  const navigate = router.navigate()
  const dispatch = useAppDispatch()

  const handleClickProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)

    logger.logEvent(`profile_menu_opened_${router.getCurrentPage().path}`)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    const onSuccess = () => {
      navigate(routes.home.path)
    }

    const onError = () => {
      navigate(routes.login.path)
    }

    dispatch(logout({ onSuccess, onError }))
  }

  const menuItems = [
    {
      label: 'Profile',
      onClick: () => {
        handleClose()
        navigate(routes.profile.path)
        logger.logEvent(`profile_option_clicked_${router.getCurrentPage().path}`)
      }
    },

    {
      label: 'Logout',
      onClick: () => {
        handleClose()
        handleLogout()
        logger.logEvent(`logout_option_clicked_${router.getCurrentPage().path}`)
      }
    }
  ]

  return (
    <Box>
      <Tooltip title="Account Settings">
        <IconButton
          onClick={handleClickProfileMenu}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={isProfileMenuOpen ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isProfileMenuOpen ? 'true' : undefined}
        >
          <Avatar size={AvatarSize.small} />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={isProfileMenuOpen}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: styles.paper
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {menuItems.map((item, index) => {
          if (item.label !== 'Logout') {
            return (
              <MenuItem key={index} onClick={item.onClick}>
                {item.label}
              </MenuItem>
            )
          } else {
            return (
              <Box key={index}>
                <Divider />

                <MenuItem key={item.label} onClick={item.onClick}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>

                  {item.label}
                </MenuItem>
              </Box>
            )
          }
        })}
      </Menu>
    </Box>
  )
}

export default ProfileMenu
