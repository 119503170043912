import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../shared/styles/themes/default.theme'

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    padding: theme.spacing(5, 10)
  } as SxProps,

  sharedTripContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%'
  } as SxProps,

  pageTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: theme.spacing(8)
  } as SxProps,

  title: {
    textAlign: 'left',
    fontWeight: theme.typography.fontWeightBold
  } as SxProps,

  subtitle: {
    textAlign: 'left',
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.grey[500]
  } as SxProps,

  hourrierCardContainer: {
    width: '100%',
    paddingBottom: theme.spacing(2)
  } as SxProps,

  mainBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    padding: theme.spacing(2, 3, 5, 3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius / 5,
    boxShadow: theme.shadows[1]
  } as SxProps,

  stepByStepGuideContainer: {
    width: '100%',
    marginBottom: theme.spacing(7)
  } as SxProps,

  urlInputContainer: {
    width: '100%',
    paddingBottom: theme.spacing(2)
  } as SxProps,

  urlWidgetRootContainer: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0),
    border: 'none'
  } as SxProps,

  urlWidgetContainer: {
    width: '100%'
  } as SxProps,

  urlWidget: {
    width: '100%'
  } as SxProps,

  urlWidgetTextField: {
    margin: theme.spacing(5, 2, 5, 2)
  } as SxProps,

  submitOrderContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1)
  } as SxProps,

  submitOrderButton: {
    padding: theme.spacing(1, 2),
    textTransform: 'none'
  } as CSSProperties
}
