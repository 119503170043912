import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { getItemDetailsApi } from './getItemDetails.api'
import getItemDetailsInitialState from './getItemDetails.initialState'
import { GetItemDetailsErrorResponse, GetItemDetailsRequestPayload } from './getItemDetails.types'

export const getItemDetails = createAsyncThunk(
  urls.items.getItemDetails,
  async (payload: GetItemDetailsRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await getItemDetailsApi(request)

      onSuccess && onSuccess(response.data)

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const getItemDetailsSlice = createSlice({
  name: urls.items.getItemDetails,
  initialState: getItemDetailsInitialState,
  reducers: {
    clearItemDetailsSuccess: (state) => {
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getItemDetails.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getItemDetails.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getItemDetails.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetItemDetailsErrorResponse
        state.success = null
      })
  }
})

export const { actions: getItemDetailsActions, reducer: getItemDetailsReducer } = getItemDetailsSlice
export const { clearItemDetailsSuccess } = getItemDetailsActions
export default getItemDetailsSlice.reducer
