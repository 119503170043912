import { CreateOfferRequestState } from './createOfferRequest.types'

const initialState: CreateOfferRequestState = {
  loading: false,
  error: null,
  success: null
  // Initialize any additional state properties here
}

export default initialState
