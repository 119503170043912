export interface LoaderProps {
  loading: boolean
  text?: string
  size?: LoaderSize
  marginTop?: number
}

export type LoaderSize = 'small' | 'medium' | 'large'

export const loaderSizeMap: Record<LoaderSize, number> = {
  small: 24,
  medium: 32,
  large: 64
}
