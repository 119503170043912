import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { createOfferRequestApi } from './createOfferRequest.api'
import createOfferRequestInitialState from './createOfferRequest.initialState'
import { CreateOfferRequestErrorResponse, CreateOfferRequestRequestPayload } from './createOfferRequest.types'

export const createOfferRequest = createAsyncThunk(
  urls.offers.createOfferRequest,
  async (payload: CreateOfferRequestRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await createOfferRequestApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const createOfferRequestSlice = createSlice({
  name: urls.offers.createOfferRequest,
  initialState: createOfferRequestInitialState,
  reducers: {
    // Add any additional reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOfferRequest.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(createOfferRequest.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(createOfferRequest.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as CreateOfferRequestErrorResponse
        state.success = null
      })
  }
})

export const { actions: createOfferRequestActions, reducer: createOfferRequestReducer } = createOfferRequestSlice
export default createOfferRequestSlice.reducer
