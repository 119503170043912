import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  LogPaymentMethodUpdateErrorResponse,
  LogPaymentMethodUpdateSuccessResponse
} from './logPaymentMethodUpdate.types'

export const logPaymentMethodUpdateApi = async (): Promise<LogPaymentMethodUpdateSuccessResponse> => {
  const response = await configureRequest({
    url: urls.payments.logPaymentMethodUpdate,
    method: 'POST'
  })

  if (response.status >= 200 && response.status < 300) {
    return response as LogPaymentMethodUpdateSuccessResponse
  } else {
    throw response as LogPaymentMethodUpdateErrorResponse
  }
}

export default logPaymentMethodUpdateApi
