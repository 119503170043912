import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  AddDeliveryAddressErrorResponse,
  AddDeliveryAddressRequest,
  AddDeliveryAddressSuccessResponse
} from './addDeliveryAddress.types'

export const addDeliveryAddressApi = async (
  request: AddDeliveryAddressRequest
): Promise<AddDeliveryAddressSuccessResponse> => {
  const response = await configureRequest({
    url: urls.profile.addDeliveryAddress,
    method: 'POST',
    data: request
  })

  if (response.status >= 200 && response.status < 300) {
    return response as AddDeliveryAddressSuccessResponse
  } else {
    throw response as AddDeliveryAddressErrorResponse
  }
}

export default addDeliveryAddressApi
