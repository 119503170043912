import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  ChangePasswordErrorResponse,
  ChangePasswordRequest,
  ChangePasswordSuccessResponse
} from './changePassword.types'

export const changePasswordApi = async (request: ChangePasswordRequest): Promise<ChangePasswordSuccessResponse> => {
  const response = await configureRequest({
    url: urls.auth.changePassword,
    method: 'POST',
    data: request
  })

  if (response.status >= 200 && response.status < 300) {
    return response as ChangePasswordSuccessResponse
  } else {
    throw response as ChangePasswordErrorResponse
  }
}

export default changePasswordApi
