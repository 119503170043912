import theme from '../../../shared/styles/themes/default.theme'

const styles = {
  modalBody: {
    padding: theme.spacing(0)
  },

  priceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.blue[50],
    border: `1px solid ${theme.palette.blue[200]}`,
    borderRadius: theme.shape.borderRadius / 5,
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },

  currentPriceBox: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1, 2),
    border: `1px solid ${theme.palette.blue[200]}`,
    borderRadius: theme.shape.borderRadius / 5
  },

  priceText: {
    color: theme.palette.secondary.dark,
    fontWeight: 'bold'
  },

  saveBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.spacing(2)
  },

  saveText: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5)
  },

  sectionTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 'bold'
  },

  optionsContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    flexDirection: 'row',
    flex: 1
  },

  optionButtonContainer: {
    flex: 1
  },

  optionButton: {
    flex: 1,
    border: `1px solid ${theme.palette.grey[300]}`,
    color: theme.palette.text.secondary
  },

  selectedOption: {
    border: `1px solid ${theme.palette.blue[800]}`,
    color: theme.palette.blue[800],
    fontWeight: theme.typography.fontWeightBold
  },

  fullWidthButton: {
    flex: 1
  },

  setNowButton: {
    width: '100%',
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    }
  }
} as const

export default styles
