import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { Box } from '@mui/material'

import FilterSection from './FilterSection/FilterSection.component'

import styles from './UniversalFilterSideBar.styles'
import { Filters, UniversalFilterSideBarProps } from './UniversalFilterSideBar.types'

const UniversalFilterSideBar = forwardRef(({ sections, onChange }: UniversalFilterSideBarProps, ref) => {
  const [filterValues, setFilterValues] = useState<Filters>({})

  useEffect(() => {
    const initialValues: Filters = {}
    sections.forEach((section) => {
      initialValues[section.id] = section.defaultValue || (section.type === 'multi-select' ? [] : '')
    })
    setFilterValues(initialValues)
  }, [sections])

  const handleFilterChange = (sectionId: string, value: unknown) => {
    const newFilterValues = { ...filterValues, [sectionId]: value }
    setFilterValues(newFilterValues)
    onChange(newFilterValues)
  }

  const handleReset = (sectionId: string) => {
    const section = sections.find((s) => s.id === sectionId)
    if (section) {
      const defaultValue = section.defaultValue || (section.type === 'multi-select' ? [] : '')
      handleFilterChange(sectionId, defaultValue)
    }
  }

  useImperativeHandle(ref, () => ({
    resetFilters: () => {
      const initialValues: Filters = {}
      sections.forEach((section) => {
        initialValues[section.id] = section.defaultValue || (section.type === 'multi-select' ? [] : '')
      })
      setFilterValues(initialValues)
      onChange(initialValues)
    }
  }))

  return (
    <Box sx={styles.container}>
      {sections.map((section) => (
        <FilterSection
          key={section.id}
          {...section}
          value={filterValues[section.id]}
          onChange={(value) => handleFilterChange(section.id, value)}
          onReset={() => handleReset(section.id)}
        />
      ))}
    </Box>
  )
})

export default UniversalFilterSideBar
