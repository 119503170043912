import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  AddItemTrackingNumberErrorResponse,
  AddItemTrackingNumberRequest,
  AddItemTrackingNumberSuccessResponse
} from './addItemTrackingNumber.types'

export const addItemTrackingNumberApi = async (
  request: AddItemTrackingNumberRequest
): Promise<AddItemTrackingNumberSuccessResponse> => {
  const response = await configureRequest({
    url: urls.offers.addItemTrackingNumber,
    method: 'POST',
    data: {
      itemRequestIds: [request.itemRequestId],
      trackingNumber: request.trackingNumber
    },
    headers: {
      'X-User-Role': UserRole.traveler
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as AddItemTrackingNumberSuccessResponse
  } else {
    throw response as AddItemTrackingNumberErrorResponse
  }
}

export default addItemTrackingNumberApi
