import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import { getUserRole } from '../../../../shared/functions/UserRole/userRoleManagment.functions'
import { CreateCustomerSessionErrorResponse, CreateCustomerSessionSuccessResponse } from './createCustomerSession.types'

export const createCustomerSessionApi = async (): Promise<CreateCustomerSessionSuccessResponse> => {
  const response = await configureRequest({
    url: urls.payments.createCustomerSession,
    method: 'GET',
    params: {},
    headers: {
      'X-User-Role': getUserRole() ?? UserRole.traveler
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as CreateCustomerSessionSuccessResponse
  } else {
    throw response as CreateCustomerSessionErrorResponse
  }
}

export default createCustomerSessionApi
