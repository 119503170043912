import React from 'react'

import { Box, Typography } from '@mui/material'

import Button from '../Button/Button.component'

import images from '../../../assets/images'
import styles from './EmptyState.styles'
import { EmptyStateProps } from './EmptyState.types'

const { warning } = images

export const EmptyState: React.FC<EmptyStateProps> = (props) => {
  const { title, subtitle, button, noIcon } = props

  return (
    <Box sx={styles.container}>
      {!noIcon && <img src={warning} style={styles.icon} alt="warning" />}

      <Box sx={styles.textContainer}>
        <Typography variant="body1" sx={styles.title}>
          {title}
        </Typography>

        <Typography variant="body1" sx={styles.subtitle}>
          {subtitle}
        </Typography>
      </Box>

      {button && <Button {...button} buttonType="secondary" />}
    </Box>
  )
}
