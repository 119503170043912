import { FlightItinerary } from '../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import date, { dateTimeFormats } from '../../shared/functions/Date/date.functions'
import { joinStringWithBullet } from '../../shared/functions/String/string.functions'
import { CabinClass, cabinClassMapping } from '../Travel/components/FlightSearchWidget/FlightSearchWidget.types'

export const getFormattedTripDetails = (flightItinerary: FlightItinerary): string => {
  const firstLeg = flightItinerary.itinerary.legs[0]

  const tripType = flightItinerary.itinerary.tripType
  let tripDetails = ''

  switch (tripType) {
    case 'oneWay':
      tripDetails = `${firstLeg.origin.city.name} (${firstLeg.origin.airport.iataCode}) → ${firstLeg.destination.city.name} (${firstLeg.destination.airport.iataCode})`
      break

    case 'roundTrip':
      tripDetails = `${firstLeg.origin.city.name} (${firstLeg.origin.airport.iataCode}) ⇌ ${firstLeg.destination.city.name} (${firstLeg.destination.airport.iataCode})`
      break

    case 'multiCity':
      tripDetails = flightItinerary.itinerary.legs
        .map(
          (leg) =>
            `${leg.origin.city.name} (${leg.origin.airport.iataCode}) → ${leg.destination.city.name} (${leg.destination.airport.iataCode})`
        )
        .join('\u00A0 • \u00A0')
      break
  }

  return tripDetails
}

export const getFormattedTravelerDateDetails = (flightItinerary: FlightItinerary): string => {
  const firstLeg = flightItinerary.itinerary.legs[0]
  const lastLeg = flightItinerary.itinerary.legs[flightItinerary.itinerary.legs.length - 1]
  let dateDetails = ''
  const tripType = flightItinerary.itinerary.tripType

  if (tripType === 'oneWay') {
    dateDetails = joinStringWithBullet([
      `Departing: ${date(firstLeg.travelDate).format(dateTimeFormats.date.medium)}`
    ]) as string
  }

  if (tripType === 'roundTrip') {
    dateDetails = joinStringWithBullet([
      `Departing: ${date(firstLeg.travelDate).format(dateTimeFormats.date.medium)}`,
      `Returning: ${date(lastLeg.travelDate).format(dateTimeFormats.date.medium)}`
    ]) as string
  }

  if (tripType === 'multiCity') {
    dateDetails = joinStringWithBullet([
      `Departing: ${date(firstLeg.travelDate).format(dateTimeFormats.date.medium)}`
    ]) as string
  }

  return dateDetails
}

export const getFormattedCabinClass = (cabinClass: CabinClass): string => {
  return cabinClassMapping[cabinClass]
}
