import { useCallback, useMemo, useState } from 'react'

import { Box, Chip, Typography } from '@mui/material'

import Accordion from '../../../../shared/components/Accordion/Accordion.component'
import Avatar from '../../../../shared/components/Avatar/Avatar.component'
import { EmptyState } from '../../../../shared/components/EmptyState/EmptyState.component'
import ItemRequestAccordion from '../ItemRequestAccordion/ItemRequestAccordion.component'

import { ItemRequest, OfferRequest } from '../../../../redux/states/offers/getOfferRequests/getOfferRequests.types'
import { releaseOfferRequest } from '../../../../redux/states/offers/releaseOfferRequest/releaseOfferRequest.slice'
import { ReleaseOfferRequestRequestPayload } from '../../../../redux/states/offers/releaseOfferRequest/releaseOfferRequest.types'
import { requestOfferRequestRefund } from '../../../../redux/states/payments/requestOfferRequestRefund/requestOfferRequestRefund.slice'
import router from '../../../../router/functions/router.functions'
import routes from '../../../../router/routes.dictionary'
import { AccordionAlertProps } from '../../../../shared/components/Accordion/Accordion.types'
import { AvatarSize } from '../../../../shared/components/Avatar/Avatar.types'
import Modal from '../../../../shared/components/Modal/Modal.functions'
import { PaginatedRenderProps } from '../../../../shared/components/PaginatedPage/PaginatedPage.types'
import status from '../../../../shared/dictionaries/status.dictionaries'
import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'
import { useAppDispatch } from '../../../../shared/hooks/redux.hooks'
import styles from './OfferRequestsAccordion.styles'

const OfferRequestsAccordion = (props: PaginatedRenderProps) => {
  const offerRequest = props.item as unknown as OfferRequest
  const refreshData = props.refreshData

  const [isRequestRefundLoading, setIsRequestRefundLoading] = useState<string[]>([])
  const [isReleaseOfferRequestLoading, setIsReleaseOfferRequestLoading] = useState<string[]>([])

  const navigate = router.navigate()
  const dispatch = useAppDispatch()

  const onRequestRefund = useCallback(() => {
    Modal.show({
      title: 'Accept Refund',
      subtitle: 'Your offer will no longer be available for any Hurrier to carry and a refund will be processed.',
      primaryButton: {
        label: 'Confirm',
        onClick: () => {
          const payload = {
            request: {
              offerRequestId: offerRequest.offerRequestId.toString()
            },
            onSuccess: () => {
              refreshData?.(() => {
                setIsRequestRefundLoading((prev) => prev.filter((id) => id !== offerRequest.offerRequestId.toString()))
              })
            },
            onError: () => {
              setIsRequestRefundLoading((prev) => prev.filter((id) => id !== offerRequest.offerRequestId.toString()))
            }
          }

          setIsRequestRefundLoading((prev) => [...prev, offerRequest.offerRequestId.toString()])
          dispatch(requestOfferRequestRefund(payload))
        }
      },
      secondaryButton: {
        label: 'Dismiss'
      }
    })
  }, [dispatch, offerRequest.offerRequestId, refreshData])

  const onFindTraveler = useCallback(() => {
    Modal.show({
      title: 'Find Traveler',
      subtitle: 'Your offer will be placed on the Offers marketplace for other Hurriers to accept.',
      primaryButton: {
        label: 'Confirm',
        onClick: () => {
          const payload: ReleaseOfferRequestRequestPayload = {
            request: {
              offerRequestId: offerRequest.offerRequestId.toString()
            },
            onSuccess: () => {
              refreshData?.(() => {
                setIsReleaseOfferRequestLoading((prev) =>
                  prev.filter((id) => id !== offerRequest.offerRequestId.toString())
                )
              })
            },
            onError: () => {
              setIsReleaseOfferRequestLoading((prev) =>
                prev.filter((id) => id !== offerRequest.offerRequestId.toString())
              )
            }
          }

          setIsReleaseOfferRequestLoading((prev) => [...prev, offerRequest.offerRequestId.toString()])
          dispatch(releaseOfferRequest(payload))
        }
      },
      secondaryButton: {
        label: 'Dismiss'
      }
    })
  }, [dispatch, offerRequest.offerRequestId, refreshData])

  const currentAlert = useMemo(() => {
    const paymentStatus = offerRequest.paymentStatus?.name
    const tripStatus = offerRequest.tripDetails?.tripStatus

    const isFlightItineraryCancelled =
      tripStatus === status.flightStatus.flightBookingCancelledBeforePayment ||
      tripStatus === status.flightStatus.flightCancelledAfterPayment

    const isOfferRequestRefunded =
      paymentStatus === status.paymentStatus.refundConfirmed ||
      paymentStatus === status.paymentStatus.paymentHoldCancelled

    const isPaymentFailed =
      paymentStatus === status.paymentStatus.paymentFailed || paymentStatus === status.paymentStatus.paymentHoldFailed

    if (isFlightItineraryCancelled && isOfferRequestRefunded) {
      return {
        message: `Your order has been refunded.`,
        type: 'warning',
        tag: 'refund-processed'
      } as AccordionAlertProps
    } else if (isFlightItineraryCancelled) {
      const travelerName = `${offerRequest.tripDetails.traveler.firstName}`

      return {
        message: `${travelerName} cancelled their trip. You may accept a refund or find a new traveler.`,
        type: 'error',
        tag: 'flight-cancelled',
        buttons: [
          {
            text: 'Accept Refund',
            onClick: onRequestRefund,
            loading: isRequestRefundLoading.includes(offerRequest.offerRequestId.toString())
          },
          {
            text: 'Find Traveler',
            onClick: onFindTraveler,
            loading: isReleaseOfferRequestLoading.includes(offerRequest.offerRequestId.toString())
          }
        ]
      } as AccordionAlertProps
    } else if (isPaymentFailed) {
      return {
        message: `There was an issue processing your payment for this order.`,
        type: 'error',
        tag: 'payment-failed',
        buttons: [
          {
            text: 'Update Payment Details',
            onClick: () => navigate(routes.profile.path, { state: { selectedSection: 'payment' } })
          }
        ]
      } as AccordionAlertProps
    }

    return null
  }, [
    offerRequest.paymentStatus?.name,
    offerRequest.tripDetails?.tripStatus,
    offerRequest.tripDetails?.traveler.firstName,
    offerRequest.offerRequestId,
    isRequestRefundLoading,
    onRequestRefund,
    navigate,
    isReleaseOfferRequestLoading,
    onFindTraveler
  ])

  const canOfferRequestBeInteractedWith = useMemo(() => {
    const tripStatus = offerRequest.tripDetails?.tripStatus
    const paymentStatus = offerRequest.paymentStatus?.name
    let canBeInteractedWith = true

    const isFlightItineraryCancelled =
      tripStatus === status.flightStatus.flightBookingCancelledBeforePayment ||
      tripStatus === status.flightStatus.flightCancelledAfterPayment

    const isPaymentRefunded =
      paymentStatus === status.paymentStatus.refundConfirmed ||
      paymentStatus === status.paymentStatus.paymentHoldCancelled

    if (isFlightItineraryCancelled || isPaymentRefunded) {
      canBeInteractedWith = false
    }

    return canBeInteractedWith
  }, [offerRequest.tripDetails?.tripStatus, offerRequest.paymentStatus?.name])

  const refreshOfferRequests = (onSuccess?: (data?: unknown) => void) => {
    refreshData?.(onSuccess)
  }

  const getAccordionHeader = () => {
    const orderNumber = offerRequest.offerRequestId

    const accordionHeader = {
      mainContent: (
        <Typography variant="h6" sx={styles.accordionHeaderMainContent}>
          Order# {orderNumber}
        </Typography>
      )
    }

    return accordionHeader
  }

  const renderItemRequest = (itemRequest: ItemRequest) => (
    <ItemRequestAccordion
      itemRequest={itemRequest}
      deliveryDetails={offerRequest.deliveryDetails}
      tripDetails={offerRequest.tripDetails}
      refreshOfferRequests={refreshOfferRequests}
      isInteractionEnabled={canOfferRequestBeInteractedWith}
    />
  )

  const getArrivalDate = () => {
    const deliveryCity = offerRequest.deliveryDetails.city
    const flightItinerary = offerRequest.tripDetails.flights

    for (let i = flightItinerary.length - 1; i >= 0; i--) {
      const flight = flightItinerary[i]

      if (flight.destination?.city?.name === deliveryCity.name) {
        return date(flight.arrivalTime).format(dateTimeFormats.date.medium)
      }
    }

    return null
  }

  const renderDetails = () => {
    const hasItemRequests = offerRequest.itemRequests.length > 0
    const travelerName = `${offerRequest.tripDetails?.traveler.firstName} ${offerRequest.tripDetails?.traveler.lastName}`

    const shopperDetails = () => {
      if (offerRequest.tripDetails !== null && offerRequest.tripDetails.traveler !== null) {
        const arrivalDate = getArrivalDate() ?? ''
        const formattedArrivalDate = date(arrivalDate).format(dateTimeFormats.date.medium)
        const isArrivalDatePast = date(formattedArrivalDate!).isBefore(date().currentDate)

        return (
          <Box style={styles.shopperDetailsContainer}>
            <Avatar
              firstName={offerRequest.tripDetails.traveler.firstName}
              lastName={offerRequest.tripDetails.traveler.lastName}
              size={AvatarSize.small}
            />

            <Box style={styles.shopperDetailsTextContainer}>
              <Box sx={styles.shopperDetailsNameContainer}>
                <Typography sx={styles.shopperDetailsName}>{travelerName}</Typography>
                {!isArrivalDatePast && <Chip sx={styles.arrivalDateChip} label={`Arriving ${getArrivalDate()}`} />}
              </Box>

              <Typography sx={styles.shopperDetailsTag}>Hurrier</Typography>
            </Box>
          </Box>
        )
      }
    }

    if (!hasItemRequests) {
      return (
        <Box style={styles.emptyStateContainer}>
          <EmptyState
            title={'You have no orders available'}
            button={{
              text: 'Shop Now',
              onClick: () => navigate(routes.shop.path)
            }}
          />
        </Box>
      )
    } else {
      return (
        <Box>
          {offerRequest.itemRequests.map(renderItemRequest)}

          {/* TODO: Add logic to check if the trip is completed */}
          {shopperDetails()}
        </Box>
      )
    }
  }

  return (
    <Accordion
      header={getAccordionHeader()}
      body={renderDetails()}
      alert={currentAlert ?? undefined}
      noExpandIcon
      noExpandBackgroundColor
      isExpanded
      preventExpandChange
    />
  )
}

export default OfferRequestsAccordion
