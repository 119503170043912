import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { addDeliveryAddressApi } from './addDeliveryAddress.api'
import addDeliveryAddressInitialState from './addDeliveryAddress.initialState'
import { AddDeliveryAddressErrorResponse, AddDeliveryAddressRequest } from './addDeliveryAddress.types'

export const addDeliveryAddress = createAsyncThunk(
  urls.profile.addDeliveryAddress,
  async (
    {
      addDeliveryAddressRequest,
      onSuccess
    }: { addDeliveryAddressRequest: AddDeliveryAddressRequest; onSuccess: () => void },
    { rejectWithValue }
  ) => {
    try {
      const response = await addDeliveryAddressApi(addDeliveryAddressRequest)

      onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const addDeliveryAddressSlice = createSlice({
  name: urls.profile.addDeliveryAddress,
  initialState: addDeliveryAddressInitialState,
  reducers: {
    // Add any additional reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(addDeliveryAddress.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(addDeliveryAddress.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(addDeliveryAddress.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as AddDeliveryAddressErrorResponse
        state.success = null
      })
  }
})

export const { actions: addDeliveryAddressActions, reducer: addDeliveryAddressReducer } = addDeliveryAddressSlice
export default addDeliveryAddressSlice.reducer
