import React from 'react'

import { Box, Checkbox, FormControl, ListItemText, MenuItem, Select, Typography } from '@mui/material'

import Link from '../Link/Link.component'

import styles from './FilterDropdown.styles'
import { FilterDropdownProps, Option } from './FilterDropdown.types'

const FilterDropdown: React.FC<FilterDropdownProps> = ({
  label,
  options,
  selectedValue,
  onChange,
  multiple = false,
  renderValue,
  resetHandler
}) => {
  const isOptionObject = (option: string | Option): option is Option => {
    return (option as Option).value !== undefined
  }

  return (
    <Box sx={styles.filterSection}>
      <Box sx={styles.header}>
        <Typography variant="h6" sx={styles.sectionTitle}>
          {label}
        </Typography>

        <Link onClick={resetHandler} showColor style={styles.resetButton}>
          Reset
        </Link>
      </Box>

      <FormControl fullWidth>
        <Select
          size="small"
          sx={styles.select}
          multiple={multiple}
          value={selectedValue}
          onChange={onChange}
          renderValue={renderValue}
        >
          {options.map((option) => {
            const value = isOptionObject(option) ? option.value : option

            return (
              <MenuItem key={value ?? ''} value={value ?? ''}>
                {multiple && (
                  <Checkbox checked={(selectedValue as (string | number)[]).indexOf(value as string | number) > -1} />
                )}

                <ListItemText primary={isOptionObject(option) ? option.label : option} />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

export default FilterDropdown
