import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  CommitPurchasePriceErrorResponse,
  CommitPurchasePriceRequest,
  CommitPurchasePriceSuccessResponse
} from './commitPurchasePrice.types'

export const commitPurchasePriceApi = async (
  request: CommitPurchasePriceRequest
): Promise<CommitPurchasePriceSuccessResponse> => {
  const response = await configureRequest({
    url: urls.items.commitPurchase,
    method: 'POST',
    data: request,
    headers: {
      'X-User-Role': UserRole.traveler
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as CommitPurchasePriceSuccessResponse
  } else {
    throw response as CommitPurchasePriceErrorResponse
  }
}

export default commitPurchasePriceApi
