import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import date from '../../../../shared/functions/Date/date.functions'
import {
  GetPublicOffersErrorResponse,
  GetPublicOffersRequest,
  GetPublicOffersSuccessResponse
} from './getPublicOffers.types'

export const getPublicOffersApi = async (request: GetPublicOffersRequest): Promise<GetPublicOffersSuccessResponse> => {
  const response = await configureRequest({
    url: urls.publicOffers.getPublicOffers,
    method: 'GET',
    params: {
      page: request.page,
      size: request.size,
      searchQuery: request.searchQuery,
      countries: request.countries as string[],
      maxDate: (request.maxDate ? (date(request.maxDate).endOf('day').toISO() as string) : undefined) as string
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetPublicOffersSuccessResponse
  } else {
    throw response as GetPublicOffersErrorResponse
  }
}

export default getPublicOffersApi
