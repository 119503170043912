import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  UpdateDeliveryAddressErrorResponse,
  UpdateDeliveryAddressRequest,
  UpdateDeliveryAddressSuccessResponse
} from './updateDeliveryAddress.types'

export const updateDeliveryAddressApi = async (
  request: UpdateDeliveryAddressRequest
): Promise<UpdateDeliveryAddressSuccessResponse> => {
  const response = await configureRequest({
    url: `${urls.profile.updateDeliveryAddress}?deliveryAddressId=${request.deliveryAddressId}`,
    method: 'PUT',
    data: {
      streetAddress: request.streetAddress,
      cityId: request.cityId,
      countryId: request.countryId,
      postalCode: request.postalCode
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as UpdateDeliveryAddressSuccessResponse
  } else {
    throw response as UpdateDeliveryAddressErrorResponse
  }
}

export default updateDeliveryAddressApi
