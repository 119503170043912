import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { getFlightItineraryShareLinkApi } from './getFlightItineraryShareLink.api'
import getFlightItineraryShareLinkInitialState from './getFlightItineraryShareLink.initialState'
import {
  GetFlightItineraryShareLinkErrorResponse,
  GetFlightItineraryShareLinkRequestPayload
} from './getFlightItineraryShareLink.types'

export const getFlightItineraryShareLink = createAsyncThunk(
  urls.flights.getFlightItineraryShareLink,
  async (payload: GetFlightItineraryShareLinkRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await getFlightItineraryShareLinkApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const getFlightItineraryShareLinkSlice = createSlice({
  name: urls.flights.getFlightItineraryShareLink,
  initialState: getFlightItineraryShareLinkInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFlightItineraryShareLink.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getFlightItineraryShareLink.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getFlightItineraryShareLink.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetFlightItineraryShareLinkErrorResponse
        state.success = null
      })
  }
})

export const { actions: getFlightItineraryShareLinkActions, reducer: getFlightItineraryShareLinkReducer } =
  getFlightItineraryShareLinkSlice
export default getFlightItineraryShareLinkSlice.reducer
