import theme from '../../shared/styles/themes/default.theme'

export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    minHeight: '100%'
  },

  pageContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(5)
  },

  emptyStateContainer: {
    paddingTop: theme.spacing(3)
  },

  offersList: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    paddingTop: theme.spacing(5)
  },

  // ordersContainer: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   gap: theme.spacing(3),
  //   padding: theme.spacing(3, 10)
  // },

  main: {
    flex: 1
  },

  loginButton: {
    marginTop: theme.spacing(2)
  }
}
