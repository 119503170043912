import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  GetSeatDetailsErrorResponse,
  GetSeatDetailsRequest,
  GetSeatDetailsSuccessResponse
} from './getSeatDetails.types'

export const getSeatDetailsApi = async (request: GetSeatDetailsRequest): Promise<GetSeatDetailsSuccessResponse> => {
  const response = await configureRequest({
    url: urls.flights.seatDetails,
    method: 'GET',
    data: request,
    params: {
      'external-system-flight-offer-id': request['external-system-flight-offer-id']
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetSeatDetailsSuccessResponse
  } else {
    throw response as GetSeatDetailsErrorResponse
  }
}

export default getSeatDetailsApi
