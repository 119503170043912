import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import { getBaggageDetailsApi } from './getBaggageDetails.api'
import getBaggageDetailsInitialState from './getBaggageDetails.initialState'
import { GetBaggageDetailsErrorResponse, GetBaggageDetailsRequestPayload } from './getBaggageDetails.types'

export const getBaggageDetails = createAsyncThunk(
  urls.flights.baggageDetails,
  async (payload: GetBaggageDetailsRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await getBaggageDetailsApi(request)

      onSuccess && onSuccess(response)

      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const getBaggageDetailsSlice = createSlice({
  name: urls.flights.baggageDetails,
  initialState: getBaggageDetailsInitialState,
  reducers: {
    resetGetBaggageDetails: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBaggageDetails.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getBaggageDetails.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getBaggageDetails.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetBaggageDetailsErrorResponse
        state.success = null
      })
  }
})

export const { actions: getBaggageDetailsActions, reducer: getBaggageDetailsReducer } = getBaggageDetailsSlice
export const { resetGetBaggageDetails } = getBaggageDetailsActions
export default getBaggageDetailsSlice.reducer
