import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  GetOfferRequestsErrorResponse,
  GetOfferRequestsRequest,
  GetOfferRequestsSuccessResponse
} from './getOfferRequests.types'

export const getOfferRequestsApi = async (
  request: GetOfferRequestsRequest
): Promise<GetOfferRequestsSuccessResponse> => {
  const response = await configureRequest({
    url: urls.offers.viewOfferRequests,
    method: 'GET',
    params: {
      page: request.page ?? 1,
      size: request.size ?? 20,
      ...(request.searchQuery && { searchQuery: request.searchQuery })
    },
    headers: {
      'X-User-Role': UserRole.shopper
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetOfferRequestsSuccessResponse
  } else {
    throw response as GetOfferRequestsErrorResponse
  }
}

export default getOfferRequestsApi
