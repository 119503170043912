import theme from '../../styles/themes/default.theme'

const styles = {
  link: (showColor?: boolean, underline?: boolean) => ({
    color: showColor ? theme.palette.primary.main : theme.palette.grey[600],
    '&:visited': {
      color: theme.palette.primary.main
    },
    textDecoration: underline ? 'underline' : 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  })
}

export default styles
