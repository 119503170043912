import theme from '../../../shared/styles/themes/default.theme'

const styles = {
  accordionHeaderMainContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },

  accordionHeaderMainContentTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(1)
  },

  accordionHeaderMainContentTitleText: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'left'
  },

  tripTypeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(0.25, 1),
    backgroundColor: theme.palette.warning.light,
    borderRadius: theme.spacing(0.7)
  },

  tripTypeText: {
    fontSize: '0.6rem',
    color: theme.palette.warning.contrastText,
    fontWeight: theme.typography.fontWeightBold
  },

  accordionHeaderMainContentSubtitleText: {
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left'
  }
} as const

export default styles
