import React, { useEffect, useMemo, useState } from 'react'

import { InfoRounded } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'

import TripSummary from '../TripSummary/TripSummary.container'

import ShareFlightBookingLink from '../../shared/components/ShareFlightItineraryLink/ShareFlightItineraryLink.component'
import Stepper from '../../shared/components/Stepper/Stepper.component'
import FlightSearchWidget from '../Travel/components/FlightSearchWidget/FlightSearchWidget.component'
import OffersMultiSelect from './Stepper/OffersMultiSelect/OffersMultiSelect.component'
import TripSelector from './components/TripSelector/TripSelector.component'

import { FareOffer } from '../../redux/states/flights/getFlightFares/types/FareDetailsResponse.types'
import { OfferRequest } from '../../redux/states/offerRequest/getPublicOffers/getPublicOffers.types'
import { RootState } from '../../redux/store/store.types'
import router from '../../router/functions/router.functions'
import { TripPreferences } from '../../shared/components/TripPreferencesModal/TripPreferencesModal.types'
import date, { dateTimeFormats } from '../../shared/functions/Date/date.functions'
import { useAppSelector } from '../../shared/hooks/redux.hooks'
import {
  FlightSearchInitialValues,
  InitialFlight,
  TripType,
  cabinClassMapping
} from '../Travel/components/FlightSearchWidget/FlightSearchWidget.types'
import { formatFlightPartialOffers } from './FlightSearchResults.functions'
import styles from './FlightSearchResults.styles'
import { NavigationState } from './FlightSearchResults.types'
import { FormattedPartialOffers, Route } from './types/FormattedSearchResults.types'

const steps = ['Choose Flights', 'Select Offers', 'Trip Summary']

const FlightSearchResults: React.FC = () => {
  const { success: response, loading: isLoadingInitialFlightSearch } = useAppSelector(
    (state: RootState) => state.searchFlights
  )
  const [offers, setOffers] = useState<FormattedPartialOffers[]>([])
  const [routes, setRoutes] = useState<Route[]>([])
  const [activeStep, setActiveStep] = useState(0)
  const [fare, setFare] = useState<FareOffer>()
  const [tripPreferences, setTripPreferences] = useState<TripPreferences>()
  const [publicOffers, setPublicOffers] = useState<OfferRequest[]>([])

  const {
    requiredCountries,
    selectedOffer,
    flightSearchInitialValues: flightSearchInitialValuesFromRoute,
    existingFlightItinerary
  } = router.getNavigationProps().state as NavigationState

  const flightSearchInitialValues = useMemo(() => {
    if (flightSearchInitialValuesFromRoute) {
      return flightSearchInitialValuesFromRoute
    }

    if (!existingFlightItinerary) {
      return undefined
    }

    const flightSearchInitialValuesFromItinerary: FlightSearchInitialValues = {
      tripType: existingFlightItinerary.itinerary.tripType as TripType,
      flights:
        existingFlightItinerary.itinerary.tripType === 'roundTrip'
          ? ([
              {
                from: {
                  iata_code: existingFlightItinerary.itinerary.legs[0].origin.airport.iataCode,
                  name: existingFlightItinerary.itinerary.legs[0].origin.airport.name,
                  city: {
                    name: existingFlightItinerary.itinerary.legs[0].origin.city.name,
                    iata_code: existingFlightItinerary.itinerary.legs[0].origin.city.iataCode,
                    city_name: existingFlightItinerary.itinerary.legs[0].origin.city.name
                  },
                  country: {
                    name: existingFlightItinerary.itinerary.legs[0].origin.country.name,
                    iata_code: existingFlightItinerary.itinerary.legs[0].origin.country.iataCode
                  }
                },
                to: {
                  iata_code: existingFlightItinerary.itinerary.legs[0].destination.airport.iataCode,
                  name: existingFlightItinerary.itinerary.legs[0].destination.airport.name,
                  city: {
                    name: existingFlightItinerary.itinerary.legs[0].destination.city.name,
                    iata_code: existingFlightItinerary.itinerary.legs[0].destination.city.iataCode
                  },
                  country: {
                    name: existingFlightItinerary.itinerary.legs[0].destination.country.name,
                    iata_code: existingFlightItinerary.itinerary.legs[0].destination.country.iataCode
                  }
                },
                departure: existingFlightItinerary.itinerary.legs[0].travelDate,
                return: existingFlightItinerary.itinerary.legs[1].travelDate
              }
            ] as unknown as InitialFlight[])
          : (existingFlightItinerary.itinerary.legs.map((leg) => ({
              from: {
                iata_code: leg.origin.airport.iataCode,
                name: leg.origin.airport.name,
                city: {
                  name: leg.origin.city.name,
                  iata_code: leg.origin.city.iataCode,
                  city_name: leg.origin.city.name
                },
                country: {
                  name: leg.origin.country.name,
                  iata_code: leg.origin.country.iataCode
                }
              },
              to: {
                iata_code: leg.destination.airport.iataCode,
                name: leg.destination.airport.name,
                city: {
                  name: leg.destination.city.name,
                  iata_code: leg.destination.city.iataCode
                },
                country: {
                  name: leg.destination.country.name,
                  iata_code: leg.destination.country.iataCode
                }
              },
              departure: leg.travelDate,
              return: null
            })) as unknown as InitialFlight[]),
      cabinClass: cabinClassMapping[existingFlightItinerary.itinerary.cabinClass],
      adults: existingFlightItinerary.itinerary.passengers.filter((p) => p.type === 'adult').length,
      children: existingFlightItinerary.itinerary.passengers.filter((p) => p.type === 'child').length,
      childrenAges: existingFlightItinerary.itinerary.passengers
        .filter((p) => p.type === 'child' && p.age !== null)
        .map((p) => p.age as number)
    }

    return flightSearchInitialValuesFromItinerary
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingFlightItinerary, flightSearchInitialValuesFromRoute?.tripType])

  const [selectedOffers, setSelectedOffers] = useState<OfferRequest[]>([selectedOffer])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (response) {
      const { offers, routes } = formatFlightPartialOffers(response)

      setOffers(offers)
      setRoutes(routes)
    }
  }, [response])

  const handleStepClick = (step: number) => {
    if (step === 0) {
      setFare(undefined)
    }
    setActiveStep(step)
  }
  const searchFlightConfig = React.useMemo(() => {
    if (!requiredCountries) return null

    return {
      origin: requiredCountries.itemLocation.name,
      destination:
        `${requiredCountries.deliveryLocation.city || ''}, ${requiredCountries.deliveryLocation.name}`.trim(),
      neededByDate: requiredCountries.deliveryLocation.neededByDate
        ? date(requiredCountries.deliveryLocation.neededByDate).format(dateTimeFormats.date.medium)
        : null,
      neededByDateISO: requiredCountries.deliveryLocation.neededByDate
    }
  }, [requiredCountries])

  const onFetchedOffers = (offers: OfferRequest[]) => {
    setPublicOffers(offers)
  }

  const handleComplete = (fare: FareOffer, preferences: TripPreferences) => {
    setFare(fare)
    setTripPreferences(preferences)

    if (publicOffers.length > 1) {
      setActiveStep(1)
    } else {
      setActiveStep(2)
    }
  }

  const handleGotoTripSummary = (selectedOffers: OfferRequest[]) => {
    setSelectedOffers(selectedOffers)
    setActiveStep(2)
  }

  if (!requiredCountries) {
    return (
      <Grid container sx={styles.container}>
        <FlightSearchWidget
          isCompact={true}
          initialValues={flightSearchInitialValues}
          renewItinerary={existingFlightItinerary}
        />

        <Box sx={styles.tripSelectorContainer}>
          <Grid item xs={12} sm={9}>
            <TripSelector
              isLoadingInitialFlightSearch={isLoadingInitialFlightSearch}
              initialBaseOfferId={response?.data.id}
              offers={offers}
              routes={routes}
              searchedValues={flightSearchInitialValues}
              existingFlightItinerary={existingFlightItinerary}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <ShareFlightBookingLink />
          </Grid>
        </Box>
      </Grid>
    )
  } else {
    return (
      <Grid container sx={styles.container}>
        {requiredCountries && activeStep === 0 && (
          <FlightSearchWidget
            searchFlightConfig={searchFlightConfig}
            isCompact={true}
            requiredCountries={requiredCountries}
          />
        )}

        <Stepper
          stepperWidth="50%"
          sx={styles.stepper}
          activeStep={activeStep}
          steps={steps.map((step) => ({ label: step }))}
          showStepper
          showLeftArrow={true}
          showRightArrow={activeStep === 0 ? fare !== undefined : true}
          onStepClick={handleStepClick}
        >
          <Box sx={{ display: activeStep === 0 ? 'block' : 'none' }}>
            {(response || isLoadingInitialFlightSearch) && (
              <Grid sx={styles.tripSelectorContainer} item xs={12} sm={12}>
                <TripSelector
                  searchedValues={flightSearchInitialValues}
                  isLoadingInitialFlightSearch={isLoadingInitialFlightSearch}
                  initialBaseOfferId={response?.data.id}
                  offers={offers}
                  routes={routes}
                  onComplete={handleComplete}
                  existingFlightItinerary={existingFlightItinerary}
                />
              </Grid>
            )}

            {!response && !isLoadingInitialFlightSearch && (
              <Box sx={styles.requiredFlightsContainer}>
                <Box sx={styles.requiredFlightsItemsContainer}>
                  <Box sx={styles.iconContainer}>
                    <InfoRounded sx={styles.infoIcon} />
                  </Box>

                  <Typography variant="h5" sx={styles.requiredFlightsTitle}>
                    Search flights above
                  </Typography>

                  {searchFlightConfig && (
                    <Typography sx={styles.requiredFlightsSubtitle}>
                      You need to have a flight from <span style={styles.linkText}>{searchFlightConfig.origin}</span> to{' '}
                      <span style={styles.linkText}>{searchFlightConfig.destination}</span>{' '}
                      {searchFlightConfig.neededByDate ? (
                        <>
                          arriving by <span style={styles.linkText}>{searchFlightConfig.neededByDate}</span>
                        </>
                      ) : (
                        <>
                          arriving <span style={styles.linkText}>Anytime</span>
                        </>
                      )}
                      .
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
          </Box>

          <Box sx={{ display: activeStep === 1 ? 'block' : 'none' }}>
            <OffersMultiSelect
              initialSelectedOffers={selectedOffers}
              requiredCountries={requiredCountries}
              onGotoTripSummary={handleGotoTripSummary}
              onFetchedOffers={onFetchedOffers}
              maxDate={searchFlightConfig?.neededByDateISO}
            />
          </Box>

          {fare && tripPreferences && (
            <Box sx={{ display: activeStep === 2 ? 'block' : 'none' }}>
              <TripSummary
                searchedValues={flightSearchInitialValues}
                tripFare={fare}
                tripPreferences={tripPreferences}
                selectedOffers={selectedOffers}
                key={fare.id}
              />
            </Box>
          )}
        </Stepper>
      </Grid>
    )
  }
}

export default FlightSearchResults
