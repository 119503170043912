import { GetUpdatedFlightOfferState } from './getUpdatedFlightOffer.types'

const initialState: GetUpdatedFlightOfferState = {
  loading: false,
  error: null,
  success: null
  // Initialize any additional state properties here
}

export default initialState
