import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  CreateOfferRequestPaymentIntentErrorResponse,
  CreateOfferRequestPaymentIntentRequest,
  CreateOfferRequestPaymentIntentSuccessResponse
} from './createOfferRequestPaymentIntent.types'

export const createOfferRequestPaymentIntentApi = async (
  request: CreateOfferRequestPaymentIntentRequest
): Promise<CreateOfferRequestPaymentIntentSuccessResponse> => {
  const response = await configureRequest({
    url: urls.payments.createOfferRequestPaymentIntent,
    method: 'POST',
    data: request,
    headers: {
      'X-User-Role': UserRole.shopper
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as CreateOfferRequestPaymentIntentSuccessResponse
  } else {
    throw response as CreateOfferRequestPaymentIntentErrorResponse
  }
}

export default createOfferRequestPaymentIntentApi
