import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  AcceptItemRequestErrorResponse,
  AcceptItemRequestRequest,
  AcceptItemRequestSuccessResponse
} from './acceptItemRequest.types'

export const acceptItemRequestApi = async (
  request: AcceptItemRequestRequest
): Promise<AcceptItemRequestSuccessResponse> => {
  const response = await configureRequest({
    url: urls.offers.acceptItemRequest,
    method: 'POST',
    headers: {
      'X-User-Role': UserRole.traveler
    },
    params: {
      itemRequestId: request.itemRequestId
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as AcceptItemRequestSuccessResponse
  } else {
    throw response as AcceptItemRequestErrorResponse
  }
}

export default acceptItemRequestApi
