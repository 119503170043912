import React from 'react'

import { Box, CssBaseline } from '@mui/material'

import { firebaseRemoteConfigActions } from '../../redux/states/firebaseRemoteConfig/firebaseRemoteConfig.slice'
import routerFunctions from '../../router/functions/router.functions'
import router from '../../router/router'
import { Route } from '../../router/router.types'
import routes from '../../router/routes.dictionary'
import firebaseDictionary from '../../shared/functions/Firebase/firebase.dictionaries'
import firebase from '../../shared/functions/Firebase/firebase.functions'
import { ConfigKey } from '../../shared/functions/Firebase/firebase.types'
import logger from '../../shared/functions/Logger/logger.functions'
import { useAppDispatch } from '../../shared/hooks/redux.hooks'
import styles from './App.styles'

const App: React.FC = () => {
  const dispatch = useAppDispatch()
  const location = routerFunctions.getNavigationProps()

  // const currentRoute = Object.values(routes as Record<string, Route>).find(
  // Todo: Fix typings for TripSummaryProps
  const currentRoute = Object.values(routes as unknown as Record<string, Route>).find(
    (route) => `/${route.path}` === location.pathname
  )

  const isGreyPage = currentRoute?.isGreyBackground || false

  firebase.remoteConfig
    ?.refreshConfig()
    .then(() => {
      let newConfig = {}

      logger.logEvent('Remote config refreshed')

      for (const [key] of Object.entries(firebaseDictionary.remoteConfigVariables)) {
        const value = firebase.remoteConfig?.getConfigValue(key as ConfigKey)

        newConfig = {
          ...newConfig,
          [key]: value
        }
      }

      dispatch(firebaseRemoteConfigActions.setFirebaseRemoteConfig(newConfig))
    })
    .catch((error) => {
      logger.logError(error, 'Error refreshing Firebase Remote Config', 'refreshConfig')
    })

  return (
    <Box sx={styles.app}>
      <CssBaseline />

      <Box component="header" sx={styles.header({ isGreyPage })}>
        {router}
      </Box>
    </Box>
  )
}

export default App
