import React from 'react'

import { Link } from 'react-router-dom'

import routes from '../../router/routes.dictionary'

const Contact: React.FC = () => {
  return (
    <div>
      <h1>This is the contact page</h1>
      <Link to={routes.home.path}>{routes.home.name}</Link>
    </div>
  )
}

export default Contact
