import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  ResetForgottenPasswordErrorResponse,
  ResetForgottenPasswordRequest,
  ResetForgottenPasswordSuccessResponse
} from './resetForgottenPassword.types'

export const resetForgottenPasswordApi = async (
  request: ResetForgottenPasswordRequest
): Promise<ResetForgottenPasswordSuccessResponse> => {
  const response = await configureRequest({
    url: urls.auth.resetForgottenPassword,
    method: 'POST',
    data: request
  })

  if (response.status >= 200 && response.status < 300) {
    return response as ResetForgottenPasswordSuccessResponse
  } else {
    throw response as ResetForgottenPasswordErrorResponse
  }
}

export default resetForgottenPasswordApi
