import { ReactNode } from 'react'

import { CaseType } from './string.types'

/**
 * Truncates a string to a specified maximum length.
 * @param str - The string to truncate.
 * @param maxLength - The maximum length of the truncated string.
 * @returns The truncated string.
 * @example
 * truncateString('Hello, world!', 5) // "Hello..."
 */
export function truncateString(str: string, maxLength: number = 50): string {
  if (!str) {
    return ''
  } else if (str.length <= maxLength) {
    return str
  } else {
    return str.slice(0, maxLength - 3) + '...'
  }
}

/**
 * Converts a string to sentence case.
 * @param str - The string to convert.
 * @returns The sentence case string.
 * @example
 * sentenceCase('hello world') // "Hello World"
 */
export function sentenceCase(str: string): string {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

/**
 * Changes the case of a string.
 * @param text - The string to change the case of.
 * @param caseType - The type of case to change the string to.
 * @example
 * changeCase('hello world', 'sentence') // "Hello World"
 * changeCase('hello world!', 'title') // "Hello world!"
 * changeCase('HELLO WORLD', 'lower') // "hello world"
 * changeCase('hello world', 'upper') // "HELLO WORLD"
 * changeCase('hello world', 'snake') // "hello_world"
 * changeCase('hello world', 'camel') // "helloWorld"
 * changeCase('hello world', 'kebab') // "hello-world"
 * @returns The string with the changed case.
 */
export function updateCase(text: string, caseType: CaseType, sanitize: boolean = true): string {
  const textToTransform = sanitize ? sanitizeString(text) : text
  let transformedText = text

  switch (caseType) {
    case 'sentence':
      // Capitalize the first letter of each word and lowercase the rest
      transformedText = sentenceCase(textToTransform)
      break

    case 'title':
      // Capitalize the first letter of the first word only
      transformedText = textToTransform
        .split(' ')
        .map((word, i) => (i === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word.toLowerCase()))
        .join(' ')
      break

    case 'lower':
      transformedText = textToTransform.toLowerCase()
      break

    case 'upper':
      transformedText = textToTransform.toUpperCase()
      break

    case 'snake':
      transformedText = textToTransform.replace(/\s+/g, '_').toLowerCase()
      break

    case 'camel':
      transformedText = textToTransform.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) =>
        index === 0 ? letter.toLowerCase() : letter.toUpperCase()
      )
      break

    case 'kebab':
      transformedText = textToTransform.replace(/\s+/g, '-').toLowerCase()
      break
  }

  return transformedText
}

/**
 * Changes the case of a string.
 * @param text - The string to change the case of.
 * @param sanitize - Whether to sanitize the string.
 * @returns An object with the string in each case.
 */
export function changeCase(
  text: string,
  sanitize: boolean = true
): {
  sentence: string
  title: string
  lower: string
  upper: string
  snake: string
  camel: string
  kebab: string
} {
  return {
    /*
    Capitalize the first letter of each word and lowercase the rest
    */
    sentence: updateCase(text, 'sentence', sanitize),

    /*
    Capitalize the first letter of the first word only
    */
    title: updateCase(text, 'title', sanitize),

    /*
    Convert the string to lowercase
    */
    lower: updateCase(text, 'lower', sanitize),

    /*
    Convert the string to uppercase
    */
    upper: updateCase(text, 'upper', sanitize),

    /*
    Replace spaces with underscores and convert to lowercase
    */
    snake: updateCase(text, 'snake', sanitize),

    /*
    Convert the string to camel case
    */
    camel: updateCase(text, 'camel', sanitize),

    /*
    Replace spaces with dashes and convert to lowercase
    */
    kebab: updateCase(text, 'kebab', sanitize)
  }
}

/**
 * Sanitizes a string by replacing underscores and dashes with spaces.
 * @param text - The text to sanitize.
 * @returns The sanitized text.
 * @example
 * sanitizeString('hello_world') // "hello world"
 * sanitizeString('hello-world') // "hello world"
 */
export function sanitizeString(text: string): string {
  let sanitizedText = replaceUnderscoreWithSpace(text)
  sanitizedText = replaceDashWithSpace(sanitizedText)

  return sanitizedText
}

/**
 * Replaces underscores with spaces in a string.
 * @param text - The text to replace underscores in.
 * @returns The text with underscores replaced with spaces.
 * @example
 * replaceUnderscoreWithSpace('hello_world') // "hello world"
 */
export function replaceUnderscoreWithSpace(text: string): string {
  return text.replace(/_/g, ' ')
}

/**
 * Replaces dashes with spaces in a string.
 * @param text - The text to replace dashes in.
 * @returns The text with dashes replaced with spaces.
 * @example
 * replaceDashWithSpace('hello-world') // "hello world"
 */
export function replaceDashWithSpace(text: string): string {
  return text.replace(/-/g, ' ')
}

/**
 * Joins an array of strings with a bullet.
 * @param strings - The array of strings to join.
 * @returns The joined string.
 * @example
 * joinStringWithBullet(['Hello', 'World']) // "Hello • World"
 */
export function joinStringWithBullet(strings: (string | ReactNode)[]): ReactNode | string {
  if (!strings.length) return ''

  return strings.reduce((prev, curr) => [prev, '\u00A0 • \u00A0', curr])
}

/**
 * Joins an array of strings with a dash.
 * @param strings - The array of strings to join.
 * @returns The joined string.
 * @example
 * joinStringWithDash(['Hello', 'World']) // "Hello - World"
 */
export function joinStringWithDash(strings: (string | ReactNode)[]): ReactNode | string {
  if (!strings.length) return ''

  return strings.reduce((prev, curr) => [prev, '\u00A0 - \u00A0', curr])
}

/**
 * Sanitizes a phone number by removing all non-numeric characters except for '+'.
 * @param phoneNumber - The phone number to sanitize.
 * @returns The sanitized phone number.
 * @example
 * sanitizePhoneNumber("+1 876 307 1239") // "+18763071239"
 */
export function sanitizePhoneNumber(phoneNumber: string | undefined): string | undefined {
  return phoneNumber ? phoneNumber.replace(/[^0-9+]/g, '') : undefined
}

/**
 * Formats a number as a currency string.
 * @param price - The number to format.
 * @returns The formatted string.
 * @example
 * formatMoney(123.45334) // "$123.45"
 */
export function formatMoney(price: number | string | undefined): string {
  return price ? Number(price).toFixed(2) : '0.00'
}
