import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  GetNextFlightsErrorResponse,
  GetNextFlightsRequest,
  GetNextFlightsSuccessResponse
} from './getNextFlights.types'

export const getNextFlightsApi = async (request: GetNextFlightsRequest): Promise<GetNextFlightsSuccessResponse> => {
  const response = await configureRequest({
    url: urls.flights.searchNext,
    method: 'GET',
    params: {
      outboundOfferId: request.outboundOfferId,
      selected_partial_offer: request.selected_partial_offers
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetNextFlightsSuccessResponse
  } else {
    throw response as GetNextFlightsErrorResponse
  }
}

export default getNextFlightsApi
