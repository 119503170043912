import React from 'react'

import { Box, Divider, Typography } from '@mui/material'

import date from '../../functions/Date/date.functions'
import styles from './TripLocationsWidget.styles'
import { TripLocationsWidgetProps } from './TripLocationsWidget.types'

const TripLocationsWidget: React.FC<TripLocationsWidgetProps> = (props) => {
  const { airlineLogo, origin, destination, departureTime, arrivalTime } = props

  return (
    <Box sx={styles.container}>
      <Box sx={styles.airlineInfo}>
        <img src={airlineLogo} alt="Airline Logo" style={styles.logo} />

        <Box sx={styles.departureContainer}>
          <Typography variant="h6" sx={styles.airportName}>
            {origin.city ? `${origin.city} (${origin.airportIataCode})` : origin.airportIataCode}
          </Typography>

          <Typography variant="body2" sx={styles.time}>
            {date(departureTime).format('h:mm a')}
          </Typography>
        </Box>
      </Box>

      <Divider sx={styles.separator} />

      <Box sx={styles.arrivalContainer}>
        <Typography variant="h6" sx={styles.airportName}>
          {destination.city ? `${destination.city} (${destination.airportIataCode})` : destination.airportIataCode}
        </Typography>

        <Typography variant="body2" sx={styles.time}>
          {date(arrivalTime).format('h:mm a')}
        </Typography>
      </Box>
    </Box>
  )
}

export default TripLocationsWidget
