import React from 'react'

import { Box, Typography } from '@mui/material'

import { getFormattedTravelerDateDetails, getFormattedTripDetails } from '../../../pages/Trips/Trips.functions'
import styles from './FlightItineraryHeader.styles'
import { FlightItineraryHeaderProps } from './FlightItineraryHeader.types'

const FlightItineraryHeader: React.FC<FlightItineraryHeaderProps> = ({ flightItinerary }) => {
  const locationsDetails = getFormattedTripDetails(flightItinerary)
  const travelerDateDetails = getFormattedTravelerDateDetails(flightItinerary)
  const tripType = flightItinerary.itinerary.tripType
  const formattedTripType = tripType === 'roundTrip' ? 'Round Trip' : tripType === 'oneWay' ? 'One Way' : 'Multi-City'

  return (
    <Box sx={styles.accordionHeaderMainContent}>
      <Box sx={styles.accordionHeaderMainContentTitleContainer}>
        <Typography sx={styles.accordionHeaderMainContentTitleText}>{locationsDetails}</Typography>

        <Box sx={styles.tripTypeContainer}>
          <Typography sx={styles.tripTypeText}>{formattedTripType}</Typography>
        </Box>
      </Box>

      <Typography sx={styles.accordionHeaderMainContentSubtitleText}>{travelerDateDetails}</Typography>
    </Box>
  )
}

export default FlightItineraryHeader
