import { CSSProperties } from 'react'

import { SxProps } from '@mui/system'

import theme from '../../styles/themes/default.theme'
import { ButtonType } from './Button.types'

const styles = {
  buttonContainer: (fullWidth: boolean): SxProps => ({
    display: 'flex',
    flex: fullWidth ? 1 : 'none',
    width: fullWidth ? '100%' : 'auto',
    justifyContent: 'center',
    alignItems: 'center'
  }),

  button: (
    isNav?: boolean,
    isActiveNav?: boolean,
    large?: boolean,
    marginTop?: string,
    outline?: boolean,
    inverted?: boolean,
    buttonType?: ButtonType
  ): SxProps =>
    ({
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
      marginTop: marginTop,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      ...(large && {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        fontSize: '24px'
      }),
      textTransform: 'capitalize',
      boxShadow: theme.shadows[0],
      '&:hover': {
        boxShadow: theme.shadows[0],
        ...(isNav && {
          backgroundColor: theme.palette.tertiary.main,
          color: theme.palette.primary.main
        })
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.grey[300]
      },
      ...(isNav && {
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.grey[800],
        ...(isActiveNav && {
          backgroundColor: theme.palette.tertiary.main,
          color: theme.palette.primary.main
        })
      }),
      ...(outline && {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        '&:hover': {
          borderColor: theme.palette.primary.dark,
          color: theme.palette.primary.dark
        },
        '&.Mui-disabled': {
          borderColor: theme.palette.grey[300],
          color: theme.palette.grey[300]
        }
      }),
      ...(buttonType === 'quaternary' && {
        borderColor: theme.palette.quaternary.main,
        color: theme.palette.quaternary.main,
        '&:hover': {
          borderColor: theme.palette.quaternary.dark,
          color: theme.palette.quaternary.dark
        },
        '&.Mui-disabled': {
          borderColor: theme.palette.grey[300],
          color: theme.palette.grey[300]
        }
      }),
      ...(inverted && {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.contrastText,
        boxShadow:
          '0px -1px 2px 0px rgba(0,0,0,0.06), 0px 1px 2px 0px rgba(0,0,0,0.06), 0px 1px 3px 0px rgba(0,0,0,0.10)',
        '&:hover': {
          borderColor: theme.palette.primary.dark,
          color: theme.palette.primary.dark,
          backgroundColor: theme.palette.primary.contrastText,
          boxShadow:
            '0px -1px 3px 0px rgba(0,0,0,0.10), 0px 1px 3px 0px rgba(0,0,0,0.10), 0px 1px 2px 0px rgba(0,0,0,0.06)'
        }
      })
    }) as SxProps,

  disabledTertiary: {
    backgroundColor: theme.palette.grey[300]
  } as CSSProperties,

  iconWrapper: (animate: boolean): SxProps => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'transform 0.5s',
    ...(animate && {
      transform: 'rotate(180deg)'
    })
  }),

  loading: {
    marginLeft: '10px'
  }
}

export default styles
