import theme from '../../styles/themes/default.theme'

export default {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },

  divider: {
    display: 'flex',
    width: '100%'
  },

  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },

  trackingDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%'
  },

  trackingDetailsTitle: {
    fontSize: theme.typography.body1.fontSize,
    paddingBottom: theme.spacing(3)
  },

  trackingDetailsStepperContainer: {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    width: '100%'
  },

  trackingStatusActionContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'flex-start'
  },

  trackingDetailsFormContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(2)
  },

  trackingDetailsFormInput: {
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 2)
    }
  },

  trackingDetailsFormButton: {
    display: 'flex',
    alignSelf: 'flex-start',
    padding: theme.spacing(0, 2),
    marginLeft: theme.spacing(2),
    height: theme.spacing(5)
  },

  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2
  }
}
