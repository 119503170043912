import { FlightItinerary } from '../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'

export const getFormattedTripDetails = (flightItinerary: FlightItinerary): string => {
  const firstLeg = flightItinerary.itinerary.legs[0]

  const tripType = flightItinerary.itinerary.tripType
  let tripDetails = ''

  switch (tripType) {
    case 'oneWay':
      tripDetails = `${firstLeg.origin.airport.iataCode} → ${firstLeg.destination.airport.iataCode}`
      break

    case 'roundTrip':
      tripDetails = `${firstLeg.origin.airport.iataCode} ⇌ ${firstLeg.destination.airport.iataCode}`
      break

    case 'multiCity':
      tripDetails = flightItinerary.itinerary.legs
        .map((leg) => `${leg.origin.airport.iataCode} → ${leg.destination.airport.iataCode}`)
        .join('\u00A0 • \u00A0')
      break
  }

  return tripDetails
}
