import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  CreateOfferRequestErrorResponse,
  CreateOfferRequestRequest,
  CreateOfferRequestSuccessResponse
} from './createOfferRequest.types'

export const createOfferRequestApi = async (
  request: CreateOfferRequestRequest
): Promise<CreateOfferRequestSuccessResponse> => {
  const response = await configureRequest({
    url: urls.offers.createOfferRequest,
    method: 'POST',
    data: request,
    headers: {
      'X-User-Role': UserRole.shopper
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as CreateOfferRequestSuccessResponse
  } else {
    throw response as CreateOfferRequestErrorResponse
  }
}

export default createOfferRequestApi
