import React from 'react'

export interface CarouselProps {
  items: React.ReactNode[]
  associatedContent?: React.ReactNode[]
  showStepper?: boolean
  showDotStepper?: boolean
  autoCycle?: boolean
  autoCycleDuration?: AutoCycleDuration
  animation?: AnimationType
  onStepChange?: (step: number) => void
}

export enum AutoCycleDuration {
  SHORT = 5000,
  MEDIUM = 10000,
  LONG = 20000
}

export enum AnimationType {
  FADE = 'fade',
  SLIDE = 'slide'
}
