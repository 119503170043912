import React, { forwardRef, useEffect, useImperativeHandle } from 'react'

import { Box, Typography } from '@mui/material'
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import {
  Appearance,
  StripeElementsOptions,
  StripeError,
  StripePaymentElementOptions,
  loadStripe
} from '@stripe/stripe-js'

import env from '../../../networkRequests/apiClient/env.config'
import logger from '../../functions/Logger/logger.functions'
import theme from '../../styles/themes/default.theme'
import Snackbar from '../Snackbar/Snackbar.functions'
import { SnackbarAutoHideDuration } from '../Snackbar/Snackbar.types'
import styles from './StripeCheckoutForm.styles'
import { PaymentFormProps, PaymentFormRef, StripeCheckoutFormProps } from './StripeCheckoutForm.types'

const stripePromise = loadStripe(env.STRIPE_PUBLISHABLE_KEY)

const PaymentForm = forwardRef<PaymentFormRef, PaymentFormProps>((props: PaymentFormProps, ref) => {
  const {
    paymentIntentClientSecret,
    setIsPaymentFormValid,
    setPaymentRequestLoading,
    setSetupPaymentLoading,
    mode,
    redirectPath,
    showTitle = false,
    setIsFormLoaded,
    setHasFormBeenInteractedWith
  } = props

  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    if (stripe && elements) {
      setIsPaymentFormValid(true)
    }
  }, [stripe, elements, setIsPaymentFormValid])

  const handleError = (error: StripeError) => {
    setPaymentRequestLoading(false)

    logger.logError(error, 'Stripe form submission error', 'StripeCheckoutForm', { mode })

    Snackbar.show({
      message: error.message || 'An unexpected error occurred.',
      severity: 'error',
      autoHideDuration: SnackbarAutoHideDuration.EXTENDED
    })
  }

  const handleSubmit = async (event?: React.FormEvent) => {
    if (event) event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setPaymentRequestLoading(true)

    // Trigger form validationa and wallet collection
    const { error: submitError } = await elements.submit()

    if (submitError) {
      logger.logError(submitError, 'Stripe form validation error', 'StripeCheckoutForm', { mode })
    }

    if (mode === 'setup' && paymentIntentClientSecret) {
      setSetupPaymentLoading && setSetupPaymentLoading(true)

      try {
        const { error } = await stripe.confirmSetup({
          elements,
          clientSecret: paymentIntentClientSecret,
          confirmParams: {
            return_url: `${env.WEB_APP_URL}/${redirectPath}`
          }
        })

        setSetupPaymentLoading && setSetupPaymentLoading(false)

        if (error) {
          handleError(error)
        }
      } catch (error) {
        handleError(error as StripeError)
      }
    } else {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${env.WEB_APP_URL}/${redirectPath}`
        }
      })

      if (error) {
        handleError(error)
      }
    }

    setPaymentRequestLoading(false)
  }

  useImperativeHandle(ref, () => ({
    submitPayment: handleSubmit
  }))

  const getFormTitle = () => {
    let title = null

    if (showTitle) {
      if (mode === 'setup') {
        title = 'Payment Methods'
      } else {
        title = 'Payment Details'
      }

      return (
        <Typography variant="h6" sx={styles.formTitle}>
          {title}
        </Typography>
      )
    } else {
      return null
    }
  }

  const handlePaymentElementReady = () => {
    setIsFormLoaded && setIsFormLoaded(true)
  }

  const handlePaymentElementFocus = () => {
    setHasFormBeenInteractedWith && setHasFormBeenInteractedWith(true)
  }

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'tabs'
  }

  return (
    <Box sx={styles.formContainer} id="stripe-checkout-form-box">
      {getFormTitle()}

      <form onSubmit={handleSubmit} id="stripe-checkout-form" style={styles.form}>
        <PaymentElement
          id="payment-element"
          options={paymentElementOptions}
          onReady={handlePaymentElementReady}
          onFocus={handlePaymentElementFocus}
        />
      </form>
    </Box>
  )
})

const StripeCheckoutForm: React.FC<StripeCheckoutFormProps> = (props: StripeCheckoutFormProps) => {
  const {
    paymentIntentClientSecret,
    customerSessionClientSecret,
    setIsPaymentFormValid,
    setPaymentRequestLoading,
    setSetupPaymentLoading,
    paymentFormRef,
    mode,
    redirectPath,
    showTitle,
    setIsFormLoaded,
    setHasFormBeenInteractedWith
  } = props

  const appearance: Appearance = {
    theme: 'flat',
    variables: {
      borderRadius: '10px',
      colorPrimary: theme.palette.primary.main
    }
  }

  // TODO: Investigate why we can't see the saved payment methods when doing a payment but see it when doing a setup
  const options: StripeElementsOptions = {
    clientSecret: paymentIntentClientSecret,
    customerSessionClientSecret: customerSessionClientSecret,
    appearance
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentForm
        mode={mode}
        paymentIntentClientSecret={paymentIntentClientSecret}
        setIsPaymentFormValid={setIsPaymentFormValid}
        setPaymentRequestLoading={setPaymentRequestLoading}
        setSetupPaymentLoading={setSetupPaymentLoading}
        ref={paymentFormRef}
        redirectPath={redirectPath}
        showTitle={showTitle}
        setIsFormLoaded={setIsFormLoaded}
        setHasFormBeenInteractedWith={setHasFormBeenInteractedWith}
      />
    </Elements>
  )
}

export default StripeCheckoutForm
