import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  GetFlightPlacesErrorResponse,
  GetFlightPlacesRequest,
  GetFlightPlacesSuccessResponse
} from './getFlightPlaces.types'

export const getFlightPlacesApi = async (request: GetFlightPlacesRequest): Promise<GetFlightPlacesSuccessResponse> => {
  const response = await configureRequest({
    url: urls.flights.getFlightPlaces,
    method: 'GET',
    params: {
      name: request.searchQuery,
      allResults: request.allResults?.toString() || 'false'
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetFlightPlacesSuccessResponse
  } else {
    throw response as GetFlightPlacesErrorResponse
  }
}

export default getFlightPlacesApi
