import theme from '../../../../shared/styles/themes/default.theme'

export default {
  stepLabel: (props: { active?: boolean; completed?: boolean }) => ({
    width: 24,
    height: 24,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: props.active
      ? theme.palette.primary.main
      : props.completed
        ? theme.palette.success.main
        : 'transparent'
  })
}
