import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { getNextFlightsApi } from './getNextFlights.api'
import getNextFlightsInitialState from './getNextFlights.initialState'
import { GetNextFlightsErrorResponse, GetNextFlightsRequest } from './getNextFlights.types'

export const getNextFlights = createAsyncThunk(
  urls.flights.searchNext,
  async (
    { getNextFlightsRequest, onSuccess }: { getNextFlightsRequest: GetNextFlightsRequest; onSuccess: () => void },
    { rejectWithValue }
  ) => {
    try {
      const response = await getNextFlightsApi(getNextFlightsRequest)

      onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const getNextFlightsSlice = createSlice({
  name: urls.flights.search,
  initialState: getNextFlightsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNextFlights.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getNextFlights.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getNextFlights.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetNextFlightsErrorResponse
        state.success = null
      })
  }
})

export const { actions: getNextFlightsActions, reducer: getNextFlightsReducer } = getNextFlightsSlice
export default getNextFlightsSlice.reducer
