import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { getFlightPlacesApi } from './getFlightPlaces.api'
import getFlightPlacesInitialState from './getFlightPlaces.initialState'
import { FlightPlace, GetFlightPlacesErrorResponse, GetFlightPlacesRequest } from './getFlightPlaces.types'

export const getFlightPlaces = createAsyncThunk(
  urls.flights.getFlightPlaces,
  async (
    {
      getFlightPlacesRequest,
      onSuccess
    }: { getFlightPlacesRequest: GetFlightPlacesRequest; onSuccess: (data: FlightPlace[]) => void },
    { rejectWithValue }
  ) => {
    try {
      const response = await getFlightPlacesApi(getFlightPlacesRequest)

      onSuccess(response.data)

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const getFlightPlacesSlice = createSlice({
  name: urls.flights.getFlightPlaces,
  initialState: getFlightPlacesInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFlightPlaces.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(getFlightPlaces.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(getFlightPlaces.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as GetFlightPlacesErrorResponse
        state.success = null
      })
  }
})

export const { actions: getFlightPlacesActions, reducer: getFlightPlacesReducer } = getFlightPlacesSlice
export default getFlightPlacesSlice.reducer
