import React, { useState } from 'react'

import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Container, IconButton } from '@mui/material'
import { FormikProps } from 'formik'
import * as Yup from 'yup'

import Form from '../../shared/components/Form/Form.component'

import { changePassword } from '../../redux/states/auth/changePassword/changePassword.slice'
import { ChangePasswordRequest } from '../../redux/states/auth/changePassword/changePassword.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import { FormField } from '../../shared/components/Form/Form.types'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import styles from './ChangePassword.styles'
import { ChangePasswordFormValues } from './ChangePassword.types'

const ChangePassword: React.FC = () => {
  const { loading } = useAppSelector((state) => state.changePassword)

  const [formikInstance, setFormikInstance] = useState<FormikProps<ChangePasswordFormValues>>(
    {} as FormikProps<ChangePasswordFormValues>
  )
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const dispatch = useAppDispatch()
  const navigate = router.navigate()

  const initialValues: ChangePasswordFormValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  }

  const validationSchema = Yup.object({
    currentPassword: Yup.string().required('Required'),
    newPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[A-Z]/, 'Password must contain one uppercase letter')
      .matches(/[a-z]/, 'Password must contain one lowercase letter')
      .matches(/[0-9]/, 'Password must contain one number')
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match')
      .required('Required')
  })

  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword)
  }

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword)
  }

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const handleBlur = () => {
    setShowCurrentPassword(false)
    setShowNewPassword(false)
    setShowConfirmPassword(false)
  }

  const onSubmit = (values: ChangePasswordFormValues) => {
    const changePasswordRequest: ChangePasswordRequest = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
      confirmNewPassword: values.confirmPassword
    }

    const onSuccess = () => {
      navigate(routes.profile.path, { replace: true })
    }

    dispatch(changePassword({ changePasswordRequest, onSuccess }))
  }

  const formFields: FormField[] = [
    {
      name: 'currentPassword',
      label: 'Current Password',
      type: showCurrentPassword ? 'text' : 'password',
      autoComplete: 'current-password',
      placeholder: 'Enter your current password',
      grid: { xs: 12 },
      required: true,
      fullWidth: true,
      onBlur: handleBlur,
      endAdornment: (
        <IconButton onMouseDown={(e) => e.preventDefault()} onClick={toggleCurrentPasswordVisibility}>
          {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      )
    },
    {
      name: 'newPassword',
      label: 'New Password',
      type: showNewPassword ? 'text' : 'password',
      autoComplete: 'new-password',
      placeholder: 'Enter your new password',
      grid: { xs: 12 },
      required: true,
      fullWidth: true,
      onBlur: handleBlur,
      helperText:
        (formikInstance?.touched?.newPassword && !!formikInstance?.errors?.newPassword) ||
        (!!formikInstance?.values?.newPassword && formikInstance?.errors?.newPassword)
          ? 'Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, and one number.'
          : '',
      endAdornment: (
        <IconButton onMouseDown={(e) => e.preventDefault()} onClick={toggleNewPasswordVisibility}>
          {showNewPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      )
    },
    {
      name: 'confirmPassword',
      label: 'Confirm Password',
      type: showConfirmPassword ? 'text' : 'password',
      autoComplete: 'new-password',
      placeholder: 'Confirm your new password',
      grid: { xs: 12 },
      required: true,
      fullWidth: true,
      onBlur: handleBlur,
      endAdornment: (
        <IconButton onMouseDown={(e) => e.preventDefault()} onClick={toggleConfirmPasswordVisibility}>
          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      )
    }
  ]

  return (
    <Container component="main" maxWidth="sm" sx={styles.container}>
      <Form
        title="Change Password"
        subtitle="Enter your current and new password to continue."
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values: unknown) => onSubmit(values as ChangePasswordFormValues)}
        onFormChange={(formik: Partial<FormikProps<unknown>>) =>
          setFormikInstance(formik as FormikProps<ChangePasswordFormValues>)
        }
        formFields={formFields}
        loading={loading}
        buttonText="Submit"
      />
    </Container>
  )
}

export default ChangePassword
