import { Step } from '../../shared/components/Stepper/Stepper.types'

export const FLIGHT_BOOKING_SUBMISSION_STORAGE_KEY = 'flight_booking_submission'
export const CREATE_FLIGHT_BOOKING_PAYMENT_INTENT_STORAGE_KEY = 'CREATE_FLIGHT_BOOKING_PAYMENT_INTENT'
export const ACCOUNT_SESSION_STORAGE_KEY = 'ACCOUNT_SESSION'
export const FARE_OFFER_STORAGE_KEY = 'FARE_OFFER'

export const steps: Step[] = [
  { label: 'Choose Flights' },
  { label: 'Passenger and Seat Details' },
  { label: 'Payment' },
  { label: 'Booking Confirmation' }
]
