import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  CreateFlightBookingErrorResponse,
  CreateFlightBookingRequest,
  CreateFlightBookingSuccessResponse
} from './createFlightBooking.types'

export const createFlightBookingApi = async (
  request: CreateFlightBookingRequest
): Promise<CreateFlightBookingSuccessResponse> => {
  const response = await configureRequest({
    url: urls.flights.createFlightBooking,
    method: 'POST',
    data: request,
    headers: {
      'X-User-Role': UserRole.traveler
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as CreateFlightBookingSuccessResponse
  } else {
    throw response as CreateFlightBookingErrorResponse
  }
}

export default createFlightBookingApi
