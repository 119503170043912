import { createSlice } from '@reduxjs/toolkit'

import firebaseRemoteConfigInitialState from './firebaseRemoteConfig.initialState'
import { FirebaseRemoteConfigValues } from './firebaseRemoteConfig.types'

const firebaseRemoteConfigSlice = createSlice({
  name: 'firebaseRemoteConfig',
  initialState: firebaseRemoteConfigInitialState,
  reducers: {
    setFirebaseRemoteConfig: (state, action) => {
      state.values = action.payload as FirebaseRemoteConfigValues
    }
  }
})

export const { actions: firebaseRemoteConfigActions, reducer: firebaseRemoteConfigReducer } = firebaseRemoteConfigSlice
export default firebaseRemoteConfigSlice.reducer
