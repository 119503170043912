import React from 'react'

import { Box, Divider, Grid, TextField, Typography, TypographyProps } from '@mui/material'

import Button from '../Button/Button.component'
import Link from '../Link/Link.component'

import images from '../../../assets/images'
import env from '../../../networkRequests/apiClient/env.config'
import routes from '../../../router/routes.dictionary'
import date from '../../functions/Date/date.functions'
import Modal from '../Modal/Modal.functions'
import styles from './Footer.styles'

const Footer: React.FC = () => {
  const openCustomerSupportModal = () => {
    Modal.show({
      title: 'Customer Support',
      body: `Having trouble? We are here to help!\n\nEmail us at ${env.SUPPORT_EMAIL_ADDRESS} and we will get back to you as soon as possible.`,
      primaryButton: {
        label: 'Send Message',
        onClick: () => {
          window.location.href = `mailto:${env.SUPPORT_EMAIL_ADDRESS}`
        }
      },
      secondaryButton: {
        label: 'Dismiss'
      }
    })
  }

  const footerSections = [
    {
      title: '',
      items: [<img src={images.hourrierLogoWithText} alt="Hurrier Logo" style={styles.hourrierLogo} />]
    },
    {
      title: 'Make & Save Money',
      items: [
        // <Link href={routes.howHourrierWorks.path}>How Hurrier Works</Link>,
        // <Link href={routes.flightDeals.path}>Flight Deals</Link>,
        <Link href={routes.shop.path}>Make an Offer</Link>
        // <Link href={routes.hourrierExperience.path}>The Hurrier Experience</Link>,
        // <Link href={routes.aboutUs.path}>About Us</Link>
      ]
    },
    {
      title: 'Help & Support',
      items: [
        // <Link href={routes.helpCenter.path}>Help Center</Link>,
        <Link onClick={() => openCustomerSupportModal()}>Customer Support</Link>,
        <Link onClick={() => openCustomerSupportModal()}>Contact Us</Link>
      ]
    },
    {
      title: 'Good to Know',
      isHidden: true,
      items: [
        <Link href={routes.termsOfServices.path}>Terms of Services</Link>,
        <Link href={routes.trustAndSafety.path}>Trust & Safety</Link>,
        <Link href={routes.privacyPolicy.path}>Privacy Policy</Link>,
        <Link href={routes.deliveryPolicy.path}>Delivery Policy</Link>,
        <Link href={routes.cookiePolicy.path}>Cookie Policy</Link>
      ]
    },
    {
      title: 'Vibe With Us!',
      isHidden: true,
      items: [
        <Link href="https://www.instagram.com">
          <img src={images.instagramLogo} alt="Instagram" style={styles.icon} />
          Instagram
        </Link>,

        <Link href="https://www.tiktok.com">
          <img src={images.tiktokLogo} alt="Tiktok" style={styles.icon} />
          Tiktok
        </Link>,

        <Link href="https://www.x.com">
          <img src={images.xLogo} alt="X" style={styles.icon} />
          X.com
        </Link>,

        <Link href="https://www.facebook.com">
          <img src={images.facebookLogo} alt="Facebook" style={styles.icon} />
          Facebook
        </Link>
      ]
    },
    {
      title: 'Join Community',
      isHidden: true,
      items: [
        <Typography>Subscribe to Hurrier and get updates on flight deals and more.</Typography>,
        <Box sx={styles.form}>
          <TextField label="Your email" variant="standard" size="small" sx={styles.textField} />
          <Button buttonType="primary" text="Subscribe" fullWidth />
        </Box>
      ]
    }
  ]

  function Copyright(props: TypographyProps) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {`© ${date().year} `}
        <Link style={styles.link} href={env.WEB_APP_URL}>
          {env.BUSINESS_NAME}
        </Link>{' '}
      </Typography>
    )
  }

  return (
    <Box component="footer" sx={styles.footer}>
      <Box sx={styles.container}>
        <Grid container spacing={4}>
          {footerSections.map(
            (section, index) =>
              !section.isHidden && (
                <Grid item xs={6} sm={4} lg={2} key={index}>
                  {section.title && (
                    <Typography variant="h6" sx={styles.title}>
                      {section.title}
                    </Typography>
                  )}
                  {section.items.map((item, idx) => (
                    <Box key={idx} style={styles.sectionItemContainer}>
                      {item}
                    </Box>
                  ))}
                </Grid>
              )
          )}
        </Grid>
      </Box>

      <Divider />

      <Box sx={styles.copyright} textAlign="center">
        <Copyright />
      </Box>
    </Box>
  )
}

export default Footer
