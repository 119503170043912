import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { acceptItemRequestApi } from './acceptItemRequest.api'
import acceptItemRequestInitialState from './acceptItemRequest.initialState'
import { AcceptItemRequestErrorResponse, AcceptItemRequestRequestPayload } from './acceptItemRequest.types'

export const acceptItemRequest = createAsyncThunk(
  urls.offers.acceptItemRequest,
  async (payload: AcceptItemRequestRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await acceptItemRequestApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const acceptItemRequestSlice = createSlice({
  name: urls.offers.acceptItemRequest,
  initialState: acceptItemRequestInitialState,
  reducers: {
    // Add any additional reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(acceptItemRequest.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(acceptItemRequest.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(acceptItemRequest.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as AcceptItemRequestErrorResponse
        state.success = null
      })
  }
})

export const { actions: acceptItemRequestActions, reducer: acceptItemRequestReducer } = acceptItemRequestSlice
export default acceptItemRequestSlice.reducer
