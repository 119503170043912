import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(5, 10),
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`
  } as SxProps,

  inputContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1, 1.75),
    borderRadius: 1,
    border: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: theme.palette.background.paper,
    minHeight: '50px',
    width: '100%',

    '&:focus-within': {
      borderColor: theme.palette.primary.main
    },

    '&:hover:not(:focus-within)': {
      borderColor: theme.palette.grey[300]
    }
  },

  input: {
    flex: 1,
    '& .MuiInputBase-input': {
      padding: theme.spacing(0.25, 0)
    }
  },

  searchButton: {
    display: 'flex',
    alignSelf: 'flex-start',
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    height: '50px'
  } as CSSProperties,

  clearButton: {
    height: '25px',
    width: '25px',
    marginRight: theme.spacing(0)
  } as SxProps,

  clearIcon: {
    height: '24px',
    width: '24px'
  } as SxProps
}

export default styles
