import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { releaseOfferRequestApi } from './releaseOfferRequest.api'
import releaseOfferRequestInitialState from './releaseOfferRequest.initialState'
import { ReleaseOfferRequestErrorResponse, ReleaseOfferRequestRequestPayload } from './releaseOfferRequest.types'

export const releaseOfferRequest = createAsyncThunk(
  urls.offers.releaseOfferRequest,
  async (payload: ReleaseOfferRequestRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess, onError } = payload

    try {
      const response = await releaseOfferRequestApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)
      onError && onError()

      return rejectWithValue(error)
    }
  }
)

const releaseOfferRequestSlice = createSlice({
  name: urls.offers.releaseOfferRequest,
  initialState: releaseOfferRequestInitialState,
  reducers: {
    resetReleaseOfferRequest: (state) => {
      state.loading = false
      state.error = null
      state.success = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(releaseOfferRequest.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(releaseOfferRequest.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(releaseOfferRequest.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as ReleaseOfferRequestErrorResponse
        state.success = null
      })
  }
})

export const { actions: releaseOfferRequestActions, reducer: releaseOfferRequestReducer } = releaseOfferRequestSlice
export const { resetReleaseOfferRequest } = releaseOfferRequestActions
export default releaseOfferRequestSlice.reducer
