import theme from '../../../shared/styles/themes/default.theme'

export default {
  filterSection: {
    marginBottom: theme.spacing(2)
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  sectionTitle: {
    fontWeight: 'bold'
  },

  resetButton: {
    fontSize: theme.typography.body2.fontSize,
    cursor: 'pointer'
  },

  select: {
    backgroundColor: theme.palette.grey[100],
    textAlign: 'left'
  }
}
