import { ApiErrorResponse, ApiSuccessResponse } from '../../../../shared/types/api.types'
import { SliceActions } from '../../../../shared/types/slice.types'
import { getItemRequestTrackingDetailsActions } from './getItemRequestTrackingDetails.slice'

export interface GetItemRequestTrackingDetailsRequest {
  itemRequestId: string
}

export interface GetItemRequestTrackingDetailsRequestPayload {
  request: GetItemRequestTrackingDetailsRequest
  onSuccess?: () => void
}

export interface GetItemRequestTrackingDetailsSuccessResponse
  extends ApiSuccessResponse<GetItemRequestTrackingDetailsData> {}

export interface GetItemRequestTrackingDetailsErrorResponse extends ApiErrorResponse {}

export interface GetItemRequestTrackingDetailsData {
  itemRequestId: number
  externalTrackingNumber: string
  trackingDetails: TrackingDetails
}
export enum TrackingDetailsKeys {
  AcceptedByTraveler = 'acceptedByTraveler',
  PurchasedByTraveler = 'purchasedByTraveler',
  InTransitToTraveler = 'inTransitToTraveler',
  DeliveredToTravelerAddress = 'deliveredToTravelerAddress',
  ConfirmedReceivedByTraveler = 'confirmedReceivedByTraveler',
  ConfirmedInDestinationCountry = 'confirmedInDestinationCountry',
  ConfirmedDeliveredToShopperByTraveler = 'confirmedDeliveredToShopperByTraveler',
  ConfirmedDeliveredToShopperByShopper = 'confirmedDeliveredToShopperByShopper',
  Lost = 'lost',
  OutOfStock = 'outOfStock'
}

export interface TrackingDetails {
  acceptedByTraveler: TrackingStatus
  purchasedByTraveler: TrackingStatus
  inTransitToTraveler: TrackingStatus
  deliveredToTravelerAddress: TrackingStatus
  confirmedReceivedByTraveler: TrackingStatus
  confirmedInDestinationCountry: TrackingStatus
  confirmedDeliveredToShopperByTraveler: TrackingStatus
  confirmedDeliveredToShopperByShopper: TrackingStatus
  lost: TrackingStatus
  outOfStock: TrackingStatus
}

export interface TrackingStatus {
  status: boolean
  timestamp: string
}

export interface GetItemRequestTrackingDetailsState {
  loading: boolean
  error: GetItemRequestTrackingDetailsErrorResponse | null
  success: GetItemRequestTrackingDetailsSuccessResponse | null
}

export type GetItemRequestTrackingDetailsActionTypes = SliceActions<typeof getItemRequestTrackingDetailsActions>
