import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { setAuthenticationStatus } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import Snackbar from '../../../../shared/components/Snackbar/Snackbar.functions'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { logoutApi } from './logout.api'
import logoutInitialState from './logout.initialState'
import { LogoutErrorResponse, LogoutRequestPayload } from './logout.types'

export const logout = createAsyncThunk(
  urls.auth.logout,
  async ({ onSuccess, onError }: LogoutRequestPayload, { rejectWithValue }) => {
    try {
      const response = await logoutApi()

      setAuthenticationStatus(false)
      onSuccess && onSuccess()

      Snackbar.show({
        message: "You've been logged out.",
        severity: 'success'
      })

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse, onError)

      return rejectWithValue(error)
    }
  }
)

const logoutSlice = createSlice({
  name: urls.auth.logout,
  initialState: logoutInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logout.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(logout.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(logout.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as LogoutErrorResponse
        state.success = null
      })
  }
})

export const { actions: logoutActions, reducer: logoutReducer } = logoutSlice
export default logoutSlice.reducer
