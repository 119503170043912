import React from 'react'

import { ArrowBackRounded, ArrowForwardRounded } from '@mui/icons-material'
import { Box, Stepper as MUIStepper, Step, StepContent, StepLabel, Typography } from '@mui/material'

import CustomConnector from './components/CustomConnector/CustomConnector.component'
import CustomStepIcon from './components/CustomStepIcon/CustomStepIcon.component'

import styles from './Stepper.styles'
import { StepperProps } from './Stepper.types'

const Stepper: React.FC<StepperProps> = (props: StepperProps) => {
  const {
    activeStep,
    steps,
    children,
    stepperWidth = '100%',
    showLeftArrow = false,
    showRightArrow = false,
    showStepper = true,
    stepContent,
    onStepClick,
    preventNavigationBackAfterLastStep = true,
    isLastStepCompleted,
    noBackground = false
  } = props

  const handleStepClick = (index: number) => {
    const isLastStep = index === steps.length - 1

    if (index < activeStep || (isLastStep && preventNavigationBackAfterLastStep)) {
      onStepClick && onStepClick(index)
    }
  }

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      onStepClick && onStepClick(activeStep + 1)
    }
  }

  const handleBack = () => {
    if (activeStep > 0) {
      onStepClick && onStepClick(activeStep - 1)
    }
  }

  const areArrowsPresent = (showLeftArrow && activeStep !== 0) || (showRightArrow && activeStep !== steps.length - 1)

  return (
    <Box sx={styles.container}>
      {showStepper && (
        <Box sx={styles.stepperContainer(noBackground, areArrowsPresent)}>
          {showLeftArrow && (
            <Box sx={{ ...styles.navButtonContainer, visibility: activeStep !== 0 ? 'visible' : 'hidden' }}>
              <ArrowBackRounded onClick={handleBack} sx={styles.navButton} />
            </Box>
          )}

          <MUIStepper
            {...props}
            activeStep={activeStep}
            connector={<CustomConnector />}
            sx={styles.stepper(stepperWidth)}
          >
            {steps.map(({ label, description }, index) => (
              <Step key={label} sx={styles.step(steps.length)}>
                <StepLabel
                  onClick={() => handleStepClick(index)}
                  optional={<Typography sx={styles.stepDescriptionText}>{description}</Typography>}
                  StepIconComponent={(props) => {
                    const isLastStep = activeStep === steps.length - 1
                    const lastStepCompleted = isLastStepCompleted ?? isLastStep
                    const isCompleted = !!props.completed || lastStepCompleted

                    return <CustomStepIcon active={!!props.active} completed={isCompleted} index={index} key={label} />
                  }}
                >
                  <Typography sx={styles.stepLabelText}>{label}</Typography>
                </StepLabel>

                {stepContent && <StepContent>{stepContent}</StepContent>}
              </Step>
            ))}
          </MUIStepper>

          {showRightArrow && (
            <Box
              sx={{
                ...styles.navButtonContainer,
                visibility: activeStep !== steps.length - 1 ? 'visible' : 'hidden'
              }}
            >
              <ArrowForwardRounded onClick={handleNext} sx={styles.navButton} />
            </Box>
          )}
        </Box>
      )}

      {children && <Box sx={styles.content}>{children}</Box>}
    </Box>
  )
}

export default Stepper
