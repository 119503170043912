import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../../../../../shared/styles/themes/default.theme'

const styles = {
  baggageCard: {
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
    width: '100%',
    borderRadius: theme.shape.borderRadius / 4,
    padding: theme.spacing(1, 2, 2, 2),
    boxShadow: theme.shadows[3]
  } as SxProps,

  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  } as SxProps,

  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '20px'
  } as SxProps,

  divider: {
    margin: theme.spacing(2, 0)
  } as SxProps,

  baggageItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  } as SxProps,

  checkIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1)
  } as CSSProperties,

  cancelIcon: {
    color: theme.palette.warning.main,
    marginRight: theme.spacing(1)
  } as CSSProperties,

  addedText: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary
  } as SxProps,

  // passengerContainer: {
  //   marginTop: theme.spacing(2),
  //   marginBottom: theme.spacing(2)
  // } as SxProps,

  // passengerTitle: {
  //   fontWeight: theme.typography.fontWeightBold,
  //   marginBottom: theme.spacing(1)
  // } as SxProps,

  baggagePrice: {
    marginLeft: 'auto',
    fontWeight: theme.typography.fontWeightBold
  } as SxProps,

  noBagsText: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic'
  } as SxProps,

  editBagsButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  } as CSSProperties,

  editBagsButton: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.main
    }
  } as CSSProperties,

  addBagsButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(2)
  } as CSSProperties,

  addBagsButton: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.main
    }
  } as CSSProperties,

  showMoreButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2)
  } as CSSProperties,

  showMoreButton: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main
  } as CSSProperties,

  segmentContainer: (isLast: boolean) =>
    ({
      marginBottom: isLast ? 0 : theme.spacing(5)
    }) as SxProps,

  segmentTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1)
  } as SxProps,

  passengerContainer: {
    marginBottom: theme.spacing(2)
  } as SxProps,

  passengerTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(1)
  } as SxProps
}

export default styles
