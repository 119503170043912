import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  GetItemChangesErrorResponse,
  GetItemChangesRequest,
  GetItemChangesSuccessResponse
} from './getItemChanges.types'

export const getItemChangesApi = async (request: GetItemChangesRequest): Promise<GetItemChangesSuccessResponse> => {
  const response = await configureRequest({
    url: urls.items.changes,
    method: 'GET',
    params: {
      id: request.id
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetItemChangesSuccessResponse
  } else {
    throw response as GetItemChangesErrorResponse
  }
}

export default getItemChangesApi
