const status = {
  userStatus: {
    active: 'active',
    unverified: 'unverified',
    locked: 'locked',
    disabled: 'disabled',
    softDeleted: 'soft_deleted'
  },

  itemRequestStatus: {
    pendingAcceptanceByTraveler: 'pending_acceptance_by_traveler',
    acceptedByTraveler: 'accepted_by_traveler',
    deniedByTraveler: 'denied_by_traveler',
    cancelledByShopper: 'cancelled_by_shopper',
    reassignedByShopper: 'reassigned_by_shopper'
  },

  deliveryStatus: {
    purchasedByTraveler: 'purchased_by_traveler',
    inTransitToTraveler: 'in_transit_to_traveler',
    deliveredToTravelerAddress: 'delivered_to_traveler_address',
    confirmedReceivedByTraveler: 'confirmed_received_by_traveler',
    confirmedInDestinationCountry: 'confirmed_in_destination_country',
    confirmedDeliveredToShopperByTraveler: 'confirmed_delivered_to_shopper_by_traveler',
    confirmedDeliveredToShopperByShopper: 'confirmed_delivered_to_shopper_by_shopper',
    lost: 'lost',
    outOfStock: 'out_of_stock'
  },

  flightStatus: {
    interestedInFlight: 'interested_in_flight',
    noLongerInterestedInFlight: 'no_longer_interested_in_flight',
    flightBooked: 'flight_booked',
    flightBookingCancelledBeforePayment: 'flight_booking_cancelled_before_payment',
    flightPaid: 'flight_paid',
    flightCancelledAfterPayment: 'flight_cancelled_after_payment',
    flightRefunded: 'flight_refunded'
  },

  paymentStatus: {
    // Payment Statuses
    paymentHoldCreated: 'payment_hold_created',
    paymentHoldProcessing: 'payment_hold_processing',
    paymentHoldConfirmed: 'payment_hold_confirmed',
    paymentHoldFailed: 'payment_hold_failed',
    paymentHoldCancelled: 'payment_hold_cancelled',

    paymentHoldReleaseFailed: 'payment_hold_release_failed',

    paymentCreated: 'payment_created',
    paymentProcessing: 'payment_processing',
    paymentConfirmed: 'payment_confirmed',
    paymentFailed: 'payment_failed',
    paymentCancelled: 'payment_cancelled',

    // Refund Statuses
    refundCreated: 'refund_created',
    refundProcessing: 'refund_processing',
    refundConfirmed: 'refund_confirmed',
    refundFailed: 'refund_failed',
    refundCancelled: 'refund_cancelled'
  }
}

export default status
