import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import {
  GetAllFlightItinerariesErrorResponse,
  GetAllFlightItinerariesRequest,
  GetAllFlightItinerariesSuccessResponse
} from './getAllFlightItineraries.types'

export const getAllFlightItinerariesApi = async (
  request: GetAllFlightItinerariesRequest
): Promise<GetAllFlightItinerariesSuccessResponse> => {
  const { page, size, withAssociatedFlights } = request

  const queryParams = new URLSearchParams()
  if (page !== undefined) queryParams.append('page', page.toString())
  if (size !== undefined) queryParams.append('size', size.toString())
  if (withAssociatedFlights !== undefined) queryParams.append('withAssociatedFlights', withAssociatedFlights.toString())

  const response = await configureRequest({
    url: urls.flights.getAllFlightItineraries,
    method: 'GET',
    params: Object.fromEntries(queryParams)
  })

  if (response.status >= 200 && response.status < 300) {
    return response as GetAllFlightItinerariesSuccessResponse
  } else {
    throw response as GetAllFlightItinerariesErrorResponse
  }
}

export default getAllFlightItinerariesApi
