import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import Snackbar from '../../../../shared/components/Snackbar/Snackbar.functions'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { verifyEmailApi } from './verifyEmail.api'
import verifyEmailInitialState from './verifyEmail.initialState'
import { VerifyEmailErrorResponse, VerifyEmailRequest } from './verifyEmail.types'

export const verifyEmail = createAsyncThunk(
  urls.auth.verifyEmail,
  async (
    { verifyEmailRequest, onSuccess }: { verifyEmailRequest: VerifyEmailRequest; onSuccess: () => void },
    { rejectWithValue }
  ) => {
    try {
      const response = await verifyEmailApi(verifyEmailRequest)

      onSuccess()

      Snackbar.show({
        message: response.userFriendlyMessage,
        severity: 'success'
      })

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse, undefined, true)

      return rejectWithValue(error)
    }
  }
)

const verifyEmailSlice = createSlice({
  name: urls.auth.verifyEmail,
  initialState: verifyEmailInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verifyEmail.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(verifyEmail.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(verifyEmail.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as VerifyEmailErrorResponse
        state.success = null
      })
  }
})

export const { actions: verifyEmailActions, reducer: verifyEmailReducer } = verifyEmailSlice

export default verifyEmailSlice.reducer
