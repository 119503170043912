import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import urls from '../../../../networkRequests/apiClient/endpoints'
import errorHandler from '../../../../shared/functions/ErrorHandler/errorHandler.functions'
import { ApiErrorResponse } from '../../../../shared/types/api.types'
import { reassignItemRequestApi } from './reassignItemRequest.api'
import reassignItemRequestInitialState from './reassignItemRequest.initialState'
import { ReassignItemRequestErrorResponse, ReassignItemRequestRequestPayload } from './reassignItemRequest.types'

export const reassignItemRequest = createAsyncThunk(
  urls.offers.reassignItemRequest,
  async (payload: ReassignItemRequestRequestPayload, { rejectWithValue }) => {
    const { request, onSuccess } = payload

    try {
      const response = await reassignItemRequestApi(request)

      onSuccess && onSuccess()

      return response
    } catch (error) {
      errorHandler.handleApiError(error as ApiErrorResponse)

      return rejectWithValue(error)
    }
  }
)

const reassignItemRequestSlice = createSlice({
  name: urls.offers.reassignItemRequest,
  initialState: reassignItemRequestInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(reassignItemRequest.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = null
      })
      .addCase(reassignItemRequest.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = payload
        state.error = null
      })
      .addCase(reassignItemRequest.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload as ReassignItemRequestErrorResponse
        state.success = null
      })
  }
})

export const { actions: reassignItemRequestActions, reducer: reassignItemRequestReducer } = reassignItemRequestSlice
export default reassignItemRequestSlice.reducer
