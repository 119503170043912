import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { UserRole } from '../../../../shared/functions/UserRole/userRoleManagement.types'
import {
  ReassignItemRequestErrorResponse,
  ReassignItemRequestRequest,
  ReassignItemRequestSuccessResponse
} from './reassignItemRequest.types'

export const reassignItemRequestApi = async (
  request: ReassignItemRequestRequest
): Promise<ReassignItemRequestSuccessResponse> => {
  const response = await configureRequest({
    url: urls.offers.reassignItemRequest,
    method: 'POST',
    params: {
      itemRequestId: request.itemRequestId
    },
    headers: {
      'X-User-Role': UserRole.shopper
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response as ReassignItemRequestSuccessResponse
  } else {
    throw response as ReassignItemRequestErrorResponse
  }
}

export default reassignItemRequestApi
