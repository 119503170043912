import React from 'react'

import { Box } from '@mui/material'

import images from '../../../../../assets/images'
import styles from './CustomStepIcon.styles'
import { CustomStepIconProps } from './CustomStepIcon.types'

const { StepperCheckmarkIcon } = images

const CustomStepIcon: React.FC<CustomStepIconProps> = (props) => {
  const { active, completed, index } = props

  if (completed) {
    return (
      <Box sx={styles.completedIconContainer}>
        <StepperCheckmarkIcon />
      </Box>
    )
  } else if (active) {
    return <Box sx={styles.activeIconContainer}>{index + 1}</Box>
  } else {
    return <Box sx={styles.inactiveIconContainer}>{index + 1}</Box>
  }
}

export default CustomStepIcon
