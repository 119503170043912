import { configureRequest } from '../../../../networkRequests/apiClient/apiClient.functions'
import urls from '../../../../networkRequests/apiClient/endpoints'
import { VerifyEmailErrorResponse, VerifyEmailRequest, VerifyEmailSuccessResponse } from './verifyEmail.types'

export const verifyEmailApi = async (request: VerifyEmailRequest): Promise<VerifyEmailSuccessResponse> => {
  const response = await configureRequest({
    url: urls.auth.verifyEmail,
    method: 'POST',
    data: request
  })

  if (response.status >= 200 && response.status < 300) {
    return response as VerifyEmailSuccessResponse
  } else {
    throw response as VerifyEmailErrorResponse
  }
}

export default verifyEmailApi
