import React, { useMemo, useState } from 'react'

import { Check, Warning } from '@mui/icons-material'
import { Box, Card, CardContent, Divider, Typography } from '@mui/material'
import _ from 'lodash'

import Button from '../../../../../../shared/components/Button/Button.component'
import SeatDetailsModal from './components/SeatDetailsModal/SeatDetailsModal.component'

import { useAppSelector } from '../../../../../../shared/hooks/redux.hooks'
import styles from './SeatDetails.styles'
import { SeatDetailsProps } from './SeatDetails.types'
import { SeatSelections } from './components/SeatDetailsModal/SeatDetailsModal.types'

const SeatDetails: React.FC<SeatDetailsProps> = (props) => {
  const { fareOffer, onSeatSelectionsChange } = props

  const [open, setOpen] = useState(false)
  const [selections, setSelections] = useState<SeatSelections>({})
  const [showMore, setShowMore] = useState(false)

  const { success: seatDetailsResponse } = useAppSelector((state) => state.getSeatDetails)

  const handleSubmitSelections = (newSelections: SeatSelections) => {
    setSelections(newSelections)
    onSeatSelectionsChange(newSelections)
    setOpen(false)
  }

  const renderSeatDetailsNotice = (item: string, included: boolean, added: boolean = false) => (
    <Box sx={styles.seatNotice}>
      {included ? <Check sx={styles.checkIcon} /> : <Warning sx={styles.cancelIcon} />}
      <Typography>{item}</Typography>
      {added && <Typography sx={styles.addedText}>- added</Typography>}
    </Box>
  )

  const isUnavailable = useMemo(() => {
    return seatDetailsResponse?.data.length === 0
  }, [seatDetailsResponse])

  const segmentDetails = useMemo(() => {
    return fareOffer.slices.flatMap((slice) =>
      slice.segments.map((segment) => ({
        id: segment.id,
        origin: slice.origin,
        destination: slice.destination
      }))
    )
  }, [fareOffer])

  const renderSeatItem = (
    segmentId: string,
    passengerId: string,
    seat: { id: string; designator: string; price: string }
  ) => {
    const segment = segmentDetails.find((seg) => seg.id === segmentId)
    const passenger = fareOffer.passengers.find((p) => p.id === passengerId)
    const passengerType = passenger ? passenger.type : 'Adult'
    const passengerIndex = fareOffer.passengers.findIndex((p) => p.id === passengerId) + 1
    // const seatPrice = getSeatPrice(segmentId, seat.id)

    return (
      <Box key={`${segmentId}-${passengerId}`} sx={styles.seatItem}>
        <Typography sx={styles.passengerTitle}>
          Passenger {passengerIndex}: {_.startCase(passengerType)}
        </Typography>

        <Box sx={styles.seatDetails}>
          <Typography variant="subtitle1">
            {`${segment?.origin.iata_code} → ${segment?.destination.iata_code}: Seat ${seat.designator}`}
          </Typography>

          <Typography variant="subtitle1">{`+ ${fareOffer.base_currency} $${Number(seat.price).toFixed(2)}`}</Typography>
        </Box>
      </Box>
    )
  }

  const renderSegment = (
    segmentId: string,
    passengerSeats: { [passengerId: string]: { id: string; designator: string; price: string } },
    isLast: boolean
  ) => {
    const segment = segmentDetails.find((seg) => seg.id === segmentId)
    const hasSelections = Object.keys(passengerSeats).length > 0

    return (
      hasSelections && (
        <Box key={segmentId} sx={styles.segmentContainer(isLast)}>
          <Typography variant="h6" sx={styles.segmentTitle}>
            {segment?.origin.city_name} → {segment?.destination.city_name}
          </Typography>

          {hasSelections &&
            Object.entries(passengerSeats).map(([passengerId, seat]) => renderSeatItem(segmentId, passengerId, seat))}
        </Box>
      )
    )
  }

  const hasSelections = useMemo(() => {
    return (
      Object.keys(selections).length > 0 && Object.values(selections).some((segment) => Object.keys(segment).length > 0)
    )
  }, [selections])

  return (
    <Card sx={styles.seatCard}>
      <CardContent>
        <Box sx={styles.titleContainer}>
          <Typography variant="h6" sx={styles.title}>
            Seat Details
          </Typography>

          <Button
            buttonType="tertiary"
            text={hasSelections ? 'Edit Seats' : 'Choose your seats'}
            onClick={() => setOpen(true)}
            disabled={isUnavailable}
            tooltipText={isUnavailable ? 'Seat selection is not available.' : ''}
          />
        </Box>

        {!hasSelections && <Divider sx={styles.divider} />}

        {!hasSelections && isUnavailable && renderSeatDetailsNotice('Seat selection not available', false)}
        {!hasSelections && !isUnavailable && renderSeatDetailsNotice('Seat selection available', true)}

        {hasSelections && (
          <>
            <Divider sx={styles.divider} />

            {Object.entries(selections)
              .slice(0, showMore ? undefined : 2)
              .map(([segmentId, passengerSeats], index) =>
                renderSegment(segmentId, passengerSeats, index === Object.entries(selections).length - 1)
              )}

            {Object.keys(selections).length > 2 && (
              <Button
                containerStyle={styles.showMoreButtonContainer}
                buttonType="secondary"
                altText={showMore ? 'Show Less Seats' : 'Show More Seats'}
                text={showMore ? 'Show Less' : 'Show More'}
                variant="text"
                style={styles.showMoreButton}
                onClick={() => setShowMore(!showMore)}
              />
            )}
          </>
        )}
      </CardContent>

      <SeatDetailsModal
        open={open}
        onClose={() => setOpen(false)}
        fareOffer={fareOffer}
        onSubmitSelections={handleSubmitSelections}
        initialSelections={selections}
      />
    </Card>
  )
}

export default SeatDetails
